import {
    FORM_FETCH,
    FORM_FETCH_SUCCESS,
    FORM_FETCH_FAIL,
    FIELD_SET_REQUIRED,
    FIELD_SET_UNREQUIRED,
    FIELD_SET_HIDDEN,
    FIELD_SET_SHOW,
    FIELD_SET_VISIBILITY,
    SET_PARTICIPANT_PARTNERID,
    CHECK_EMAIL_REGISTERED,
    ALLOWED_FILE_EXTENSIONS_SET
 } from './ActionTypes';

export const formFetch = (formID, parameters) => {
    return {
        type: FORM_FETCH,
        formID: formID,
        ...parameters
    };
};

export const formFetchSuccess = (form) => {
    return {
        type: FORM_FETCH_SUCCESS,
        form: form
    };
};

export const formFetchFail = (error) => {
    return {
        type: FORM_FETCH_FAIL,
        error: error
    };
};


export const requireField = (submissionId, formElementId) => {
    return {
        type: FIELD_SET_REQUIRED,
        submissionId: submissionId,
        formElementId: formElementId
    }
};

export const unrequireField = (submissionId, formElementId) => {
    return {
        type: FIELD_SET_UNREQUIRED,
        submissionId: submissionId,
        formElementId: formElementId
    }
};

export const hideField = (submissionId, formElementId) => {
    return {
        type: FIELD_SET_HIDDEN,
        submissionId: submissionId,
        formElementId: formElementId
    }
};

export const showField = (submissionId, formElementId) => {
    return {
        type: FIELD_SET_SHOW,
        submissionId: submissionId,
        formElementId: formElementId
    }
};

export const fieldSetVisibility = (submissionId, formElementId, hidden = true) => {
    return {
        type: FIELD_SET_VISIBILITY,
        submissionId: submissionId,
        formElementId: formElementId,
        hidden: hidden
    }
};

export const setParticipantPartnerID = partnerID => {
    return {
        type: SET_PARTICIPANT_PARTNERID,
        partnerID: partnerID
    }
}

export const checkEmailAlreadyRegistered = (submissionId, formElementId, value) => {
    return {
        type: CHECK_EMAIL_REGISTERED,
        submissionId: submissionId,
        formElementId: formElementId,
        value: value
    }
}

export const setAllowedFileExtensions = allowedFileExtensions => {
    return {
        type: ALLOWED_FILE_EXTENSIONS_SET,
        allowedFileExtensions
    }
}

