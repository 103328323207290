
import { put } from 'redux-saga/effects';
import api from '../api';
import { personFetchFail, personFetchSuccess } from '../actions';

export function* personFetchSaga(action) {

    const { personID, organizationID, eventID } = action;

    try {
        const response = yield api.post('public/participants/find/' + personID, {
            organizationID: organizationID,
            eventID: eventID,
            service: 'widget2'
        });
        yield put(personFetchSuccess(response.data.data.person))
    }
    catch (error) {
        if(error.response && error.response.data && error.response.data.errors) {
            yield put(personFetchFail(error.response.data.errors[0]));
        } else {
            yield put(personFetchFail('Unknown error'));
        }
    }
}