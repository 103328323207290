import {
    FORM_FETCH_SUCCESS
} from '../actions/ActionTypes';

const initialState = {
    all: {},
    bundles: {
        byId: {},
        allIds: []
    },
    conditions: {
        byId: {},
        byTarget: {},
        byBundleId: {},
        allIds: []
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FORM_FETCH_SUCCESS:

            const formConditionBundles = action.form.formConditionBundles.filter(formConditionBundle => {
                return typeof formConditionBundle.isEnabled === 'undefined' || formConditionBundle.isEnabled;
            });

            return {
                ...state,
                all: formConditionBundles,
                bundles: flattenBundles(formConditionBundles)
            }
        default:
            return state;
    }
};


const flattenBundles = formConditionBundles => {

    let bundles = {
        byId: {},
        byTarget: {},
        byActionTarget: {}
    };

    bundles.byId = formConditionBundles.reduce((obj, bundle) => {
        obj[bundle.formConditionBundleId] = bundle;
        return obj;
    }, {});


    bundles.byActionTarget = formConditionBundles.reduce((bundles, formConditionBundle) => {
        formConditionBundle.formConditionActions
            .filter(formConditionAction => formConditionAction.actionTarget === 'formElement')
            .forEach(formConditionAction => {
                const { formElementId } = formConditionAction.actionParameter;
                bundles[formElementId] = bundles[formElementId] || [];
                if(bundles[formElementId].indexOf(formConditionBundle.formConditionBundleId) < 0){
                    bundles[formElementId].push(formConditionBundle.formConditionBundleId);
                }
            })

        return bundles;
    }, {});


    for ( const formConditionBundle of formConditionBundles) {
        for (const formCondition of formConditionBundle.formConditions) {

            if(!bundles.byTarget[formCondition.targetId]) {
                bundles.byTarget[formCondition.targetId] = [];
            }
            if(bundles.byTarget[formCondition.targetId].indexOf(formConditionBundle.formConditionBundleId) < 0){
                bundles.byTarget[formCondition.targetId].push(formConditionBundle.formConditionBundleId);
            }

            /*
            * If condition is type of "value of field equals value of another field",
            * set bundle as related to formElement with expectedId (if expecetedId not null).
            */
            if(formCondition.expectedId && !bundles.byTarget[formCondition.expectedId]) {
                if (!bundles.byTarget[formCondition.expectedId]) {
                    bundles.byTarget[formCondition.expectedId] =  [];
                }
                bundles.byTarget[formCondition.expectedId].push(formConditionBundle.formConditionBundleId);
            }
        }
    }

    for( const [formElementId, bundleIds] of Object.entries(bundles.byTarget) ) {
        // Check bundle actions, get bundleIds from bundles.byActionTarget to existing bundleIds array
        for( const bundleId of bundleIds) {
            // Get actions of bundle
            for( const action of bundles.byId[bundleId].formConditionActions) {
                if(action.actionTarget !== 'formElement' || !bundles.byActionTarget[action.actionParameter.formElementId]){
                   continue;
                }
                for(const actionBundle of bundles.byActionTarget[action.actionParameter.formElementId]) {
                    if(bundles.byTarget[formElementId].indexOf(actionBundle) < 0 ) {
                       bundles.byTarget[formElementId].push(actionBundle);
                    }
                }
            }
        }
    }

    /*
    for (const [formElementId, bundleIds] of Object.entries(bundles.byActionTarget) ) {
        for(const bundleId of bundleIds) {
            if(bundles.byTarget[formElementId] && bundles.byTarget[formElementId].indexOf(bundleId) < 0 ) {
                bundles.byTarget[formElementId].push(bundleId);
             }
        }
    }
    */

    return bundles;
};