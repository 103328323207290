import {
    APP_LOADING_SET,
    SET_ACTIVE_VIEW,
    SET_ALERTS,
    SET_ERRORS,
    RESET_ALERTS
} from './../actions/ActionTypes';

const initialState = {
    activeView: 'form',
    alerts: null,
    alertsVisible: false,
    errors: null,
    errorsByFieldId: {},
    loading: {
        status: true,
        text: 'Loading',
        color: 'primary'
    }
};

export default function (state = initialState, action) {
    switch(action.type) {
        case APP_LOADING_SET:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    status: action.status || false,
                    text: action.text || initialState.loading.text,
                    color: action.color || initialState.loading.color
                }
            }
        case SET_ACTIVE_VIEW:
            return {
                ...state,
                activeView: action.activeView
            }
        case SET_ALERTS:
            return {
                ...state,
                alertsVisible: true,
                alerts: [...action.alerts]
            }
        case RESET_ALERTS:
            return {
                ...state,
                alertsVisible: false,
                alerts: null,
                errors: null
            }
        case SET_ERRORS:
            return {
                ...state,
                alertsVisible: true,
                errors: setErrors(action.errors),
                errorsByFieldId: setErrorsByFieldId(action.errors)
            }
        default:
            return state;
    }
};

const setErrors = errors => {

    let outputErrors = [];

    if(Array.isArray(errors) && errors.length) {
        outputErrors.push(errors[0]);
    }

    /*
    errors.forEach(error => {
        if(error.fields && error.fields.length) {

            error.fields.forEach(field => {

                let errorMessage = field.message;

                // Max submissions reached (over quota)
                if(parseInt(error.code) === 1080) {
                    errorMessage = error.details;
                }

                outputErrors.push({
                    // ...field,
                    title: error.title,
                    details: errorMessage,
                    value: field.value,
                })
            })
        } else {
            outputErrors.push(error);
        }
    });
    */

    return outputErrors;
}

const setErrorsByFieldId = errors => {
    let errorsByFieldId = {};
    errors.forEach(error => {
        if(error.fields && error.fields.length) {
            error.fields.forEach(field => {
                errorsByFieldId[field.property] = error;
            })
        }
    });
    return errorsByFieldId;
}
