import { put } from "redux-saga/effects";
import api from '../api';

import {
	fetchPaytrailFormDataSuccess,
	fetchPaytrailFormDataFail,
	setErrors
} from '../actions';

export function* paytrailFormDataSaga(action) {
	try {
		
		const response = yield api.get('public/paytrail/paymentForm/' + action.orderID);		
		
		if(response.data.errors && response.data.errors.length) {
			throw response.data.errors;
		}

		yield put(fetchPaytrailFormDataSuccess(response.data.data.paytrail));

	} catch (error) {
		yield put(fetchPaytrailFormDataFail(error));
		yield put(setErrors(error));
	}
}
