
import {
    FORM_FETCH_SUCCESS,
    NEXT_STEP,
    SUBMISSION_SUBMIT_SUCCESS,
    SET_ACTIVE_VIEW,
    NAVIGATE_PREV_FORM_PAGE
} from './../actions/ActionTypes';

const initialState = {
    activeView: 'form',
    canSkipSummary: false,
    formPages: {
        count: 1,
        active: null,
        isLast: false,
        isFirst: true,
        titles: [],
        allIds: [],
        byId: {},
        lastPageId: null,
        firstPageId: null,
    }
};

export default function(state = initialState, action) {
    switch(action.type) {
        case FORM_FETCH_SUCCESS:
            return {
                ...state,
                canSkipSummary: summaryPageSkippable(action.form),
                formPages: {
                    ...state.formPages,
                    count: action.form.pages.length,
                    active: action.form.pages[0].formPageId,
                    titles: action.form.pages.map(page => page.title),
                    allIds: action.form.pages.map(page => page.formPageId),
                    byId: formPagesById(action.form.pages),
                    firstPageId: action.form.pages[0].formPageId,
                    lastPageId: action.form.pages[action.form.pages.length-1].formPageId,
                    isFirst: true,
                    isLast: action.form.pages[0].formPageId === action.form.pages[action.form.pages.length-1].formPageId
                }
            }
        case NEXT_STEP:
            const nextPageId = getNextPageId(state.formPages.allIds, state.formPages.active);
            if(document.getElementById("EventosFormWidget")) {
                document.getElementById("EventosFormWidget").scrollIntoView();
            }
            return {
                ...state,
                formPages: {
                    ...state.formPages,
                    active: nextPageId,
                    isFirst: nextPageId === state.formPages.firstPageId,
                    isLast: nextPageId === state.formPages.lastPageId
                },
                activeView: getActiveView(state)
            }
        case NAVIGATE_PREV_FORM_PAGE:
            const prevPageId = getPrevPageId(state.formPages.allIds, state.formPages.active);
            if(document.getElementById("EventosFormWidget")) {
                document.getElementById("EventosFormWidget").scrollIntoView();
            }
            return {
                ...state,
                formPages: {
                    ...state.formPages,
                    active: prevPageId,
                    isFirst: prevPageId === state.formPages.firstPageId,
                    isLast: prevPageId === state.formPages.lastPageId
                }
            }
        case SET_ACTIVE_VIEW:
            return {
                ...state,
                activeView: action.activeView
            }
        case SUBMISSION_SUBMIT_SUCCESS:
            return {
                ...state
            }
        default:
            return state;
    }
}

const getNextPageId = (pages, currentPageId) => {
    const currentPageIndex = pages.indexOf(currentPageId);
    if(currentPageIndex < (pages.length-1)) {
        return pages[currentPageIndex+1];
    }
    return currentPageId;
}

const getPrevPageId = (pages, currentPageId) => {
    const currentPageIndex = pages.indexOf(currentPageId);
    if(currentPageIndex > 0) {
        return pages[currentPageIndex-1];
    }
    return currentPageId;
}

const formPagesById = pages => {
    return pages.reduce((obj, item) => {
        obj[item.formPageId] = item;
        return obj;
    }, {})
}

const getActiveView = state => {
    if(state.formPages.active === state.formPages.lastPageId) {
        return 'summary';
    }
    return 'form';
}

const summaryPageSkippable = form => {

    if(form.showSummaryPage) {
        return false;
    }

    if(form.showSummaryPage || (form.products && form.products.length > 0)) {
        return false;
    }

    const formElementsWithProducts = form.pages.map(page => page.elements)
        .reduce((prev, curr) => prev.concat(curr))
        .filter(formElement => {
            if( formElement.options && formElement.options.length > 0) {
                return formElement.options.filter(formElementOption => {
                    return formElementOption.products && formElementOption.products.length > 0;
                }).length > 0;
            }
            return false;
        });

    return formElementsWithProducts.length === 0;
}