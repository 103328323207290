import React from 'react';
import { fieldValueAsText } from '../../utility/helpers';

const nonFieldElements = ['paragraph', 'heading', 'divider'];

function SubmissionFieldsSummary(props) {

    const { submissionFields, formFields, languageId, interestsById, formUploads } = props;

    return (
        <div className="SubmissionFieldsSummary">
            <div className="row">

                {Object.values(submissionFields)
                    .filter(submissionField => (!submissionField.hidden && nonFieldElements.indexOf(submissionField.type) === -1))
                    .sort((a, b) => a.order - b.order)
                    .sort((a, b) => a.pageIndex - b.pageIndex)
                    .map(submissionField => {

                        let formElementUploads = [];
                        if(submissionField.type === 'file' && formUploads.byFieldId && formUploads.byFieldId[submissionField.formElementId]) {
                            formElementUploads = formUploads.byFieldId[submissionField.formElementId];
                        }

                        const field = {...formFields[submissionField.formElementId], ...submissionField, formUploads: formElementUploads};
                        let displayValue = submissionField.value ? fieldValueAsText(field, languageId, interestsById) : '-';
                        if(field.type === 'file') {
                            displayValue = fieldValueAsText(field, languageId, interestsById);
                        }
                        return (
                            <div className={`${field.class} formElement-${field.formElementId}`} key={field.formElementId}>
                                <div className="form-group">
                                    <div>
                                        <label style={{fontWeight: 'bold', display: 'block'}}>{field.label[languageId]}</label>
                                        {Array.isArray(displayValue) ?
                                            displayValue.map((value, index) =>
                                                <span
                                                    key={index}
                                                    className="SummaryFieldValue"
                                                    style={{display: 'block'}}
                                                >
                                                    {value}
                                                </span>
                                            )
                                        :
                                            <span className="SummaryFieldValue">{displayValue}</span>
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default SubmissionFieldsSummary;