
import { put, select } from 'redux-saga/effects';
import {
    setErrors,
    setLoading,
    nextStep,
    submissionSubmit,
    setInputValue,
    setSubmissionValidationErrors
} from '../actions';
import  * as selectors from './../selectors'
import { validateField, validatePhone, validateEmail } from './../utility/validator';


export function* validateSubmissionSaga(action) {

    yield put(setErrors([]));
    yield put(setLoading(true, 'Validating submission'));

    try {
        const { currentPageId } = yield action;
        const form = yield select(selectors.form);
        const submissionIds = yield select(selectors.submissionIds);
        const fieldsByPageId = yield select(selectors.fieldsByPageId);
        const submissionFields = yield select(selectors.submissionFields);
        const modifyingExistingSubmission = yield select(selectors.modifyingExistingSubmission);
        const formUploadsByFieldId = yield select(selectors.formUploadsByFieldId);

        let validationErrors = {};
        let autofocused = false;

        // Loop through all the fields of the page
        for (const submissionId of submissionIds) {

            validationErrors[submissionId] = [];

            for( const field of fieldsByPageId[currentPageId] ) {

                // Skip fields that are not shown for current submission
                const submissionField = yield submissionFields[submissionId][field.formElementId];

                if(typeof submissionField === 'undefined') {
                    continue;
                }

                let payload = yield {
                    value: submissionField.value,
                    dateValue: submissionField.dateValue
                };

                // Skip hidden fields
                if(submissionField.hidden) {
                    payload = yield {...payload, valid: true, errors: null, isValidated: true};
                    // Last parameter (skipConditions) is false,
                    // because it's essential to check conditions for hidden fields.
                    yield put(setInputValue(submissionId, field.formElementId, payload, false));
                    continue;
                }

                /**
                 * Client side validation
                 */

                // Add information about existing formUploads of file field for validation purposes
                if(field.type === 'file' && formUploadsByFieldId[field.formElementId]) {
                    field.formUploads = formUploadsByFieldId[field.formElementId];
                }

                let validation = yield validateField({...field, ...submissionField});

                if( validation.valid ) {

                    /**
                     * Server side validations (these are done if client side validation is OK)
                     */
                    if( field.mappedAs === 'email' && !modifyingExistingSubmission && (field.validation.required || form.isRegistrationForm) ) {
                        const emailValidation = yield validateEmail(submissionField.value, form.formID);
                        validation = {...validation, ...emailValidation};
                        if(!emailValidation.valid) {
                            yield validationErrors[submissionId].push(emailValidation.errors);
                        }
                    } else if( field.mappedAs === 'phone' && field.validation.required ) {
                        const phoneValidation = yield validatePhone(submissionField.value);
                        validation =  yield { ...validation, ...phoneValidation};
                        if(!phoneValidation.valid) {
                            yield validationErrors[submissionId].push(phoneValidation.errors);
                        }
                    }

                } else {
                    yield validationErrors[submissionId].push(validation.errors);
                }

                // Set autofocus to first element with errors
                if(!autofocused && !validation.valid) {
                    validation.autofocus = true;
                    autofocused = true;
                } else {
                    validation.autofocus = false;
                }

                // Spread the validation properties to payload
                payload = {...payload, ...validation};
                // Last parameter (skipConditions) is true, because it's pointless to check them here
                yield put(setInputValue(submissionId, field.formElementId, payload, true));
            }

            if(validationErrors[submissionId].length === 0 ) {
                delete validationErrors[submissionId];
            }
        }


        yield put(setSubmissionValidationErrors(validationErrors));
        yield put(setLoading(false));

        /**
         * If there's no errors,
         * go to next step or submit the form.
         */
        if(!Object.keys(validationErrors).length) {

            const lastFormPageId = yield (select(selectors.lastFormPageId));
            const canSkipSummary = yield (select(selectors.canSkipSummary));

            let requestSummary = yield 1;
            if(canSkipSummary) {
                requestSummary = yield 0;
            }

            if(lastFormPageId === currentPageId && (!modifyingExistingSubmission || (modifyingExistingSubmission && form.allowSubmissionUpdate))) {
                yield put(submissionSubmit({requestSummary: requestSummary}));
            } else {
                yield put(nextStep());
                var widgetWrapper = document.getElementById("EventosFormWidget");
                widgetWrapper.scrollIntoView();
            }

        } else {
            console.error(validationErrors);
        }
    } catch (errors) {
        console.error(errors);
        yield put(setLoading(false));
    }

}