
/**
 * App / General
 */
export const APP_LOADING_SET = 'APP_LOADING_SET'

/**
 * Form
 */
export const FORM_FETCH = 'FORM_FETCH'
export const FORM_FETCH_SUCCESS = 'FORM_FETCH_SUCCESS'
export const FORM_FETCH_FAIL = 'FORM_FETCH_FAIL'

export const FORM_SUBMIT = 'FORM_SUBMIT'
export const FORM_SUBMIT_PREVIEW_SUCCESS = 'FORM_SUBMIT__PREVIEW_SUCCESS'
export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS'
export const FORM_SUBMIT_FAIL = 'FORM_SUBMIT_FAIL'

export const FORM_NEXT_PAGE = 'FORM_NEXT_PAGE'
export const FORM_NEXT_PAGE_SUCCESS = 'FORM_NEXT_PAGE_SUCCESS'
export const FORM_NEXT_PAGE_FAIL = 'FORM_NEXT_PAGE_FAIL'
export const FORM_PREV_PAGE = 'FORM_PREV_PAGE'

export const INPUT_CHANGE = 'INPUT_CHANGE'
export const SET_SUBMISSION_VALUE = 'SET_SUBMISSION_VALUE'

export const FIELD_SET_VALIDATION = 'FIELD_SET_VALIDATION'
export const FIELD_SET_REQUIRED = 'FIELD_SET_REQUIRED'
export const FIELD_SET_UNREQUIRED = 'FIELD_SET_UNREQUIRED'
export const FIELD_SET_HIDDEN = 'FIELD_SET_HIDDEN'
export const FIELD_SET_SHOW = 'FIELD_SET_SHOW'
export const FIELD_SET_VISIBILITY = 'FIELD_SET_VISIBILITY'
export const FIELD_SET_REQUIREMENT = 'FIELD_SET_REQUIREMENT'
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD'

export const ALLOWED_FILE_EXTENSIONS_SET = 'ALLOWED_FILE_EXTENSIONS_SET'

/**
 * Submissions
 */
export const INIT_SUBMISSION = 'INIT_SUBMISSION'
export const SUBMISSION_FETCH = 'SUBMISSION_FETCH'
export const SUBMISSION_FETCH_SUCCESS = 'SUBMISSION_FETCH_SUCCESS'
export const SUBMISSION_FETCH_FAIL = 'SUBMISSION_FETCH_FAIL'
export const SUBMISSION_SUBMIT = 'SUBMISSION_SUBMIT'
export const SUBMISSION_SUBMIT_SUCCESS = 'SUBMISSION_SUBMIT_SUCCESS'
export const SUBMISSION_SUBMIT_FAIL = 'SUBMISSION_SUBMIT_FAIL'
export const ADD_SUBMISSION_INSTANCE = 'ADD_SUBMISSION_INSTANCE'
export const REMOVE_SUBMISSION_INSTANCE = 'REMOVE_SUBMISSION_INSTANCE'
export const SET_PERSONID = 'SET_PERSONID'
export const SET_PARTNERID = 'SET_PARTNERID'
export const SET_PARTICIPANT_PARTNERID = 'SET_PARTICIPANT_PARTNERID'

export const CHECK_EMAIL_REGISTERED = 'CHECK_EMAIL_REGISTERED'
export const CHECK_EMAIL_REGISTERED_SUCCESS = 'CHECK_EMAIL_REGISTERED_SUCCESS'
export const CHECK_EMAIL_REGISTERED_FAIL = 'CHECK_EMAIL_REGISTERED_FAIL'

export const VALIDATE_PHONE_NUMBER = 'VALIDATE_PHONE_NUMBER'
export const SUBMISSION_VALIDATE = 'SUBMISSION_VALIDATE'
export const SUBMISSION_SET_VALIDATION_ERRORS = 'SUBMISSION_SET_VALIDATION_ERRORS'

export const CONFIRMATION_EMAIL_SEND_METHOD_TOGGLE = 'CONFIRMATION_EMAIL_SEND_METHOD_TOGGLE'

export const SET_MULTIREGISTRATION_MESSAGE_RECEIVER_EMAILS = 'SET_MULTIREGISTRATION_MESSAGE_RECEIVER_EMAILS'

export const SET_USE_MANUAL_EMAIL_RECIPIENTS = 'SET_USE_MANUAL_EMAIL_RECIPIENTS'

export const RESERVATION_SAVE = 'RESERVATION_SAVE'
export const RESERVATION_SAVE_SUCCESS = 'RESERVATION_SAVE_SUCCESS'
export const RESERVATION_SAVE_FAIL = 'RESERVATION_SAVE_FAIL'

export const PRESENTATION_ADD = 'PRESENTATION_ADD'
export const PRESENTATION_REMOVE = 'PRESENTATION_REMOVE'
export const SET_SUBMISSION_PRESENTATIONS = 'SET_SUBMISSION_PRESENTATIONS'

/**
 * Conditions
 */
export const INIT_CONDITIONS = 'INIT_CONDITIONS'
export const CHECK_FORMELEMENT_CONDITIONS = 'CHECK_FORMELEMENT_CONDITIONS'

/**
 * Languages
 */
export const SET_LANGUAGES = 'SET_LANGUAGES'
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE'

/**
 * Alerts
 */
export const SET_ALERTS = 'SET_ALERTS'
export const RESET_ALERTS = 'RESET_ALERTS'
export const SET_ERRORS = 'SET_ERRORS'


/**
 * Navigation
 */
export const NEXT_STEP = 'NEXT_STEP'
export const PREV_STEP = 'PREV_STEP'
export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW'
export const NAVIGATE_NEXT_FORM_PAGE = 'NAVIGATE_NEXT_FORM_PAGE'
export const NAVIGATE_NEXT_FORM_PAGE_SUCCESS = 'NAVIGATE_NEXT_FORM_PAGE_SUCCESS'
export const NAVIGATE_PREV_FORM_PAGE = 'NAVIGATE_PREV_FORM_PAGE'



/**
 * Payments
 */
export const PAYMENT_METHOD_SET = 'PAYMENT_METHOD_SET'
export const BILLING_DETAILS_SET = 'BILLING_DETAILS_SET'
export const BILLING_INFORMATION_SET = 'BILLING_INFORMATION_SET'
export const BILLING_INFORMATION_VALID = 'BILLING_INFORMATION_VALID'
export const BILLING_INFORMATION_INVALID = 'BILLING_INFORMATION_INVALID'
export const PAYTRAIL_SET_REDIRECTING = 'PAYTRAIL_SET_REDIRECTING'
export const PAYTRAIL_SET_STATUS = 'PAYTRAIL_SET_STATUS'
export const PAYTRAIL_FETCH_FORMDATA = 'PAYTRAIL_FETCH_FORMDATA'
export const PAYTRAIL_FETCH_FORMDATA_SUCCESS = 'PAYTRAIL_FETCH_FORMDATA_SUCCESS'
export const PAYTRAIL_FETCH_FORMDATA_FAIL = 'PAYTRAIL_FETCH_FORMDATA_FAIL'
export const CHECK_COUPON_CODE = 'CHECK_COUPON_CODE'
export const COUPON_CODE_UPDATE = 'COUPON_CODE_UPDATE'

/**
 * Paytrail
 */
export const SET_PAYTRAIL_REDIRECTING = 'SET_PAYTRAIL_REDIRECTING'
export const SET_PAYTRAIL_PAYMENT_STATUS = 'SET_PAYTRAIL_PAYMENT_STATUS'
export const FETCH_PAYTRAIL_FORMDATA = 'FETCH_PAYTRAIL_FORMDATA'
export const FETCH_PAYTRAIL_FORMDATA_SUCCESS = 'FETCH_PAYTRAIL_FORMDATA_SUCCESS'
export const FETCH_PAYTRAIL_FORMDATA_FAIL = 'FETCH_PAYTRAIL_FORMDATA_FAIL'

/**
 * Invoice
 */
export const UPDATE_INVOICE_DATA = 'UPDATE_INVOICE_DATA'
export const UPDATE_BILLING_INFORMATION = 'UPDATE_BILLING_INFORMATION'
export const SET_BILLING_INFORMATION_ERRORS = 'SET_BILLING_INFORMATION_ERRORS'
export const FETCH_INVOICE_CUSTOMERS = 'FETCH_INVOICE_CUSTOMERS'
export const FETCH_INVOICE_CUSTOMERS_SUCCESS = 'FETCH_INVOICE_CUSTOMERS_SUCCESS'
export const FETCH_INVOICE_CUSTOMERS_FAIL = 'ETCH_INVOICE_CUSTOMERS_FAIL'
export const INVOICE_CUSTOMER_SET = 'INVOICE_CUSTOMER_SET'
export const PAYER_IS_COMPANY_SET = 'PAYER_IS_COMPANY_SET'
export const INVOICE_DELIVERY_METHOD_SET = 'INVOICE_DELIVERY_METHOD_SET'

/**
 * Participant list
 */
export const PARTICIPANT_LIST_FETCH = 'PARTICIPANT_LIST_FETCH'
export const PARTICIPANT_LIST_FETCH_SUCCESS = 'PARTICIPANT_LIST_FETCH_SUCCESS'
export const PARTICIPANT_LIST_FETCH_FAIL = 'PARTICIPANT_LIST_FETCH_FAIL'

/**
 * Person
 */
export const PERSON_FETCH = 'PERSON_FETCH'
export const PERSON_FETCH_FAIL = 'PERSON_FETCH_FAIL'
export const PERSON_FETCH_SUCCESS = 'PERSON_FETCH_SUCCESS'