
import { put } from 'redux-saga/effects';
import { setInputValue } from '../actions';

import {validatePhone } from './../utility/validator';

export function* validatePhoneNumberSaga(action) {

    const { submissionId, formElementId, value } = yield action;

    let payload = {
        value: value,
        valid: true,
        isValidated: true,
        errors: []
    };

	try {
        const request = yield validatePhone(value);
        if(!request.valid) {
            throw request;
        }
	} catch (error) {
        payload = {
            ...payload,
            ...error
        };
    }

    yield put(setInputValue(submissionId, formElementId, payload));
}