import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class FieldLabel extends React.Component {

    render() {

        const { text, inline = false } = this.props;

        const labelClasses = classNames({
            'col-form-label': true,
            'col-sm-3': inline
        });

        return (
            <label className={labelClasses}>
                {text}
            </label>
        )
    }

};

FieldLabel.propTypes = {
    text: PropTypes.string,
    inline: PropTypes.bool
}

export default FieldLabel;