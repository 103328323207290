export const toggleArrayValue = (value, array) => {
    if(array.indexOf(value) === -1) {
        array.push(value);
    } else {
        array.splice(array.indexOf(value), 1);
    }
    return array;
}

export const buildUpdatedSubmission = (submission, formElementId, value) => {
    const updatedSubmission = {
        ...submission,
        [formElementId]: {
            ...submission[formElementId],
            value: value
        }
    };
    return updatedSubmission;
}

export const parseSubmissionData = (submissions, formData, personID, partnerID, requestSummary, presentationsBySubmissionId) => {

	let index = 0;
	let submissionCounter = 1;
	const presentations = Object.values(presentationsBySubmissionId);

	for ( const submission of Object.values(submissions) ) {
		const entries = Object.entries(submission);
		const prefix = 'r' + submissionCounter + '_';

		for ( const [formElementId, field] of entries) {

			// Add key for multiregistration POST data
			let idx = 'r' + submissionCounter + '_field_' + formElementId;

			if(field.hidden || field.type === 'divider' || field.type ==='paragraph' || field.type === 'heading') {
				continue;
			}

			// Skip radio & select fields that are empty
			if ( (field.type === 'select' || field.type === 'radio' || field.type === 'scalerating' || field.type === 'iconrating' || field.type === 'nps') && (field.value === '' || !field.value) ) {
				continue;
			}

			// Event related mappings: options are hardcoded and API handles only values 1 and 0
			/*if((field.mappedAs === 'avec' || field.mappedAs === 'marketingAllowed' || field.mappedAs === 'isPublic') && (field.options && field.options.length > 0) ) {
				if(parseInt(field.value) === field.options[0].formElementOptionId) {
					formData.append(idx, 1);
				} else {
					formData.append(idx, 0);
				}
				continue;
			}*/

			switch(field.type) {
				case 'datepicker':
					formData.append(idx, field.value);
					break;
				case 'checkbox':
					if(field.value && field.value.length) {
						field.value.forEach(value => {
							formData.append(idx+'[]', value);
						});
					}
					break;
				case 'file':
					const fileKeys = field.value ? Object.keys(field.value) : [];
					if (requestSummary === 0) {
						// final submit: add files
						for(const fileKey of fileKeys) {
							formData.append(idx+'[]', field.value[fileKey]);
						}
					} else {
						// request summary: add only filenames (shown in summary page)
						for(const fileKey of fileKeys) {
							formData.append(idx+'[]', field.value[fileKey].name);
						}
					}
					break;
				default:
					formData.append(idx, field.value);
					break;
			}
		}
		if(personID && submissionCounter === 1) {
			formData.append(prefix+'personID', personID);
		}
		if(partnerID) {
			formData.append(prefix+'partnerID', partnerID);
		}

		if(presentations && presentations[index]) {
			presentations[index].forEach(presentationID => {
				formData.append(prefix+'presentations[]', presentationID);
			})
		}

		submissionCounter++;
		index++;
	 }
	 return formData;
}

export const fieldValueAsText = (field, languageId, interestsById) => {
    switch (field.type) {
        case 'checkbox':
        case 'radio':
        case 'select':
		case 'scalerating':
		case 'nps':
			if(field.mappedAs === 'countryCode') {
				return field.value;
			}
			else if(field.mappedAs === 'interests') {
				const values = Array.isArray(field.value) ? [...field.value] : [field.value];
				const displayValues = values.map(value => interestsById[value] ? interestsById[value].name : '' );
				return displayValues.length ? displayValues.join(', ') : '-';
			} else {
				const values = Array.isArray(field.value) ? [...field.value] : [field.value];
				const displayValues = field.options.filter(option => (values.indexOf(option.formElementOptionId) !== -1) || values.indexOf(''+option.formElementOptionId) !== -1).map(option => option.text[languageId]);
				return displayValues.length ? displayValues : '-';
			}

		case 'file':

			let values = (field.value && Object.keys(field.value).length > 0) ? Object.values(field.value).map(field => field.name) : [];

			// Add already uploaded files to values
			if(field.formUploads && field.formUploads.length) {
				let formUploadNames = field.formUploads.map(upload => `${upload.fileName}${upload.fileType}`);
				values = values.concat(formUploadNames);
			}
			// If single file field, show only latest file
			if(!field.fileAllowMultiple && values.length > 1) {
				values = [values[0]]
			}
			return values;
        default:
            return field.value;
    }
}

export const sortByProperty = (array, property, direction = 'asc') => {
	return array.sort((a, b) => {
		if(direction === 'desc') {
			return a[property] + b[property];
		}
		return a[property] - b[property];
    });
}