import {
    PERSON_FETCH,
    PERSON_FETCH_SUCCESS,
    PERSON_FETCH_FAIL
 } from './ActionTypes';

export const personFetch = (personID, eventID, organizationID) => {
    return {
        type: PERSON_FETCH,
        personID,
        eventID,
        organizationID
    }
};

export const personFetchSuccess = person => {
    return {
        type: PERSON_FETCH_SUCCESS,
        person
    }
};

export const personFetchFail = error => {
    return {
        type: PERSON_FETCH_FAIL,
        error
    }
};

