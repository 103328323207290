import { put, select } from "redux-saga/effects";
import api from '../api';

import {
    reservationSaveSuccess,
    reservationSaveFail,
	setActiveView,
	addSubmissionInstance
} from '../actions';

import  * as selectors from './../selectors';

export function* reservationSaveSaga(action) {

	const form = yield select(selectors.form);
	const submissionReservation = yield select(selectors.submissionReservation);
	const currentPersonCount = submissionReservation ? submissionReservation.personCount : null;

	try {

		let url = 'public/forms/' + form.formID + '/reservations';
		if(submissionReservation && submissionReservation.submissionReservationID) {
			url += '/' + submissionReservation.submissionReservationID;
		}

		// Make API call
		const response = yield api.post(url, action.payload);

		// Success callback
		yield put(reservationSaveSuccess(response.data.data.submissionReservation));

		// If payload contains personCount greater than submissionReservation.personCount, add submission instance
		if(!currentPersonCount || (action.payload.personCount && currentPersonCount < action.payload.personCount)) {
			yield put(addSubmissionInstance());
		}

	} catch (error) {
		if(form.reservationsEnabled && !submissionReservation) {
			yield put(setActiveView('reservationFull'));
		}
		if(error.response) {
			const firstError = error.response.data.errors[0];
			yield put(reservationSaveFail(firstError));
		}
	}
}