import { put, select } from "redux-saga/effects";
import api from '../api';
import {
	setErrors,
	setLoading,
	submissionFetchSuccess,
	submissionFetchFail,
	setInputValue,
	setActiveView,
	submissionSubmitSuccess,
	submissionSubmitFail,
	fetchPaytrailFormDataSuccess,
	languageChange,
	addSubmissionInstance,
	setPaymentMethod,
	submissionSubmit
} from './../actions';

import { validateEmail } from './../utility/validator';
import  * as selectors from './../selectors';
import { parseSubmissionData } from './../utility/helpers';
import { setSubmissionPresentations, submissionFetch } from './../actions/SubmissionActions';

/**
 * Fetch existing form submission.
 *
 * @param {string} formSubmissionID
 * @param {string} formSubmissionHash
 */
export function* submissionFetchSaga(action) {

	let { formSubmissionID, formSubmissionHash, paytrailPaymentResult, authToken, form } = action;

	try {
		yield put(setLoading(true, 'Loading submission'));

		// Submission fetch URL, set authToken parameter if defined
		let submissionFetchUrl = `public/formSubmissions/${formSubmissionID}/?h=${formSubmissionHash}`;
		if(authToken) {
			submissionFetchUrl += `&authToken=${authToken}`;
		}

		const response = yield api.get(submissionFetchUrl);
		const { formSubmission } = yield response.data.data;

		// If returned formSubmissionID is different, fetch the formSubmission again.
		if(formSubmission.formSubmissionID !== formSubmissionID) {
			formSubmissionID = yield formSubmission.formSubmissionID;
			formSubmissionHash = yield formSubmission.formSubmissionHash;
			yield put(submissionFetch({formSubmissionID, formSubmissionHash, authToken, form}));
		} else {

			try {
				yield put(submissionFetchSuccess(formSubmission, form));
			} catch (error) {
				throw error;
			}

			yield put(languageChange(formSubmission.languageId));
			
			/**
			 * Check if submission is cancelled.
			 */
			if(formSubmission.isCancelled) {
				yield put(setActiveView('cancelled'));
			}

			/**
			 * If there's paytrailPaymentResult present, check if submission has order
			 */
			if(formSubmission.order && formSubmission.order.status === 2 && paytrailPaymentResult && paytrailPaymentResult === 'true') {
				yield put(setActiveView('success'));
			}

			/*
			* If there's presentations, set them to localStorage
			*/
			if(formSubmission.person && formSubmission.person.event && formSubmission.person.event.presentations && formSubmission.formID === '2c8b88e0-f09c-11e9-a21a-ee6a04371b85') {
				let presentations = yield formSubmission.person.event.presentations.map(presentation => presentation.presentationID);
				yield localStorage.setItem('eventosPresentations', JSON.stringify(presentations));
				yield put(setSubmissionPresentations(formSubmissionID, presentations));
			}
		}
	} catch (error) {
		console.log(error)
		if(paytrailPaymentResult && paytrailPaymentResult === 'false') {
			yield put(setErrors([{
				title: 'Payments.paymentCancelledOrFailed.title',
				details: 'Payments.paymentCancelledOrFailed.details'
			}]));
		} else if(error.response && error.response.data && error.response.data.errors) {
			yield put(setErrors(error.response.data.errors));
		}
		yield put(submissionFetchFail());
		yield put(addSubmissionInstance());
	} finally {
		yield put(setLoading(false));
	}
}


/**
 * Submit form submission.
 *
 * @param {object} action
 */
export function* submitSaga(action) {

	try {

		const loadingText = action.requestSummary === 0 ? 'Submitting' : 'Validating submission';

		yield put(setLoading(true, loadingText));
		yield put(setErrors([]));

		if(action.paymentMethod) {
			yield put(setPaymentMethod(action.paymentMethod));
		}

		// This variable will hold the POST request data
		let data = yield new FormData();

		const form = yield select(selectors.form);
		const order = yield select(selectors.order);
		const submissionFields = yield select(selectors.submissionFields);
		const currentLanguageId = yield select(selectors.languageId);
		const paymentMethod = yield select(selectors.paymentMethod);
		const paymentMethodDefault = yield select(selectors.paymentMethodDefault);
		const billingInformation = yield select(selectors.billingInformation);
		const personID = yield select(selectors.personID);
		const partnerID = yield select(selectors.partnerID);
		const couponCode = yield select(selectors.couponCode);
		const formSubmissionID = yield select(selectors.formSubmissionID);
		const formSubmissionHash = yield select(selectors.formSubmissionHash);
		const formSubmissionRevision = yield select(selectors.formSubmissionRevision);
		const invoiceCustomers = yield select(selectors.invoiceCustomers);
		const multiRegMessagesReceiverEmails = yield select(selectors.multiRegMessagesReceiverEmails);
		const useManualConfirmationRecipients = yield select(selectors.useManualConfirmationRecipients);
		const submissionReservationID = yield select(selectors.submissionReservationID);
		const modifyExistingSubmission = yield select(selectors.modifyingExistingSubmission);
		const invoiceDeliveryMethod = yield select(selectors.invoiceDeliveryMethod);
		const presentationsBySubmissionId = yield select(selectors.presentationsBySubmissionId);
		const authToken = yield select(selectors.authToken);
		const bundlePersonNumber = yield select(selectors.bundlePersonNumber);



		// Set initial values for submission POST data
		const initialData = {
			formID: form.formID,
			...action,
			couponCode: couponCode || '',
			languageId: currentLanguageId,
			registeringCount: Object.values(submissionFields).length,
			submissionReservationID: submissionReservationID
		};

		if(formSubmissionID !== '' && formSubmissionHash !== '' && formSubmissionRevision !== '') {
			data.append('formSubmissionID', formSubmissionID);
			data.append('formSubmissionHash', formSubmissionHash);
			data.append('formSubmissionRevision', formSubmissionRevision);

			if(authToken) {
				data.append('authToken', authToken);
			}
		}

		for ( const [key, value] of Object.entries(initialData) ) {
			data.append(key, value);
		}

		if(action.updateOrder) {
			data.append("updateOrder", action.updateOrder);
		}

		if(action.requestSummary === 0 && useManualConfirmationRecipients) {
			data.append('multiRegMessagesReceiverEmails', multiRegMessagesReceiverEmails.value);
			if(multiRegMessagesReceiverEmails.value && !multiRegMessagesReceiverEmails.valid ) {
				let errors = [{
					title: 'Tarkista vahvistusviestien vastaanottaja',
					details: 'Vahvistusviestien vastaanottajaksi määritetty osoite ei ole kelvollinen.'
				}];
				throw errors;
			}
		}

		// Set payment method, remove if there's no order in final submit
		if( ((action.requestSummary === 1 && paymentMethod) || (action.requestSummary === 0 && order)) && (!modifyExistingSubmission || ( modifyExistingSubmission && authToken)) && (!bundlePersonNumber || bundlePersonNumber === 1) ) {
			data.append("paymentMethod", paymentMethod);
		} else if (action.requestSummary === 1 && form.products && paymentMethodDefault) {
			data.append("paymentMethod", paymentMethodDefault);
		}

		const invoicePayerIsCompany = yield select(selectors.invoicePayerIsCompany);

		// Add billing information
		if(billingInformation) {

			// Invoice
			if(parseInt(paymentMethod) === 1) {
				if(invoicePayerIsCompany && invoiceCustomers && invoiceCustomers.selected.length && invoiceCustomers.selected[0].invoiceCustomerID) {
					data.append('invoiceCustomerID', invoiceCustomers.selected[0].invoiceCustomerID);
				}
				for ( const [key, value] of Object.entries(billingInformation.fields) ) {
					if(invoiceDeliveryMethod !== 0 && (key === 'eInvoiceAddress' || key === 'eInvoiceIntermediateId')) {
						continue;
					}
					if(!invoicePayerIsCompany && key === 'invoiceCompany') {
						continue;
					}
					data.append(key, value);
				}
			}
			// Paytrail
			else if( parseInt(paymentMethod) === 2 && invoicePayerIsCompany) {
				// Add invoice fields when payer is company
				const invoiceFields = ['invoiceCompany', 'invoiceCompanyBusinessID', 'invoiceVatNumber', 'invoiceStreetAddress', 'invoiceCity', 'invoicePostalCode', 'invoiceAdditionalDetails', 'invoiceReference'];
				for ( const invoiceField of invoiceFields ) {
					if(typeof billingInformation.fields[invoiceField] !== 'undefined') {
						data.append(invoiceField, billingInformation.fields[invoiceField]);
					}
				}
			}

			// Parse & set invoiceVatNumber if Finnish form
			try {
				if(currentLanguageId === 1) {
					let { invoiceCompanyBusinessID } = billingInformation.fields;
					if(invoiceCompanyBusinessID && typeof invoiceCompanyBusinessID !== 'undefined') {
						// Check if businessID is inserted as Finnish VAT number
						if(invoiceCompanyBusinessID.substring(0, 2).toUpperCase() === 'FI') {
							let parsedInvoiceCompanyBusinessID = invoiceCompanyBusinessID.substring(2);
							data.set('invoiceVatNumber', invoiceCompanyBusinessID);
							data.set('invoiceCompanyBusinessID', parsedInvoiceCompanyBusinessID);
						} else {
							let parsedInvoiceVatNumber = `FI` + invoiceCompanyBusinessID.replace(/[^0-9]/g, '');
							data.set('invoiceVatNumber', parsedInvoiceVatNumber);
						}
					}
				}
			} catch(error) {
				console.log(error)
			}

			data.append('invoiceDeliveryMethod', invoiceDeliveryMethod);
			data.append('invoicePayerIsCompany', invoicePayerIsCompany);
		}

		if(!invoicePayerIsCompany) {
			data.delete('invoiceVatNumber');
			data.delete('invoiceCompanyBusinessID');
		}


		/* if(data && action.requestSummary === 0) {
			// Display the key/value pairs
			for (var pair of data.entries()) {
				console.log(pair[0]+ ', ' + pair[1]);
			}
			let errors = {
				title: 'General error',
				details: 'There was problem with parsing the form data.'
			};
			throw errors;
		} */


		// Parse submission data to suitable format for API
		try {
			yield parseSubmissionData(submissionFields, data, personID, partnerID, action.requestSummary, presentationsBySubmissionId);
		} catch (error) {
			let errors = {
				title: 'General error',
				details: 'There was problem with parsing the form data.'
			};
			throw errors;
		}

		// Make the API request
		const response = yield api.post('public/forms/submit', data, {
			headers: {
				'Content-Type': false
			}
		});

		// If invalid response from API.
		if(!response.data) {
			throw new Error('General error');
		}

		// If any errors
		if(response.data.errors && response.data.errors.length) {
			throw response.data.errors;
		}

		// Handle success
		if(action.requestSummary === 1) {

			// If response contains order and paymentMethod is not set, use default
			// and submit again to set possible payment method related products to order
			if(response.data.data.formSubmissions[0].order && !paymentMethod) {
				yield put(submissionSubmit({requestSummary: 1, paymentMethod: paymentMethodDefault}))
			}

			// Success callback
			yield put(submissionSubmitSuccess(response.data.data.formSubmissions));

			// Don't change view if checking coupon code or changing payment method
			if(!action.couponCode && !action.paymentMethod) {
				yield put(setActiveView('summary'));
			}

		} else {
			// Paytrail redirection
			if(
				response.data.data.formSubmissions[0].order &&
				paymentMethod === 2 &&
				parseFloat(response.data.data.formSubmissions[0].order.totalWithTax) > 0.00 &&
				!modifyExistingSubmission
			) {
				const orderID = yield response.data.data.formSubmissions[0].order.orderID;
				try {
					const response = yield api.get('public/paytrail/paymentForm/' + orderID);
					if(response.data.errors && response.data.errors.length) {
						throw response.data.errors;
					}
					yield put(fetchPaytrailFormDataSuccess(response.data.data.paytrail));
				} catch (error) {
					throw error;
				}
			} else {
				yield put(submissionSubmitSuccess(response.data.data.formSubmissions));
				yield put(setActiveView('success'));
			}
		}

		yield put(setLoading(false));

	} catch ( error ) {

		console.log(error);

		yield put(setLoading(false));

		// Field specific errors
		if(
			error.response &&
			error.response.data &&
			error.response.data.errors &&
			error.response.data.errors.length
		) {

			let fieldErrors = error.response.data.errors
				.filter(errorItem => errorItem.fields)
				.reduce((errors, error) => {
					errors = errors.concat(error.fields)
					return errors;
				}, []);

			for(const fieldError of fieldErrors) {

				const submissionFields = yield select(selectors.submissionFields);

				if(fieldError.property) {

					let { value, property } = fieldError;

					// If error is related to formElementOptionId
					if(property === 'formElementOptionId') {
						let formElementOptionId = parseInt(value);
						fieldLoop:
						for(const [submissionId, fields] of Object.entries(submissionFields)) {
							for(const field of Object.values(fields)) {
								if(
									(
										field.type === 'radio' ||
										field.type === 'checkbox' ||
										field.type === 'select'
									) &&
									(
										parseInt(field.value) === formElementOptionId ||
										(
											field.value &&
											Array.isArray(field.value) &&
											field.value.includes(formElementOptionId)
										)
									))
								{
									let errorPayload = {
										valid: false,
										errors: [{
											message: fieldError.message
										}]
									}
									if(field.type === 'radio') {
										errorPayload.value = '';
									}
									yield put(setInputValue(submissionId, field.formElementId, errorPayload));
									break fieldLoop;
								}
							}
						}
					}
					// If error is related to formElementId
					else if (property.split(':').length === 2) {
						let formElementId = fieldError.property.split(':')[1];
						fieldLoop:
						for(const [submissionId, fields] of Object.entries(submissionFields)) {
							for(const field of Object.values(fields)) {
								if(field.formElementId === parseInt(formElementId) && field.value === value) {
									let errorPayload = {
										valid: false,
										errors: [{
											message: fieldError.message
										}]
									}
									yield put(setInputValue(submissionId, formElementId, errorPayload));
									break fieldLoop;
								}
							}
						}
					}

				}
			}
		}


		// Map errors that require text modifications
		if(error.response && error.response.data && error.response.data.errors) {
			error.response.data.errors.map(errorItem => {
				const errorCode = parseInt(errorItem.code);
				switch(errorCode) {
					case 1000:
						if(errorItem.details === 'Form element not found from array!') {
							errorItem.details = 'form.formElementNotFoundFromArray';
						}
						break;
					case 1030:
						if(errorItem.fields &&
							errorItem.fields.length &&
							errorItem.fields[0].message &&
							errorItem.fields[0].value
						) {
							errorItem.details = `${errorItem.fields[0].message}`;
							errorItem.property = `${errorItem.fields[0].value}`;
						}
						break;
					case 1040:
						if(errorItem.details !== 'Form submission limit reached!') {
							errorItem.title = 'Sign up time expired';
							errorItem.details = 'Sign up is not allowed anymore';
						} else {
							// errorItem.title = 'Form submission limit reached';
							errorItem.details = 'App.formSubmissionLimitReachedMsg';
						}
						break;
					case 1060:
						if(errorItem.details === "Event is full") {
							errorItem.title = 'App.registrationFailed';
							errorItem.details = 'App.eventIsFull';
						}
						break;
					case 1080:
						//
						break;
					case 4010:
						errorItem.title = 'App.fileUploadFailed';
						// errorItem.details = 'App.fileUploadSizeExceeded';
						break;
					default:
						break;
				}
				return errorItem;
			});

			yield put(setErrors(error.response.data.errors));

		}
		else if (Array.isArray(error) && error.length > 0) {
			yield put(setErrors(error));
		}
		else {
			let errors = [{
				title: 'General error',
				details: 'Submission failed, please try again.'
			}];
			yield put(setErrors(errors));
		}

		yield put(submissionSubmitFail());
		yield put(setLoading(false));

	} finally {
		//yield put(setLoading(false));
	}
}


export function* emailAlreadyRegisteredSaga(action) {

	let payload = yield {
		value: action.value,
		valid: true,
		isValidated: true,
		errors: []
	};

	try {
		const formID = yield select(selectors.formID);
		const response = yield validateEmail(action.value, formID);

		// Extend the payload with request response
		payload = {...payload, ...response};

	} catch (errors) {
		console.error(errors);
	} finally {
		yield put(setInputValue(action.submissionId, action.formElementId, {...payload}));
	}
}

