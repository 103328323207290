import axios from 'axios';

export const getBaseUrl = (environment = null) => {

    let env = process.env.REACT_APP_ENVIRONMENT;

    if(environment) {
        env = environment;
    }

    switch (env) {
        case 'local':
            return 'https://api.eventos2.local/v1/';
        case 'development':
            return 'https://api-dev.eventos.fi/v1/';
        case 'demo':
            return 'https://api-demo.eventos.fi/v1/';
        case 'staging':
            return 'https://api-staging.eventos.fi/v1/';
        case 'production':
            return 'https://api.eventos.fi/v1/';
        default:
            return 'https://api.eventos2.local/v1/'
    }
}

const api = axios.create({
    baseURL: getBaseUrl()
});

// Add a response interceptor
api.interceptors.response.use(
	response => response,
	error => Promise.reject(error)
);


export default api;