
import { put, select } from 'redux-saga/effects';
import {
    fieldSetRequirement,
    fieldSetVisibility
} from '../actions';
import  * as selectors from './../selectors';

import { validateConditionBundle } from './../utility/conditions';


export function* conditionCheckSaga(action) {

    try {

        if(action.skipConditions) {
            return;
        }

        const { submissionId, formElementId } = yield action;

        // Selectors
        const conditionBundlesByTarget = yield select(selectors.conditionBundlesByTarget);
        const conditionBundlesById = yield select(selectors.conditionBundlesById);

        // Resolve element related formConditionBundles
        let formConditionBundles = yield [];
        if(conditionBundlesByTarget[formElementId] ) {
            formConditionBundles = yield conditionBundlesByTarget[formElementId].map(id => {
                return conditionBundlesById[id];
            });
        }

        // If there isn't any formElement related formConditionBundles
        if( formConditionBundles.length === 0 ) {
            return;
        }

        const fieldsById = yield select(selectors.fieldsById);
        const submissionFields = yield select(selectors.submissionFields);
        const submissionValues = yield {
            ...submissionFields[submissionId],
            [formElementId]: {
                ...submissionFields[submissionId][formElementId],
                ...action.payload
            }
        };

        formConditionBundles = formConditionBundles.sort((a,b) => {
            return a.weight - b.weight;
        });

        for ( const formConditionBundle of formConditionBundles ) {

            if(!formConditionBundle) {
                continue;
            }

            // Check if condition IFs are matched
            const formConditionMatched = validateConditionBundle(formConditionBundle, submissionValues, fieldsById);
            const formConditionActions = yield formConditionBundle.formConditionActions.filter(action => action.actionTarget === 'formElement');

            for ( let formConditionAction of formConditionActions ) {

                // If formElement is not present in submission
                const { formElementId } = formConditionAction.actionParameter;
                if( !formElementId || !submissionValues[formElementId] || !fieldsById[formElementId] ) {
                    continue;
                }

                // Default properties of formElement / field
                const { defaults } = fieldsById[formElementId];

                // If current visibility state is same as default, and condition is not matched, skip
                if ( submissionValues[formElementId].hidden === defaults.hidden && !formConditionMatched && (formConditionAction.actionType === 2 || formConditionAction.actionType === 3) ){
                    // continue;
                }

                switch(formConditionAction.actionType) {
                    case 0: // Require
                    case 1: // Unrequire
                        const required = formConditionAction.actionType === 0;
                        yield put(fieldSetRequirement(submissionId, formElementId, formConditionMatched ? required : defaults.required));
                        break;
                    case 2: // Hide
                    case 3: // Show
                        const hidden = formConditionAction.actionType === 2;
                        yield put(fieldSetVisibility(submissionId, formElementId, formConditionMatched ? hidden : defaults.hidden));
                        // yield put(setInputValue(submissionId, formElementId, {hidden: formConditionMatched ? hidden : defaults.hidden}))
                        break;
                    default:
                        break;
                }
            }
        }

    } catch (errors) {
        console.log(errors);
        // yield put(setLoading(false));
    }
}

