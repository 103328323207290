
import {
    RESERVATION_SAVE,
    RESERVATION_SAVE_SUCCESS,
    RESERVATION_SAVE_FAIL
} from '../actions/ActionTypes';

const initialState = {
    submissionReservationID: null,
    data: null,
    isFull: false,
    isProcessing: false,
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case RESERVATION_SAVE:
            return {
                ...state,
                error: null,
                isProcessing: true
            }
        case RESERVATION_SAVE_SUCCESS:
            return {
                ...state,
                submissionReservationID: action.submissionReservation.submissionReservationID,
                data: action.submissionReservation,
                isFull: false,
                error: null,
                isProcessing: false
            }
        case RESERVATION_SAVE_FAIL:
            return {
                ...state,
                error: action.error,
                isFull: action.error.code && parseInt(action.error.code) === 1060,
                isProcessing: false
            }
        default:
            return state;
    }
}