import api from '../api';
import validator from 'validator';
import i18next from 'i18next';

import translations from '../translations/validations/translations.json';

const defaultLanguage = 'en';

const numberRegExp = /^0[0-9].*$/;


/**
 * Validate field of a submission.
 *
 * @param {object} field
 */
export const validateField = (field) => {

    let result = {
        valid: true,
        errors: [],
        isValidated: true
    };

    /**
     * Loop through validation definitions
     */
    for ( const [key, rule] of Object.entries(field.validation) ) {
        switch(key) {
            case 'required':
                if( rule ) {
                    switch(field.type) {
                        case 'radio':
                        case 'checkbox':
                        case 'select':
                        case 'scalerating':
                        case 'iconrating':
                        case 'nps':
                            if(!field.value || field.value === null || field.value.length === 0) {
                                result.valid = false;
                                result.errors.push({
                                    reason: key,
                                    message: translations[key][i18next.language] || translations[key][defaultLanguage],
                                    formElementId: field.formElementId,
                                });
                            }
                            break;
                        case 'file':
                            if((field.formUploads && field.formUploads.length === 0) || (!field.formUploads &&(!field.value || field.value === null || !field.value.length))) {
                                result.valid = false;
                                result.errors.push({
                                    reason: key,
                                    message: translations[key][i18next.language] || translations[key][defaultLanguage],
                                    formElementId: field.formElementId,
                                });
                            }
                            break;
                        default:
                            if(!field.value || field.value === null || field.value === '' || validator.isEmpty(field.value)) {
                                result.valid = false;
                                result.errors.push({
                                    reason: key,
                                    message: translations[key][i18next.language] || translations[key][defaultLanguage],
                                    formElementId: field.formElementId,
                                });
                            }
                            break;
                    }
                }
                break;
            case 'min':
                if( rule !== null && rule !== '' && field.type === 'number' ) {
                    if(parseInt(field.value) < parseInt(rule)) {
                        let message = translations[key][i18next.language] || translations[key][defaultLanguage];
                        message = message.replace('%s', rule);
                        result.valid = false;
                        result.errors.push({
                            reason: key,
                            message: message,
                            formElementId: field.formElementId,
                        });
                    }
                }
                break;
            case 'max':
                    if( rule !== null && rule !== '' && parseInt(rule) !== 0 && field.type === 'number' ) {
                        if(parseInt(field.value) > parseInt(rule)) {
                            let message = translations[key][i18next.language] || translations[key][defaultLanguage];
                            message = message.replace('%s', rule);
                            result.valid = false;
                            result.errors.push({
                                reason: key,
                                message: message,
                                formElementId: field.formElementId,
                            });
                        }
                    }
                    break;
            case 'mincheck':
                if( rule !== null && rule !== '' && parseInt(rule) !== 0 && field.type === 'checkbox' ) {
                    if(field.value.length < rule ) {
                        let message = translations[key][i18next.language] || translations[key][defaultLanguage];
                        if(rule === "1") {
                            message = translations[key+"_single"][i18next.language] || translations[key+"_single"][defaultLanguage];
                        }
                        message = message.replace('%s', rule);
                        result.valid = false;
                        result.errors.push({
                            reason: key,
                            message: message,
                            formElementId: field.formElementId,
                        });
                    }
                }
                break;
            case 'maxcheck':
                if( rule !== null && rule !== '' && parseInt(rule) !== 0 && field.type === 'checkbox' ) {
                    if(field.value.length > rule ) {
                        let message = translations[key][i18next.language] || translations[key][defaultLanguage];
                        if(rule === "1") {
                            message = translations[key+"_single"][i18next.language] || translations[key+"_single"][defaultLanguage];
                        }
                        message = message.replace('%s', rule);
                        result.valid = false;
                        result.errors.push({
                            reason: key,
                            message: message,
                            formElementId: field.formElementId,
                        });
                    }
                }
                break;
            default:
                break;
        }
    }

    /**
     * Check element type specific validations.
     * Run this block only if there isn't any other validation errors.
     */
    if( field.value && field.value !== null && field.value !== "" ) {

        /**
         * Number
         */
         if(field.type === 'number' && ( numberRegExp.test(field.value) || (!validator.isNumeric(field.value) || !validator.isInt(field.value))) ) {
            result.valid = false;
            result.errors.push({
                formElementId: field.formElementId,
                message: translations.number[i18next.language] || translations.number[defaultLanguage]
            });
        }

        /**
         * Emails
         */
        if((field.type === 'email' || field.mappedAs === 'email') && !validator.isEmail(field.value)) {
            if(!validator.isEmail(field.value)) {
                result.valid = false;
                result.errors.push({
                    formElementId: field.formElementId,
                    message: translations.email[i18next.language] || translations.email[defaultLanguage],
                });
            } else if (field.usedEmails && field.usedEmails.length && field.usedEmails.indexOf(field.value) > -1) {
                result.valid = false;
                result.errors.push({
                    formElementId: field.formElementId,
                    message: translations.emailUsed[i18next.language] || translations.emailUsed[defaultLanguage],
                });
            }
        }

        if(field.formElementId === 33513) {
            const naeRy = ['E5955', 'E6110', 'E5022', 'E5434', 'E5120', 'E5062', 'E5105', 'E5030', 'E6108', 'E1017', 'E1068', 'E5169', 'E6095', 'E5244', 'E5579', 'E5086', 'E5568', 'E7121', 'E5214', 'E5241', 'E1007', 'E5046', 'E5042', 'E1099', 'E5310', 'E5040', 'E5101', 'E5023', 'E5386', 'E1037', 'E5171', 'E5175', 'E1046', 'E1002', 'E1081', 'E5856', 'E7126', 'E5476', 'E5010', 'E5002', 'E5059', 'E5117', 'E5884', 'E1023', 'E5047', 'E5026', 'E5116', 'E6103', 'E5436', 'E5039', 'E5657', 'E5118', 'E5361', 'E5982', 'E5005', 'E6111', 'E5334', 'E5977', 'E6100', 'E5638', 'E5938', 'E5049', 'E6094', 'E5097', 'E5227', 'E5141', 'E5108', 'E5021', 'E1062', 'E5352', 'E5067', 'E5051', 'E1019', 'E5135', 'E1110', 'E7120', 'E5185', 'E1010', 'E5068', 'E6102', 'E5972', 'E5007', 'E5745', 'E5260', 'E5181', 'E5438', 'E6107', 'E5588', 'E5054', 'E5441', 'E5061', 'E5009', 'E5919', 'E5050', 'E5983', 'E1090', 'E5299', 'E1087', 'E6091', 'E5641', 'E6098', 'E1061', 'E5045', 'E5119', 'E7119', 'E1008', 'E1060', 'E5073', 'E5060', 'E5102', 'E5258', 'E1006', 'E1078', 'E5004'];
            if(naeRy.indexOf(field.value) === -1) {
                result.valid = false;
                result.errors.push({
                    formElementId: field.formElementId,
                    message: 'Membership number seems to be inactive.',
                });
            }
        }

        /**
         * Phone numbers
         */
         /*
         else if (field.type === 'tel' && !validator.isMobilePhone(field.value, 'any')) {
            result.valid = false;
            result.errors.push({
                formElementId: field.formElementId,
                message: translations.phone[i18next.language] || translations.phonel[defaultLanguage],
            });
        }
        */

        /**
         * Postal codes / ZIP
         */
        /*
        else if (field.mappedAs === 'postalCode'  && !validator.isPostalCode(field.value, locale))  {
            result.valid = false;
            result.errors.push({
                formElementId: field.formElementId,
                message: translations.postalCode[i18next.language] || translations.postalCode[defaultLanguage],
            });
        }
        */
    }

    return result;
}

export const validateBillingInformation = (billingInformation, paymentMethod, languageId, eventID) => {

    const { fields } = billingInformation;
    let valid = true;
    let errors = {};

    // Check required fields
    let requiredFields = getRequiredBillingInformationFields(billingInformation, paymentMethod, languageId, eventID);

    requiredFields.forEach(key => {
        const fieldValidation = validateBillingInformationField(key, fields[key], billingInformation, paymentMethod, languageId, eventID);
        errors[key] = fieldValidation;
        if(!fieldValidation.valid) {
            valid = false;
        }
    });

    return {
        valid: valid,
        errors: errors
    };
}


/**
 * Validate field of a billing information / invoice data.
 *
 * @param {object} name (the field name)
 * @param {string} value (provided value)
 * @return {object}
 */
export const validateBillingInformationField = (name, value, billingInformation, paymentMethod, languageId, eventID) => {


    let result = {
        valid: true,
        name: name,
        errors: []
    };

    let requiredFields = getRequiredBillingInformationFields(billingInformation, paymentMethod, languageId, eventID);

    if(!value || (requiredFields.indexOf(name) !== -1 && validator.isEmpty(value)) ) {
        result.valid = false;
        result.errors.push({
            name: name,
            message: translations.required[i18next.language] || translations.required[defaultLanguage],
        });
    }

    return result;
}

function getRequiredBillingInformationFields(billingInformation, paymentMethod = 1, languageId, eventID = null) {


    // GPD 2022 customization
    if(eventID) {
        if(eventID === '8b4b0c8a-d030-11eb-a21a-ee6a04371b85' || eventID === '2c38c9ce-405f-11ec-89e2-080027a0fbc5') {

            if(paymentMethod === 2) {
                // Paytrail
                if(languageId === 2) {
                    // English
                    return ['invoiceCompany', 'invoiceVatNumber',  'invoiceStreetAddress', 'invoicePostalCode', 'invoiceCity'];
                } else {
                    return ['invoiceCompany', 'invoiceCompanyBusinessID', 'invoiceStreetAddress', 'invoicePostalCode', 'invoiceCity'];
                }

            } else if(paymentMethod === 1) {
                // Invoice
                if(languageId === 2) {
                    // English
                    if(billingInformation.invoiceDeliveryMethod === 0) {
                        // Delivery method is eInvoice
                        return ['invoiceCompany', 'invoiceStreetAddress', 'invoicePostalCode', 'invoiceCity', 'eInvoiceAddress', 'eInvoiceIntermediateId'];
                    }
                    return ['invoiceCompany', 'invoiceStreetAddress', 'invoicePostalCode', 'invoiceCity'];
                }
            }
        }
    }



    if(paymentMethod === 2) {
        if(languageId === 1) {
            return ['invoiceCompany', 'invoiceCompanyBusinessID',  'invoiceStreetAddress', 'invoicePostalCode', 'invoiceCity'];
        } else {
            return ['invoiceCompany', 'invoiceCompanyBusinessID', 'invoiceStreetAddress', 'invoicePostalCode', 'invoiceCity'];
        }
    }


    // Default required billing information fields
    let requiredFields = ['invoiceStreetAddress', 'invoicePostalCode', 'invoiceCity'];

    // Payer is company
    if(billingInformation.invoicePayerIsCompany) {
        requiredFields.push('invoiceCompany');
        requiredFields.push('invoiceCompanyBusinessID');
        // requiredFields.push('invoiceReference');

        // LPSY 2023 KEVÄTKOULUTUSPÄIVÄT custom viite pakolliseksi
        if(eventID && eventID === 'c8d0487d-9e09-11ed-a018-ee6a04371b85') {
            requiredFields.push('invoiceReference');
        }

        // Delivery method is eInvoice
        if(billingInformation.invoiceDeliveryMethod === 0) {
            requiredFields.push('eInvoiceAddress');
            requiredFields.push('eInvoiceIntermediateId');
        }
    }
    // Payer is individual
    else {
        requiredFields.push('invoiceFirstNames');
        requiredFields.push('invoiceLastName');
    }

    // Invoice delivery method is email
    if(billingInformation.invoiceDeliveryMethod === 1) {
        requiredFields.push('invoiceDeliveryEmail');
    }

    return requiredFields;
}

export const validatePhone = phone => {

    return api.get('public/validate/phone/' + encodeURIComponent(phone))
        .then(() => {
            return {
                valid: true,
                errors: []
            };
        })
        .catch((error) => {
            return  {
                valid: false,
                errors: [{
                    reason: '',
                    message: 'form.validation.general.invalid_phone'
                }]
            }
        });
}

export const validateEmail = (email, formID) => {

    const url = 'public/validate/emailUsedForForm/' + encodeURIComponent(email) + '/' + formID;

    return api.get(url)
        .then(() => {
            return {
                isValidated: true,
                valid: true,
                errors: []
            };
        })
        .catch(error => {
            let errorResponse = {
                isValidated: true,
                valid: false,
                errors: [{
                    reason: '',
                    message: 'form.validation.general.invalid_email'
                }]
            };

            if(error.response && error.response.data && error.response.data.errors) {
                errorResponse.errors = error.response.data.errors.map(error => {
                    return {
                        ...error,
                        reason: error.title,
                        message: error.details
                    }
                })
            }

            return errorResponse;
        });
}
