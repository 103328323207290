import {
    PAYMENT_METHOD_SET,
    PAYTRAIL_SET_REDIRECTING,
    PAYTRAIL_SET_STATUS,
    PAYTRAIL_FETCH_FORMDATA,
    PAYTRAIL_FETCH_FORMDATA_SUCCESS,
    PAYTRAIL_FETCH_FORMDATA_FAIL,
    CHECK_COUPON_CODE
 } from './ActionTypes';

 export const setPaymentMethod = (paymentMethod) => {
    return {
        type: PAYMENT_METHOD_SET,
        paymentMethod: paymentMethod
    }
}

export const setPaytrailRedirecting = (status = true) => {
    return {
        type: PAYTRAIL_SET_REDIRECTING,
        redirecting: status
    }
}

export const setPaytrailPaymentStatus = (status) => {
    return {
        type: PAYTRAIL_SET_STATUS,
        paymentStatus: status
    }
}

export const fetchPaytrailFormData = orderID => {
    return {
        type: PAYTRAIL_FETCH_FORMDATA,
        orderID: orderID
    }
}

export const fetchPaytrailFormDataSuccess = paytrailFormData => {
    return {
        type: PAYTRAIL_FETCH_FORMDATA_SUCCESS,
        paytrailFormData: paytrailFormData
    }
}

export const fetchPaytrailFormDataFail = payload => {
    return {
        type: PAYTRAIL_FETCH_FORMDATA_FAIL,
        ...payload
    }
}

export const checkCouponCode = couponCode => {
    return {
        type: CHECK_COUPON_CODE,
        couponCode: couponCode,
        requestSummary: 1
    }
}

