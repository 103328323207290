import React from 'react';
import { Input, Label } from 'reactstrap';
import { PropTypes } from 'prop-types';

export default function BillinginformationField({ name, label, required, fields, isValidated, validation, defaultValue, onChange }) {
    return (
        <React.Fragment>

            {label &&
                <Label for={name}>{label}</Label>}

            <Input
                type="text"
                name={name}
                id={name}
                value={fields[name] || (defaultValue ||'')}
                onChange={onChange}
                required={required}
                valid={(validation[name] && validation[name].valid)}
                invalid={isValidated && (validation[name] && !validation[name].valid) && isValidated}
            />

        </React.Fragment>
    )
}

BillinginformationField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    fields: PropTypes.object,
    isValidated: PropTypes.bool,
    validation: PropTypes.object,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func
};