// import moment from 'moment';
import _ from 'lodash';
import moment from 'moment-timezone'

import {
    FORM_FETCH_SUCCESS,
    SUBMISSION_FETCH,
    SUBMISSION_FETCH_SUCCESS,
    SUBMISSION_FETCH_FAIL,
    SUBMISSION_SUBMIT,
    SUBMISSION_SUBMIT_SUCCESS,
    SUBMISSION_SUBMIT_FAIL,
    ADD_SUBMISSION_INSTANCE,
    REMOVE_SUBMISSION_INSTANCE,
    SET_SUBMISSION_VALUE,
    FIELD_SET_VALIDATION,
    FIELD_SET_VISIBILITY,
    FIELD_SET_REQUIREMENT,
    COUPON_CODE_UPDATE,
    SUBMISSION_SET_VALIDATION_ERRORS,
    CONFIRMATION_EMAIL_SEND_METHOD_TOGGLE,
    SET_MULTIREGISTRATION_MESSAGE_RECEIVER_EMAILS,
    SET_PERSONID,
    SET_PARTNERID,
    PERSON_FETCH_SUCCESS,
    SET_USE_MANUAL_EMAIL_RECIPIENTS,
    PRESENTATION_ADD,
    PRESENTATION_REMOVE,
    SET_SUBMISSION_PRESENTATIONS
} from "../actions/ActionTypes";

const initialState = {
    couponCode: '',
    defaultFormElements: {},
    fields: {},
    formSubmissionID: '',
    formSubmissionHash: '',
    formSubmissionRevision: '',
    formUploads: {
        byFieldId: {}
    },
    fieldQuotas: {},
    loading: false,
    modifyingExistingSubmission: false,
    messagesSentOnlyToMainSubmitter: false,
    forceMainSubmitter: false,
    useManualConfirmationRecipients: false,
    order: null,
    oldOrderID: null,
    orderModifiable: true,
    personID: null,
    partnerID: null,
    submissionIds: [],
    submissionResponse: null,
    validationErrors: {},
    products: {
        byProductId: {}
    },
    multiRegMessagesReceiverEmails: {
        value: null,
        valid: null,
        isValidated: false
    },
    optionsWithProductConditions: {
        formElementOptionId: [],
        formElementId: []
    },
    authCode: null,
    timeZone: 'Europe/Helsinki',
    presentationsBySubmissionId: {}
};

export default function(state = initialState, action) {
    switch (action.type) {
        case FORM_FETCH_SUCCESS:
            const forceEmailToMainSubmitter = shouldForceEmailToMainSubmitter(action.form.pages);
            return {
                ...state,
                defaultFormElements: parseDefaultFormElements(action.form),
                fieldQuotas: setFieldQuotas(action.form.pages),
                messagesSentOnlyToMainSubmitter: forceEmailToMainSubmitter,
                forceMainSubmitter: forceEmailToMainSubmitter
            };
        case ADD_SUBMISSION_INSTANCE:
            const formSubmissionKey = action.submissionId || moment().format('x');
            return {
                ...state,
                submissionIds: [...state.submissionIds, formSubmissionKey],
                fields: {
                    ...state.fields,
                    [formSubmissionKey]: getSubmissionDefaultFormElements(state.defaultFormElements, !state.submissionIds.length)
                },
                presentationsBySubmissionId: setPresentationsById(state, formSubmissionKey)
            }
        case REMOVE_SUBMISSION_INSTANCE:
            let updatedFields = {...state.fields};
            let updatedPresentations = {...state.presentationsBySubmissionId};
            delete updatedFields[action.submissionId];
            delete updatedPresentations[action.submissionId];
            return {
                ...state,
                submissionIds: state.submissionIds.filter(submissionId => submissionId !== action.submissionId),
                fields: updatedFields,
                presentationsBySubmissionId: updatedPresentations
            }
        case SUBMISSION_FETCH:
            return {
                ...state,
                formSubmissionHash: action.formSubmissionHash,
                formSubmissionID: action.formSubmissionID,
                loading: true
            }
        case SUBMISSION_FETCH_SUCCESS:
            const { formSubmissionID, bundlePersonNumber, values, authToken, order } = action.formSubmission;
            let optionsWithProductConditions = false;
            if(!authToken || (order && order.userModifiedBy !== null)) {
                optionsWithProductConditions = setOptionsWithProductConditions(action.form);
            }
            return {
                ...state,
                loading: false,
                modifyingExistingSubmission: true,
                bundlePersonNumber: bundlePersonNumber,
                formSubmissionID: formSubmissionID,
                formSubmissionHash: action.formSubmission.formSubmissionHash ? action.formSubmission.formSubmissionHash : state.formSubmissionHash,
                formSubmissionRevision: action.formSubmission.formSubmissionRevision,
                personID: action.formSubmission.personID,
                partnerID: action.formSubmission.partnerID,
                submissionResponse: action.formSubmission,
                submissionIds: [formSubmissionID],
                order: action.formSubmission.order,
                oldOrderID: action.formSubmission.order ? action.formSubmission.order.orderID : null,
                authToken: authToken || null,
                fields: {
                    [formSubmissionID]: parseSubmissionFormElements(state.defaultFormElements, values, bundlePersonNumber, formSubmissionID, optionsWithProductConditions, authToken, order)
                },
                orderModifiable: order ? (order.paymentMethod === 1 && order.userModifiedBy === null) : true,
                presentationsBySubmissionId: {
                    [formSubmissionID]: []
                },
                formUploads: {
                    ...state.formUploads,
                    byFieldId: formUploadsByFieldId(action.formSubmission.formUploads)
                }
            }
        case SUBMISSION_FETCH_FAIL:
            return {
                ...state,
                loading: false,
                formSubmissionID: initialState.formSubmissionID,
                formSubmissionHash: initialState.formSubmissionHash,
                personID: null,
                partnerID: null
            };
        case SUBMISSION_SUBMIT:
            return {
                ...state,
                submitting: true
            }
        case SUBMISSION_SUBMIT_SUCCESS:
            return {
                ...state,
                submitting: false,
                submissionResponse: action.submissionResponse,
                order: action.submissionResponse[0].order
            }
        case SUBMISSION_SUBMIT_FAIL:
            return {
                ...state,
                submitting: false,
                submitErrors: [action.errors]
            }
        case SET_SUBMISSION_VALUE:
            return {
                ...state,
                fields: updateSubmissionFieldValue(state.fields, action.submissionId, action.formElementId, action.payload)
            }
        case FIELD_SET_VALIDATION:
            return {
                ...state,
                fields: updateSubmissionFieldValue(state.fields, action.submissionId, action.formElementId, action.validation)
            }
        case FIELD_SET_REQUIREMENT:
            return {
                ...state,
                fields: updateSubmissionFieldValue(state.fields, action.submissionId, action.formElementId, {required: action.required})
            }
        case FIELD_SET_VISIBILITY:
            return {
                ...state,
                fields: updateSubmissionFieldValue(state.fields, action.submissionId, action.formElementId, {hidden: action.hidden}, state.modifyingExistingSubmission)
            }
        case COUPON_CODE_UPDATE:
            return {
                ...state,
                couponCode: action.couponCode
            }
        case SUBMISSION_SET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.validationErrors
            }
        case CONFIRMATION_EMAIL_SEND_METHOD_TOGGLE:
            return {
                ...state,
                messagesSentOnlyToMainSubmitter: !state.messagesSentOnlyToMainSubmitter
            }
        case SET_MULTIREGISTRATION_MESSAGE_RECEIVER_EMAILS:
            return {
                ...state,
                multiRegMessagesReceiverEmails: {
                    value: (action.email && action.email.length > 0) ? action.email : null,
                    valid: action.valid,
                    isValidated: action.isValidated
                }
            }
        case SET_USE_MANUAL_EMAIL_RECIPIENTS:
                return {
                    ...state,
                    useManualConfirmationRecipients: action.value
                }
        case SET_PERSONID:
                return {
                    ...state,
                    personID: action.personID ? action.personID : null
                }
        case SET_PARTNERID:
                return {
                    ...state,
                    partnerID: action.partnerID  || null
                }
        case PERSON_FETCH_SUCCESS:
            const firstInstanceIndex = Object.keys(state.fields)[0];
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [firstInstanceIndex]: Object.values(state.fields[firstInstanceIndex]).reduce((fields, field) => {
                        if(field.mappedAs === 'email') {
                            field.value = action.person.emails[0].email;
                            field.disabled = true;
                        } else if(field.mappedAs === 'firstNames' || field.mappedAs === 'lastName') {
                            field.value = action.person[field.mappedAs];
                        }
                        fields[field.formElementId] = field;
                        return fields;
                    }, {})
                }
            }
        case PRESENTATION_ADD:
            let presentationsAfterAdd = [...state.presentationsBySubmissionId[action.submissionId]];
            if(presentationsAfterAdd.indexOf(action.presentationID) === -1) {
                presentationsAfterAdd.push(action.presentationID);
            }
            return {
                ...state,
                presentationsBySubmissionId: {
                    ...state.presentationsBySubmissionId,
                    [action.submissionId]: presentationsAfterAdd
                }
            }
        case PRESENTATION_REMOVE:
            return {
                ...state,
                presentationsBySubmissionId: {
                    ...state.presentationsBySubmissionId,
                    [action.submissionId]: state.presentationsBySubmissionId[action.submissionId].filter(presentationID => presentationID !== action.presentationID)
                }
            }
        case SET_SUBMISSION_PRESENTATIONS:
                return {
                    ...state,
                    presentationsBySubmissionId: {
                        ...state.presentationsBySubmissionId,
                        [action.submissionId]: action.presentations
                    },
                    fields: {
                        ...state.fields,
                        [action.submissionId]: setFieldValuesByPresentations(state.fields[action.submissionId], action.presentations, action.fromLocalStorage)
                    }
                }
        default:
            return state;
    }
};


/**
 * Set field values if presentations are set from localStorage
 * (Made for CGI Ratkaisu)
 *
 * @param {object} fields
 * @param {array} presentations
 * @param {boolean} fromLocalStorage
 */
const setFieldValuesByPresentations = (fields, presentations, fromLocalStorage = false) => {

    if(!fromLocalStorage) {
        return fields;
    }

    // Loop through submission's formElements
    for ( const [formElementId, formElement] of Object.entries(fields) ) {
        // If formElement type is checkbox
        if(formElement.type === 'checkbox' && formElement.mappedAs !== 'interests' && formElement.options && formElement.options.length > 0) {
            // Loop through options
            formElement.options.forEach(option => {
                 // Loop through presentations that are bind to the option
                option.presentations.forEach(presentation => {
                    // If presentationID is bind to option, add the option's formElementOptionId to element's value
                    if(presentations.indexOf(presentation.presentationID) > -1 && formElement.value.indexOf(presentation.presentationID) === -1) {
                        formElement.value.push(option.formElementOptionId);
                    }
                })
            });
            // Update formElement
            fields[formElementId] = formElement;
        }
    }
    return fields;
}

/**
 * Parse values of a submission to usable format.
 *
 * @param {object} defaultFormElements
 * @param {object} formSubmissionValues
 * @param {int} bundlePersonNumber
 * @param {string} formSubmissionID
 * @param {array} optionsWithProductConditions
 * @param {string|null} authToken
 * @param {object|null} order
 */
const parseSubmissionFormElements = (defaultFormElements, formSubmissionValues, bundlePersonNumber, formSubmissionID, optionsWithProductConditions, authToken, order) => {

    // Is submitter first in bundle or only registrant
    const isFirst = (!bundlePersonNumber || bundlePersonNumber === 1);

    // Get default submission formElements (filters out elements if not first)
    let submissionDefaultFormElements = getSubmissionDefaultFormElements(defaultFormElements, isFirst);

    // Update default formElements with formSubmission values
    formSubmissionValues.map(formElement => {
        const { formElementId, formElementOptionId, value, modifiable } = formElement;
        if(submissionDefaultFormElements[formElementId]) {
            switch(formElement.element.type){
                case 'datepicker':

                    let dateValue;
                    let updatedValue = value;
                    let splittedValue = value.split('.');

                    // If date is stored in format 'DD.MM.YYYY' (which it should be)
                    if (splittedValue && splittedValue.length === 3) {
                        dateValue = moment().year(splittedValue[2]).month(parseInt(splittedValue[1]) - 1).date(splittedValue[0]).toDate();
                    }
                    else {
                         // If date is stored in format 'Mon Jul 01 2019 00:00:00 GMT-0300 (Brasilian normaaliaika)'
                        let splittedDate = value.split(' ');
                        if(splittedDate.length >= 4) {
                            dateValue = moment().year(splittedDate[3]).month(splittedDate[1]).date(splittedDate[2]);
                            let day = dateValue.format('D');
                            let month = dateValue.format('M');
                            let year = dateValue.format('Y');
                            updatedValue = day + '.' + month + '.' + year;
                            dateValue = dateValue.toDate();
                        }
                        // If date is stored in format '2019-08-31T15:00:00+03:00'
                        else {
                            updatedValue = value.replace(/-/g, '.').substring(0, 10);
                            splittedValue = updatedValue.split('.').reverse();
                            if(splittedValue.length === 3) {
                                dateValue = moment().year(splittedValue[2]).month(parseInt(splittedValue[1]) - 1).date(splittedValue[0]).toDate();
                            }
                        }
                    }

                    submissionDefaultFormElements[formElementId].dateValue = dateValue;
                    submissionDefaultFormElements[formElementId].value = updatedValue;

                    break;
                case 'checkbox':
                    // Mapped as interests
                    if(defaultFormElements[formElementId].mappedAs === 'interests') {
                        submissionDefaultFormElements[formElementId].value.push(value);
                    } else {
                        if(!Array.isArray(submissionDefaultFormElements[formElementId].value)) {
                            submissionDefaultFormElements[formElementId].value = [formElementOptionId];
                        } else {
                            submissionDefaultFormElements[formElementId].value.push(formElementOptionId);
                        }

                        // Make sure that options are not disabled if current user
                        submissionDefaultFormElements[formElementId].options.map(option => {
                            let { maxSubmissions, submissionCount } = option;
                            if(maxSubmissions === submissionCount && submissionDefaultFormElements[formElementId].value.indexOf(option.formElementOptionId) > -1) {
                                option.disabled = false;
                                option.ownChoice = true;
                            }
                            return option;
                        });
                    }

                    break;
                case 'radio':
                case 'select':
                    if(formElementOptionId && formElementOptionId !== 0) {
                        submissionDefaultFormElements[formElementId].value = formElementOptionId;
                    } else {
                        submissionDefaultFormElements[formElementId].value = value;
                    }
                    submissionDefaultFormElements[formElementId].options.map(option => {
                        let { maxSubmissions, submissionCount } = option;
                        if(maxSubmissions === submissionCount && submissionDefaultFormElements[formElementId].value === option.formElementOptionId) {
                            option.disabled = false;
                        }
                        return option;
                    });
                    break;
                default:
                    if(formElementOptionId && formElementOptionId !== 0) {
                        submissionDefaultFormElements[formElementId].value = formElementOptionId;
                    } else {
                        submissionDefaultFormElements[formElementId].value = value;
                    }
                    break;
            }
            if(formElement.countryCode) {
                submissionDefaultFormElements[formElementId].countryCode = formElement.countryCode || null;
                submissionDefaultFormElements[formElementId].value = formElement.countryCode || value;
            }
            submissionDefaultFormElements[formElementId].disabled = !modifiable;
        }
        return formElement;
    });

    // Disable element or options if products are related (when modifying via public url)
    if(
        (!authToken && formSubmissionID) ||
        (order && (order.userModifiedBy !== null || order.paymentMethod === 2))
    ) {
        for ( const [formElementId, element] of Object.entries(submissionDefaultFormElements) ) {

            const { type, options } = element;

            // Check only for checkbox, radio, select that have options
            if((type === 'checkbox' || type === 'radio' || type === 'select') && element.options.length) {

                // Enable checkbox options that don't have products binded
                if(type === 'checkbox') {
                    submissionDefaultFormElements[formElementId].options.map(option => {
                        if(option.products.length > 0) {
                            option.disabled = true;
                        }
                        return option;
                    });
                }
                 // Disable the element (radio, select) if options have products binded
                else {
                    for(const option of options) {
                        if(option.products && option.products.length) {
                            submissionDefaultFormElements[formElementId].disabled = true;
                        }
                    }
                }

                // Set option disabled if it has 'add product to order' condition
                if(optionsWithProductConditions && optionsWithProductConditions.formElementOptionId.length) {
                    let updatedOptions = element.options.map(option => {
                        if(optionsWithProductConditions.formElementOptionId.indexOf(option.formElementOptionId) > -1) {
                            option.disabled = true;
                        }
                        return option;
                    });
                    submissionDefaultFormElements[formElementId].options = updatedOptions;
                }
            }

            if(optionsWithProductConditions && optionsWithProductConditions.formElementId.length && optionsWithProductConditions.formElementId.indexOf(parseInt(formElementId)) > -1) {
                if(type === 'checkbox') {
                    submissionDefaultFormElements[formElementId].options.map(option => {
                        option.disabled = true;
                        return option;
                    });
                } else {
                    submissionDefaultFormElements[formElementId].disabled = true;
                }
            }
        }

    }

    return submissionDefaultFormElements;
}

/**
 * Return formElements and default values for a submission.
 *
 * @param {object} defaultFormElements
 * @param {boolean} isFirst some fields are shown only for first submitter
 * @return object
 */
const getSubmissionDefaultFormElements = (defaultFormElements, isFirst) => {

    return Object.values(defaultFormElements)

        // Filter out fields that are not supposed to be shown for other than first registrant
        .filter(field => (isFirst || (!isFirst && field.duplicateInMultiReg !== false)))

        // Sort fields by order
        .sort((a, b) =>  a.order - b.order)

        // Sort fields by pageIndex
        .sort((a, b) => a.pageIndex - b.pageIndex)

        // Reduce to object with formElementIds as keys
        .reduce((fieldObject, field) => {
            fieldObject[field.formElementId] = {
                formElementId: field.formElementId,
                value: field.value,
                type: field.type,
                valid: true,
                isValidated: false,
                errors: null,
                required: field.required,
                hidden: field.hidden,
                order: field.order,
                pageIndex: field.pageIndex,
                mappedAs: field.mappedAs,
                options: field.options || null
            };
            return fieldObject;
        }, {});
}


/**
 * Update value of a field in submission.
 *
 * @param {object} fields
 * @param {string} submissionId
 * @param {int} formElementId
 * @param {object} payload
 */
const updateSubmissionFieldValue = (fields, submissionId, formElementId, payload, modifyingExistingSubmission = false) => {

    // Hidden adjustments
    if(typeof payload.hidden !== 'undefined' && payload.hidden === true && !modifyingExistingSubmission) {
        const fieldType = fields[submissionId][formElementId].type;

        if(fieldType === 'checkbox') {
            payload.value = [];
        } else if (fieldType === 'select') {
            payload.value = '';
        } else if (fieldType === 'radio') {
            payload.value = null;
        }
        else {
            payload.value = '';
        }

        if (fields[submissionId][formElementId].options && fields[submissionId][formElementId].options.length) {
            payload.options = fields[submissionId][formElementId].options.map(option => {
                option.selected = false;
                return option;
            });
        }
    }

    // Required adjustments
    if(typeof payload.required !== 'undefined') {
        payload.validation = {
            ...fields[submissionId][formElementId].validation,
            required: payload.required,
            isValidated: false
        };
        payload.isValidated = payload.required ? fields[submissionId][formElementId].isValidated : false;
        payload.errors = payload.required ? fields[submissionId][formElementId].errors : [];
    }

    return {
        ...fields,
        [submissionId]: {
            ...fields[submissionId],
            [formElementId]: {
                ...fields[submissionId][formElementId],
                ...payload,
                autofocus: payload.autofocus || false
            }
        }
    }
};


/**
 * Parse default form elements to an object.
 *
 * @param {object} form
 * @return object
 */
const parseDefaultFormElements = form => {

    const { pages } = form;

    let formElements = {};

    let interestSetsById = {};
    if(form.interestSets) {
        interestSetsById = form.interestSets.reduce((obj, interestSet) => {
            obj[interestSet.interestSetID] = interestSet;
            return obj;
        }, {});
    }

    pages.forEach((page, index) => {
        page.elements.forEach(element => {

            const defaults = {
                required: element.validation.required ? true : false,
                hidden: parseInt(element.hidden) === 1 ? true : false
            };

            const formElement = {
                ...element,
                countryCode: element.countryCode || null,
                name: element.formElementId,
                value: fieldTypeDefaultValue(element.type, element.value),
                dateValue: null,
                options: element.options ? element.options.map(option => {
                    return {
                        ...option,
                        selected: false
                    }
                }): null,
                pageIndex: index,
                disabled: element.disabled || false,
                ...defaults,
                defaults: {
                    ...defaults
                }
            };

            // If element is mapped as 'interests'
            if(formElement.mappedAs === 'interests') {

                let interestSets = ( element.value && interestSetsById[element.value] ) ? [interestSetsById[element.value]] : Object.values(interestSetsById);
                let interestsTree = [];

                for ( let interestSet of interestSets ) {
                    interestSet.interests = interestSet.interests.map(interest => {
                        return {
                            ...interest,
                            formElementOptionId: interest.interestID,
                            value: interest.interestID,
                            selected: false,
                            maxSubmissions: null,
                            products: [],
                            text: form.languages.reduce((texts, language) => {
                                texts[language.languageId] = interest.name;
                                return texts;
                            }, {}),
                            timeDeleted: null,
                            timeModified: null,
                        }
                    }).sort((a,b) => {
                        var textA = a.name.toUpperCase();
                        var textB = b.name.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    });
                    interestsTree = interestsTree.concat(interestsToTree(interestSet.interests));
                }
                // Set interests as options
                formElement.options = interestsTree;
            }

            formElements[formElement.formElementId] = formElement;
        });
    });

    return formElements;
}

/**
 * @name interestsToTree
 * @desc Parse interests to a parent -> children tree
 * @param {array} interests
 */
function interestsToTree(list) {
    _(list).forEach( f => {
        f.items=_(list).filter( g => g.parentInterestID===f.interestID).value();
    });
    var resultArray = _(list).filter(f => f.parentInterestID === null).value();
    return resultArray;
}

/**
 * Return default empty value of a given formElement type.
 *
 * @param {string} fieldType
 * @param {string} value
 */
const fieldTypeDefaultValue = (fieldType, value) => {
    switch(fieldType) {
        case 'file':
        case 'checkbox':
            return [];
        default:
            return (value || '');
    }
}

/**
 * Parse submissions formUploads to object with formElementID as key
 *
 * @param {array} formUploads
 * @return object
 */
const formUploadsByFieldId = formUploads => {
    return formUploads.reduce((obj, item) => {
        obj[item.formElementId] = formUploads.filter(upload => upload.formElementId === item.formElementId);
        return obj;
    }, {});
}


const setFieldQuotas = formPages => {
    let quotas = {};
    formPages.map(page => page.elements.map(formElement => {
        if(formElement.options) {
            formElement.options.map(formElementOption => {
                quotas[formElementOption.formElementOptionId] = {
                    maxSubmissions: formElementOption.maxSubmissions,
                    submissionCount: formElementOption.submissionCount,
                    quotaLeft: (formElementOption.maxSubmissions - formElementOption.submissionCount)
                }
                return formElementOption;
            })
        }
        return page;
    }));
    return quotas;
}

const shouldForceEmailToMainSubmitter = pages => {

    let result = false;

    for( const page of pages) {
        for ( const element of page.elements ) {
            if(element.mappedAs === 'email' && element.duplicateInMultiReg === false) {
                result = true;
                return true;
            }
        }
    }
    return result;
}

const setPresentationsById = (state, submissionId) => {
    if(state.submissionIds.length === 0) {
        return {
            [submissionId]: []
        }
    } else {
        return {
            ...state.presentationsBySubmissionId,
            [submissionId]: []
        }
    }
}

const setOptionsWithProductConditions = form => {

    let ids = {
        formElementOptionId: [],
        formElementId: []
    };

    // Loop through formConditionBundles
    for ( const bundle of form.formConditionBundles ) {
        // Check if bundle's formConditionActions contain "add product to order" conditions
        for ( const action of bundle.formConditionActions ) {
             // If "add product to order" action is found
            if ( action.actionType === 7 ) {
                // Set all expectedId's from bundle's formConditions to ids array.
                for ( const condition of bundle.formConditions ) {
                    // Field
                    if(condition.expectedId) {
                        ids.formElementOptionId.push(condition.expectedId);
                    } else if (condition.condition === 0 && condition.targetId) {
                        ids.formElementId.push(condition.targetId);
                    }
                }
                break;
            }
        }
    }

    return ids;
}