import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Spinner } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';

import {
    setActiveView,
    setPaymentMethod,
    submissionSubmit,
    setCouponCode,
    updateBillingInformationData,
    setBillingInformationValidation,
    fetchInvoiceCustomers,
    setInvoiceCustomer,
    confirmationEmailSendMethodToggle,
    setMultiRegMessagesReceiverEmails,
    setUseManualEmailRecipients,
    payerIsCompanySet
} from './../actions';

import SubmissionFieldsSummary from './../components/SubmissionFieldsSummary/SubmissionFieldsSummary';
import SummaryOrderRows from './../components/SummaryOrderRows/SummaryOrderRows';
import CouponCode from './../components/CouponCode/CouponCode';
import PaymentMethodSelect from './../components/PaymentMethodSelect/PaymentMethodSelect';
import BillingInformationForm from '../components/BillingInformationForm/BillingInformationForm';
import PaytrailPaymentForm from './../components/PaytrailPaymentForm/PaytrailPaymentForm';
import ConfirmationEmailMethod from './../components/ConfirmationEmailMethod/ConfirmationEmailMethod';

import InvoicePayer from './../components/BillingInformationForm/InvoicePayer';

import { validateBillingInformation } from '../utility/validator';
import BusinessDetails from './../components/BusinessDetails/BusinessDetails';

const invoiceFieldMapping = {
    firstNames: 'invoiceFirstNames',
    lastName: 'invoiceLastName',
    company: 'invoiceCompany',
    city: 'invoiceCity',
    invoiceCompany: 'invoiceCompany',
    partnerName: 'invoiceCompany',
    postalCode: 'invoicePostalCode',
    invoicePostalCode: 'invoicePostalCode',
    invoiceCity: 'invoiceCity',
    streetAddress: 'invoiceStreetAddress',
    invoiceStreetAddress: 'invoiceStreetAddress',
    invoiceCompanyBusinessID: 'invoiceCompanyBusinessID',
    eInvoiceIntermediateId: 'eInvoiceIntermediateId',
    eInvoiceAddress: 'eInvoiceAddress',
    email: 'invoiceDeliveryEmail',
    invoiceDeliveryEmail: 'invoiceDeliveryEmail'
};

class SummaryContainer extends React.Component {

    constructor(props) {
        super(props);

        this.handleNextStep = this.handleNextStep.bind(this);
        this.handlePrevStep = this.handlePrevStep.bind(this);
        this.handleCouponCheck = this.handleCouponCheck.bind(this);
        this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
        this.setBillingInformationValidated = this.setBillingInformationValidated.bind(this);
        this._handleInvoicePayerIsCompanyChange = this._handleInvoicePayerIsCompanyChange.bind(this);

        this.state = {
            billingInformationValidated: false,
            invoiceCustomers: [],
            couponCodeRequiredWarning: null
        }

        // Farmasiapäivät 2022 Näyttelyvieras ilmoittautumislomake
        this.couponCodeRequiredFormIDs = ['30e2829f-43e8-11ed-a018-ee6a04371b85'];
    }

    componentDidMount() {
        // Fetch organization's invoiceCustomers, if not already fetched
        const { form, invoiceCustomers, modifyingExistingSubmission } = this.props;
        if(!invoiceCustomers.initialized && !modifyingExistingSubmission ) {
            this.props.onFetchInvoiceCustomers(form.organizationID);
        }
        if(this.props.forceMainSubmitter) {
            const mainEmail = this.props.mainSubmitterEmail();
            this.props.onChangeMultiRegMessagesReceiverEmails(mainEmail, true, false);
        }
        if(!this.props.paymentMethod) {
           this.props.onPaymentMethodChange(this.props.defaultPaymentMethod);
        }
    }

    handleNextStep() {

        const {
            modifyingExistingSubmission,
            order,
            paymentMethod,
            billingInformation,
            authToken,
            oldOrderID,
            orderModifiable,
            languageId,
            t
        } = this.props;

        // Validate billing information if payment method is 'Invoice'
        if(
            (
                (!modifyingExistingSubmission || authToken) &&
                paymentMethod === 1 &&
                (order && order.orderRows && order.orderRows.length  && (order.totalWithTaxRounded !== '0.00' && order.totalWithTaxRounded !== 0.00)) &&
                orderModifiable
            ) ||
                (
                    modifyingExistingSubmission &&
                    paymentMethod === 1 &&
                    order &&
                    order.status === 1
                )
            ) {
            const billingInformationValidation = validateBillingInformation(billingInformation, paymentMethod, languageId, this.props.eventID);
            this.props.onSetBillingInformationValidation(billingInformationValidation);
            this.setState({billingInformationValidated: true});
            if( !billingInformationValidation.valid ) {
                return;
            }
        }

        // Validate BusinessDetails if payment method is 'Paytrail'
        if(!modifyingExistingSubmission && paymentMethod === 2 && billingInformation.invoicePayerIsCompany &&
            (order && order.orderRows && order.orderRows.length  && (order.totalWithTaxRounded !== '0.00' && order.totalWithTaxRounded !== 0.00))) {
            const billingInformationValidation = validateBillingInformation(billingInformation, paymentMethod, languageId, this.props.eventID);
            this.props.onSetBillingInformationValidation(billingInformationValidation);
            this.setState({billingInformationValidated: true});
            if( !billingInformationValidation.valid ) {
                return;
            }
        }

        // CUSTOM: Validate coupon code
        if(!modifyingExistingSubmission && this.couponCodeRequiredFormIDs.includes(this.props.form.formID) && !order.couponFound) {
            this.setState({couponCodeRequiredWarning: t('App.couponCodeRequired')});
            return;
        }



        /**
         * Prompt about updating the related order
         */
        if(
            modifyingExistingSubmission &&
            authToken &&
            (order || (typeof oldOrderID !== 'undefined' && oldOrderID)) &&
            orderModifiable &&
            this.props.paymentMethod === 1
        ) {
            const { t } = this.props;

            confirmAlert({
                title: t('updateRelatedOrder') + '?',
                message: t('submissionContainsOrder'),
                buttons: [
                    {
                        label: t('yesUpdateOrder'),
                        onClick: () => this.props.onSubmit({requestSummary: 0, updateOrder: true})
                    },
                    {
                        label: t('noKeepExistingOrder'),
                        onClick: () => this.props.onSubmit({requestSummary: 0, updateOrder: false})
                    }
                ]
            });
        } else {
            this.props.onSubmit({requestSummary: 0, updateOrder: false});
        }
    }

    handlePrevStep() {
        this.props.onViewChange('form');
    }

    handleCouponCheck() {
        this.props.onCheckCouponCode(this.props.couponCode);
    }


    /**
     * Handle payment method change.
     *
     * @param {int} paymentMethod
     */
    handlePaymentMethodChange(paymentMethod) {
        if(this.props.paymentMethodProducts && Object.keys(this.props.paymentMethodProducts).length) {
            this.props.onSubmit({requestSummary: 1, paymentMethod: parseInt(paymentMethod)});
        } else {
            this.props.onPaymentMethodChange(paymentMethod);
        };
        this.setBillingInformationValidated(false);
    }

    /**
     * Handle invoicePayerIsCompany change.
     *
     * @param {boolean} invoicePayerIsCompany
     */
    _handleInvoicePayerIsCompanyChange(invoicePayerIsCompany) {
        this.props.onPayerIsCompanySet(invoicePayerIsCompany);
        this.setBillingInformationValidated(false);
    }

    setBillingInformationValidated(validated) {
        this.setState({billingInformationValidated: validated});
    }

    render() {

        const {
            t,
            fieldsById,
            isRegistrationForm,
            submitting,
            submissionFields,
            order,
            couponCode,
            paytrailData,
            paytrailRedirecting,
            languageId,
            modifyingExistingSubmission,
            paymentMethod,
            sendSubmissionSuccessMessage,
            submissionReservationExpired,
            billingInformation
        } = this.props;

        const hasOrder = (order && order.orderRows && order.orderRows.length);

        // Gather billing information values from fields of first submission.
        const firstSubmission = Object.values(Object.values(submissionFields).splice(0, 1)[0]);
        const defaultBillingFieldValues = firstSubmission.reduce((defaultBillingInformationFields, field) => {
            if(invoiceFieldMapping[field.mappedAs]) {
                if(field.mappedAs === 'company') {
                    if(field.type === 'text') {
                        defaultBillingInformationFields[invoiceFieldMapping[field.mappedAs]] = field.value;
                    }
                }  else {
                    defaultBillingInformationFields[invoiceFieldMapping[field.mappedAs]] = field.value;
                }
            }
            return defaultBillingInformationFields;
        }, {});

        let confirmButtonText = 'App.confirmSubmission';
        if(hasOrder && !modifyingExistingSubmission && paymentMethod === 2) {
            confirmButtonText = 'App.goToPaytrail';
        } else if (isRegistrationForm && !modifyingExistingSubmission) {
            confirmButtonText = 'App.confirmRegistration';
        } else if (modifyingExistingSubmission) {
            confirmButtonText = 'App.saveChanges';
        }
        if(hasOrder && order.totalWithTaxRounded === '0.00' && !modifyingExistingSubmission) {
            confirmButtonText = 'App.confirmRegistration';
        }

        return (
            <div className="SummaryWrapper">

                <h1 className="PageTitle SummaryPageTitle">
                    { t('Summary') }
                </h1>
                <div className="alert alert-warning" role="alert">
                    {(hasOrder && !this.props.modifyingExistingSubmission) ?
                        t('App.confirmSubmissionMessageWithPayment')
                    :
                        t('App.confirmSubmissionMessage')
                    }
                </div>

                {Object.keys(submissionFields).map(submissionId =>
                    <SubmissionFieldsSummary
                        key={submissionId}
                        submissionFields={submissionFields[submissionId]}
                        formFields={fieldsById}
                        languageId={languageId}
                        interestsById={this.props.interestsById}
                        formUploads={this.props.formUploads}
                    />
                )}

                {(hasOrder) ? (
                    <div>
                        <SummaryOrderRows order={order} />

                        {(!modifyingExistingSubmission) &&
                            <>
                                {this.state.couponCodeRequiredWarning && 
                                    <div className="alert alert-warning" role="alert">
                                        {this.state.couponCodeRequiredWarning}
                                    </div>
                                }
                                
                                <CouponCode
                                    validating={submitting}
                                    code={couponCode}
                                    used={order.couponUsed}
                                    found={order.couponFound}
                                    couponUsageLimitExceeded={order.couponUsageLimitExceeded}
                                    onSubmit={this.handleCouponCheck}
                                    onChange={this.props.onUpdateCouponCode} />
                            </>
                        }
                           

                        {(modifyingExistingSubmission && order && order.couponCodeId) ?
                            <div style={{ marginBottom: '30px' }}>
                                <label>{t('Coupon code')}</label><br />
                                <span>{order.code}</span>
                            </div>
                        : null}

                        {(
                            hasOrder &&
                            order.totalWithTaxRounded &&
                            order.totalWithTaxRounded !== '0.00' &&
                            order.totalWithTaxRounded !== 0.00
                        ) ?
                            <React.Fragment>
                                {!modifyingExistingSubmission ?
                                    <PaymentMethodSelect
                                        paymentMethods={this.props.paymentMethods}
                                        paymentMethodProducts={this.props.paymentMethodProducts}
                                        selectedMethod={this.props.paymentMethod}
                                        onMethodChange={this.handlePaymentMethodChange}
                                        defaultMethod={this.props.defaultPaymentMethod}
                                        eventID={this.props.eventID}
                                        invoicePayerIsCompany={billingInformation.invoicePayerIsCompany}
                                    />
                                : null}
                                {
                                !modifyingExistingSubmission ||
                                (modifyingExistingSubmission && this.props.showBillingInformationForm()) ?
                                    <InvoicePayer
                                        onChange={this._handleInvoicePayerIsCompanyChange}
                                        t={t}
                                        invoicePayerIsCompany={billingInformation.invoicePayerIsCompany}
                                        eventID={this.props.eventID}
                                        formID={this.props.form.formID}
                                    />
                                : null}
                                {(!modifyingExistingSubmission && this.props.paymentMethod === 2 && billingInformation.invoicePayerIsCompany) ?
                                    <BusinessDetails
                                        defaultBillingFieldValues={defaultBillingFieldValues}
                                        eventID={this.props.eventID}
                                    />
                                : null}
                            </React.Fragment>
                        : null}
                    </div>
                ) : null}

                {this.props.showBillingInformationForm() ?
                <React.Fragment>
                     <BillingInformationForm
                        isValidated={this.state.billingInformationValidated}
                        defaultBillingFieldValues={defaultBillingFieldValues}
                        billingInformation={billingInformation}
                        setBillingInformationValidated={this.setBillingInformationValidated}
                        submissionFields={submissionFields}
                        modifyingExistingSubmission={this.props.modifyingExistingSubmission}
                        order={order}
                        oldOrderID={this.props.oldOrderID}
                        invoiceCustomers={this.props.invoiceCustomers}
                        languageId={languageId}
                        eventID={this.props.eventID}
                    />
                </React.Fragment>

                : null}

                {paytrailData && paytrailRedirecting ? (
                    <div>
                        <Spinner color="primary" />
                        <PaytrailPaymentForm paytrailData={paytrailData} />
                    </div>
                ) : null}

                {!modifyingExistingSubmission && sendSubmissionSuccessMessage && Object.keys(submissionFields).length > 1 ?
                    <ConfirmationEmailMethod
                        useManualConfirmationRecipients={this.props.useManualConfirmationRecipients}
                        setUseManualEmailRecipients={this.props.onSetUseManualEmailRecipients}
                        multiRegMessagesReceiverEmails={this.props.multiRegMessagesReceiverEmails}
                        onChange={this.props.onChangeMultiRegMessagesReceiverEmails}
                        messagesSentOnlyToMainSubmitter={this.props.messagesSentOnlyToMainSubmitter}
                        forceMainSubmitter={this.props.forceMainSubmitter}
                        primaryEmail={this.props.mainSubmitterEmail()}
                    />
                : null}


                <div className="NavigationControls">
                    <Button
                        color="secondary"
                        className="BackButton"
                        onClick={this.handlePrevStep}
                        disabled={paytrailRedirecting || submitting}
                    >
                        {t('App.return')}
                    </Button>

                    <Button
                        color="primary"
                        className="ForwardButton"
                        onClick={this.handleNextStep}
                        disabled={paytrailRedirecting || submitting || submissionReservationExpired}
                    >
                        {t(confirmButtonText)}
                    </Button>
                </div>

            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        eventID: state.form.form.eventID,
        sendSubmissionSuccessMessage: state.form.form.sendSubmissionSuccessMessage,
        messagesSentOnlyToMainSubmitter: state.submission.messagesSentOnlyToMainSubmitter,
        languageId: state.language.languageId,
        submissionFields: state.submission.fields,
        submissionResponse: state.submission.submissionResponse,
        order: state.submission.order,
        fieldsById: state.form.fields.byId,
        submitting: state.submission.submitting,
        paymentMethods: state.payment.paymentMethods.byId,
        paymentMethod: state.payment.paymentMethods.selected,
        defaultPaymentMethod: state.payment.paymentMethods.default,
        paymentMethodProducts: state.payment.paymentMethodProducts,
        paytrailData: state.payment.paytrail.formData,
        paytrailRedirecting: state.payment.paytrail.redirecting,
        couponCode: state.submission.couponCode,
        validating: state.submission.validating,
        billingInformation: state.payment.billingInformation,
        isRegistrationForm: state.form.form.isRegistrationForm,
        modifyingExistingSubmission: state.submission.modifyingExistingSubmission,
        invoiceCustomers: state.payment.invoiceCustomers,
        multiRegMessagesReceiverEmails: state.submission.multiRegMessagesReceiverEmails,
        forceMainSubmitter: state.submission.forceMainSubmitter,
        useManualConfirmationRecipients: state.submission.useManualConfirmationRecipients,
        eInvoice: state.payment.eInvoice,
        interestsById: state.form.interests.byId,
        authToken: state.submission.authToken,
        bundlePersonNumber: state.submission.bundlePersonNumber,
        formUploads: state.submission.formUploads,
        oldOrderID: state.submission.oldOrderID,
        orderModifiable: state.submission.orderModifiable,
        mainSubmitterEmail: () => {
            if(state.submission.submissionResponse && Array.isArray(state.submission.submissionResponse) && state.submission.submissionResponse[0].values) {
                for (const field of state.submission.submissionResponse[0].values) {
                    if(field.mappedAs === 'email') {
                        return field.value;
                    }
                }
            }
            return '';
        },
        showBillingInformationForm: () => {

            let {
                modifyingExistingSubmission,
                authToken,
                order,
                bundlePersonNumber,
                orderModifiable,
            } = state.submission;
            let { form } = state.form;
            let paymentMethod = state.payment.paymentMethods.selected;

            // If payment method is not invoice
            if(paymentMethod !== 1) {
                return false;
            }

            // If there is no order
            if(!order) {
                return false;
            } else {
                let { status, orderRows, totalWithTaxRounded } = order;
                // Not waiting for payment, not any products
                if(status !== 1 || !orderRows.length) {
                    return false;
                }
                // Not anything to pay for
                if(totalWithTaxRounded === '0.00' || totalWithTaxRounded === 0.00) {
                    return false;
                }
                // Order modified by admin (in Eventos)
                if(!orderModifiable) {
                    return false;
                }
            }

            // Modifying existing submission without authToken,
            // when form generates invoices automatically or has invoiceCustomer
            if(
                modifyingExistingSubmission && !authToken &&
                (form.generateInvoice || order.invoiceCustomer)
            ) {
                return false;
            }

            // If bundle but not first person of the bundle
            if(bundlePersonNumber && bundlePersonNumber !== 1) {
                return false;
            }

            return true;
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onViewChange: (view) => {
            return dispatch(setActiveView(view));
        },
        onPaymentMethodChange: method => {
            return dispatch(setPaymentMethod(method));
        },
        onSubmit: (payload) => {
            return dispatch(submissionSubmit(payload));
        },
        onCheckCouponCode: couponCode => {
            dispatch(submissionSubmit({requestSummary: 1, couponCode: couponCode}));
        },
        onUpdateCouponCode: couponCode => {
            dispatch(setCouponCode(couponCode))
        },
        onUpdateBillingInformation: (name, value, validation) => {
            return dispatch(updateBillingInformationData(name, value, validation));
        },
        onSetBillingInformationValidation: (validation) => {
            return dispatch(setBillingInformationValidation(validation))
        },
        onFetchInvoiceCustomers: (organizationID) => {
            return dispatch(fetchInvoiceCustomers(organizationID))
        },
        onInvoiceCustomerSelect: invoiceCustomer => {
            return dispatch(setInvoiceCustomer(invoiceCustomer))
        },
        onConfirmationEmailMethodToggle: () => {
            return dispatch(confirmationEmailSendMethodToggle());
        },
        onSetUseManualEmailRecipients: (value) => {
            return dispatch(setUseManualEmailRecipients(value));
        },
        onChangeMultiRegMessagesReceiverEmails: (email, valid, isValidated) => {
            return dispatch(setMultiRegMessagesReceiverEmails(email, valid, isValidated));
        },
        onPayerIsCompanySet: invoicePayerIsCompany => {
            return dispatch(payerIsCompanySet(invoicePayerIsCompany));
        },
    }
}

export default withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(SummaryContainer));