import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

class Step extends React.Component {

    render() {

        const { title, stepWidthStyle, active, done, last, pageNum } = this.props;

        const StepClasses = classNames('step', {
            'Step': true,
            'active': active,
            'done': done,
            'last': last
        });

        return (
            <li className={StepClasses} style={{...stepWidthStyle, paddingBottom: '8px'}}>
                <span className="d-block d-sm-none">{pageNum}</span>
                <span className="d-none d-sm-block">{title}</span>
            </li>
        )
    }
}

Step.propTypes = {
    title: PropTypes.string,
    stepStyle: PropTypes.object,
    active: PropTypes.bool,
    done: PropTypes.bool,
    last: PropTypes.bool
}

export default Step;