import { 
    SET_LANGUAGES,
    LANGUAGE_CHANGE    
 } from './ActionTypes';

export const setLanguages = (languages) => {
    return {
        type: SET_LANGUAGES,
        languages: languages
    }
};

export const languageChange = (languageId) => {
    return {
        type: LANGUAGE_CHANGE,
        languageId: languageId
    }
};


