import React, { useEffect, useRef, memo } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import classNames from 'classnames';
import DatePicker from "react-datepicker";
import moment from 'moment-timezone';
import countries from '../../utility/countryCodes';
import FieldLabel from './FieldLabel/FieldLabel';
import FieldDescription from './FieldDescription/FieldDescription';
import FieldErrors from './FieldErrors/FieldErrors';
import FileField from './FileField/FileField';
import IconRatingField from './IconRatingField/IconRatingField';
import validator from 'validator'

import "react-datepicker/dist/react-datepicker.css";

function Field(props) {

    const focusRef = useRef(null);
    const { submissionId, formElement, languageId, inline, allowedFileExtensions } = props;
    const { name, value, dateValue, options, valid, isValidated, errors, type, disabled, mappedAs, autofocus} = formElement;
    const label = formElement.label[languageId];
    const placeholder = formElement.placeholder[languageId];
    const required = typeof formElement.required !== 'undefined' ? formElement.required : formElement.validation.required;

    const formGroupClasses = classNames('formElement-'+formElement.formElementId, {
        'form-group': !inline,
        'required': required,
        'FormGroup': true,
        ['element-type-'+type]: true,
        'hidden': formElement.hidden,
        'form-group row': inline,
        [formElement.class]: !inline
    });

    useEffect(() => {
        if(focusRef.current && autofocus) {
            focusRef.current.focus();
        }
    });

    function isChecked(formElementOptionId, value){
        if(value) {
            if(Array.isArray(value)) {
                return value.length > 0 && value.indexOf(formElementOptionId) !== -1;
            } else {
                return ""+value === ""+formElementOptionId;
            }
        }
        return false;
    }

    function handleInputChange(event) {
        const { submissionId, formElement } = props;
        let value = event.target.value;
        if(formElement.type === 'checkbox' && formElement.mappedAs !== 'interests') {
            value = parseInt(value);
        }
        props.handleInputChange(submissionId, formElement.formElementId, value);
    }

    function handleDateChange(date) {
        const { submissionId, formElement } = props;
        let formattedDateValue = '';
        if (moment(date).isValid()) {
            formattedDateValue = moment(date).clone().format('D.M.Y');
        }
        props.handleInputChange(submissionId, formElement.formElementId, formattedDateValue, date);
    }

    function handleIconRatingChange(formElementId, value) {
        const { submissionId } = props;
        props.handleInputChange(submissionId, formElementId, value);
    }

    function preventNonNumericalInput(e) {
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);
        if(!validator.isNumeric(charStr)) {
            e.preventDefault();
            return;
        }
    }

    function renderChildInterests (formElementOptions, formElement) {

        const { submissionId, languageId } = props;

        return (
            (formElementOptions && formElementOptions.length) ?
                <ul>
                    {formElementOptions.map(formElementOption => (
                        <li key={formElementOption.interestID}>
                            <FormGroup check key={formElementOption.formElementOptionId}>
                                <Label check>
                                    <Input
                                        type='checkbox'
                                        name={submissionId + '_' + formElement.name}
                                        value={formElementOption.formElementOptionId}
                                        defaultChecked={isChecked(formElementOption.formElementOptionId, formElement.value)}
                                        onChange={handleInputChange}
                                        innerRef={focusRef}
                                    />{' '}
                                    {formElementOption.text[languageId]}
                                </Label>
                            </FormGroup>
                            {renderChildInterests(formElementOption.items, formElement)}
                        </li>
                    ))}
                </ul>
            : null
        )
    }

    function FieldElement() {
        switch (type) {
            case 'checkbox':
            return (
                <div>
                    {mappedAs === 'interests' ?
                        <ul className="interests">
                            {options.map(formElementOption => (
                                <li key={formElementOption.interestID}>
                                    <FormGroup check key={formElementOption.formElementOptionId} disabled={disabled}>
                                        <Label check className={formElementOption.disabled ? 'disabled' : 'enabled'}>
                                            <Input
                                                type='checkbox'
                                                name={submissionId + '_' + name}
                                                value={formElementOption.formElementOptionId}
                                                checked={isChecked(formElementOption.formElementOptionId, formElement.value)}
                                                onChange={handleInputChange}
                                                disabled={disabled}
                                                innerRef={focusRef}
                                            />{' '}
                                            {formElementOption.text[languageId]}
                                        </Label>
                                    </FormGroup>
                                    {renderChildInterests(formElementOption.items, formElement)}
                                </li>
                            ))}
                        </ul>
                    :
                        options.map(formElementOption => (
                        <FormGroup check key={formElementOption.formElementOptionId} disabled={disabled}>
                            <Label check className={formElementOption.disabled ? 'disabled' : 'enabled'}>
                                <Input
                                    type='checkbox'
                                    name={submissionId + '_' + name}
                                    value={formElementOption.formElementOptionId}
                                    checked={isChecked(formElementOption.formElementOptionId, formElement.value)}
                                    onChange={handleInputChange}
                                    disabled={formElementOption.disabled}
                                    innerRef={focusRef}
                                />{' '}
                                {formElementOption.text[languageId]}
                            </Label>
                        </FormGroup>
                    ))}
                </div>
            )
            case 'radio':
            case 'scalerating':
            case 'nps':
                return (
                    <div>

                         {((type === 'scalerating' || type === 'nps') && formElement.scaleLabelMin[languageId] && formElement.scaleLabelMax[languageId]) ? (
                            <p className="scalelabel-labels">
                                <span className="scalelabel scalelabelMin">1 = {formElement.scaleLabelMin[languageId]},</span>
                                <span className="scalelabel scalelabelMax">{options.length} = {formElement.scaleLabelMax[languageId]}</span>
                            </p>
                        ): null}

                        {options.map(formElementOption => (
                            <FormGroup check key={formElementOption.formElementOptionId} inline={type==='scalerating' || type==='nps'} disabled={formElementOption.disabled || formElement.disabled} >
                                <Label check className={formElementOption.disabled ? 'disabled' : 'enabled'}>
                                    <Input
                                        type='radio'
                                        name={submissionId + '_' + name}
                                        value={formElementOption.formElementOptionId}
                                        checked={isChecked(formElementOption.formElementOptionId, formElement.value)}
                                        onChange={handleInputChange}
                                        disabled={formElementOption.disabled || formElement.disabled}
                                        innerRef={focusRef}
                                    />{' '}
                                    {formElementOption.text[languageId]}
                                </Label>
                            </FormGroup>
                        ))}
                    </div>
                );
            case 'select':
                let countryDefaultValue = (value !== null || value !== '') ? value : null;
                if(!countryDefaultValue) {
                    if(languageId === 1) {
                        countryDefaultValue = 'FI';
                    }
                }
                return (
                    <Input
                        type="select"
                        name={name}
                        onChange={handleInputChange}
                        required={required}
                        value={(formElement.mappedAs === 'countryCode' && formElement.countryCode) ? formElement.countryCode : value}
                        valid={valid && isValidated}
                        invalid={!valid && isValidated}
                        innerRef={focusRef}
                        disabled={disabled}
                        translate="no"
                    >
                        <option value="">
                            {placeholder}
                        </option>
                        {formElement.mappedAs === 'countryCode' ?
                            Object.values(countries)
                                .sort((a, b) => a.name > b.name ? 1 : -1)
                                .map(formElementOption =>
                                    <option value={formElementOption.cca2} key={formElementOption.cca2} >
                                        {formElementOption.name}
                                    </option>
                                )
                        :
                            formElement.options.map(formElementOption =>
                                <option value={formElementOption.formElementOptionId} key={formElementOption.formElementOptionId} disabled={formElementOption.disabled} >
                                    {formElementOption.text[languageId]}
                                </option>
                            )
                        }
                    </Input>
                );

            case 'datepicker':
                return (
                    <DatePicker
                        customInput={<Input type="text" name={name} valid={valid && isValidated} value={value} invalid={!valid && isValidated} innerRef={focusRef}></Input>}
                        selected={typeof dateValue === 'object' ? dateValue : null}
                        name={''+name}
                        onChange={handleDateChange}
                        className="form-control"
                        placeholderText={formElement.placeholder[languageId]}
                        dateFormat="d.M.yyyy"
                        required={required}
                        valid={valid && isValidated}
                        invalid={!valid && isValidated}
                        disabled={disabled}
                    />
                );
            case 'iconrating':
                return (
                    <IconRatingField
                        selected={formElement.value}
                        element={formElement}
                        onClick={handleIconRatingChange}
                        disabled={disabled}
                    />
                );
            case 'file':
                return (
                    formElement ? (
                        <FileField
                            formElement={formElement}
                            submissionId={submissionId}
                            handleInputChange={props.handleInputChange}
                            uploads={props.uploads}
                            disabled={disabled}
                            allowedFileExtensions={allowedFileExtensions}
                        />
                    ) : null
                );
            case 'number':
                return (
                    formElement ? (
                        <Input
                            type={type}
                            name={name}
                            onKeyPress={preventNonNumericalInput}
                            onChange={handleInputChange}
                            value={value || ''}
                            valid={valid && isValidated}
                            invalid={!valid && isValidated}
                            autoFocus={formElement.autofocus}
                            innerRef={focusRef}
                            disabled={disabled}
                            placeholder={placeholder}
                            min={formElement.validation.min}
                            max={formElement.validation.max}
                        />
                    ) : null
                );
            default:
                return (
                    formElement ? (
                        <Input
                            type={type}
                            name={name}
                            onBlur={handleInputChange}
                            defaultValue={value}
                            valid={valid && isValidated}
                            invalid={!valid && isValidated}
                            autoFocus={formElement.autofocus}
                            innerRef={focusRef}
                            disabled={disabled}
                            placeholder={placeholder}
                            required={required}
                        />
                    ) : null
                );
        }
    }



    return (
        <div className={formGroupClasses}>

            <FieldLabel text={label} inline={inline} />

            {inline ?
                <div className="col-sm-9">
                    <div className="row">
                        <div className={formElement.class}>
                            {FieldElement()}
                        </div>
                    </div>
                    <FieldErrors errors={errors} formElementId={formElement.formElementId} />
                </div>
            :
                <div>
                    {FieldElement()}
                    <FieldErrors errors={errors} formElementId={formElement.formElementId} />
                </div>
            }

            <FieldDescription languageId={languageId} description={formElement.description} />

        </div>
    )

}

function preventRender(prevProps, nextProps) {
    const compareProperties = ['value', 'hidden', 'required', 'valid', 'isValidated', 'errors', 'disabled', 'options'];
    // Render if language is changed
    if(prevProps.languageId !== nextProps.languageId) {
        return false;
    }
    if(nextProps.formElement.autoFocus) {
        return false;
    }
    for(const prop of compareProperties) {
        if(prevProps.formElement[prop] !== nextProps.formElement[prop]) {
            return false;
        }
    }
    if(nextProps.formElement.type === 'file' && prevProps.allowedFileExtensions !== nextProps.allowedFileExtensions) {
        return false;
    }
    return true;
}

export default memo(Field, preventRender);