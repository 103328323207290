import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';

const ParticipantCount = ({ t, visible, count = 0, max }) => (
    visible ?
        <div id="ParticipantCount">
            {t('event.signedUpForEvent')}: {count} {max && ('/ ' + max) }
        </div>
    : null
);

ParticipantCount.propTypes = {
    visible: PropTypes.bool,
    count: PropTypes.number,
    max: PropTypes.number
};

export default withTranslation()(ParticipantCount);