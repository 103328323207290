import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { IoMdAddCircle } from 'react-icons/io';

import { hideField, showField, checkFormElementConditions, setInputValue, addSubmissionInstance, removeSubmissionInstance, checkEmailAlreadyRegistered, navigateNextFormPage, navigatePrevFormPage, validatePhoneNumber, reservationSave, presentationAdd, presentationRemove } from '../actions';

import FormPageTitle from './../components/FormPageTitle/FormPageTitle';
import SubmissionFields from '../components/SubmissionFields/SubmissionFields';
import Field from './../components/Field/Field';

import { toggleArrayValue } from './../utility/helpers';
import { validateField } from './../utility/validator';


class FormContainer extends Component {

    constructor(props) {
        super(props);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleNextStep = this.handleNextStep.bind(this);
        this.handlePrevStep = this.handlePrevStep.bind(this);
        this.handleSubmissionInstanceMount = this.handleSubmissionInstanceMount.bind(this);
        this.handleAddPerson = this.handleAddPerson.bind(this);
        this.handleRemovePerson = this.handleRemovePerson.bind(this);
        this.handleOptionPresentations = this.handleOptionPresentations.bind(this);
    }

    componentDidUpdate(prevProps) {
        const { pageIds }  = this.props;
        const activePageIndex = pageIds.indexOf(this.props.navigation.formPages.active);
        if(prevProps.navigation.formPages.active !== this.props.navigation.formPages.active && activePageIndex > 0) {
            this.checkConditions();
        }
    }


    /**
     * Handle input change event.
     *
     * @param {string} submissionID submissionID which the field is related to
     * @param {int} formElementId formElementId of the field
     * @param {string} value new value
     * @param {string} dateValue new dateValue (if datepicker)
     */
    handleInputChange(submissionId, formElementId, value, dateValue = null) {

        const { fieldsById, submissionFields } = this.props;
        const originalField = fieldsById[formElementId];
        const submissionField = submissionFields[submissionId][formElementId];
        const originalValue = submissionFields[submissionId][formElementId].value;

        if( originalValue === value ) {
            return;
        }

        // Transform the new value to an array if the element type is checkbox
        let newValue = value;
        if( originalField.type === 'checkbox' ) {
            newValue = toggleArrayValue(value, [...submissionField.value]);
        }

        // This payload will be passed to the action creator
        let payload = {
            value: newValue,
            dateValue: dateValue,
            autofocus: false,
            usedEmails: []
        };
        if(originalField.mappedAs === 'countryCode') {
            payload.countryCode = newValue;
        }

        /**
         * //Validate only on input change if the field has already been validated once.
         * //This is to prevent excessive amount of validations and UI changes for user.
         * Add validations to payload object.
         */
        if( originalField.mappedAs === 'email' ) {
            if( newValue && newValue !== null && newValue !== "" ) {
                // Check if email is already used for another person
                if(this.props.submissionIds.length > 1) {
                    let usedEmails = [];
                    for ( const [id, fields] of Object.entries(submissionFields)) {
                        if(id === submissionId) {
                            continue;
                        }
                        Object.values(fields).filter(field => field.mappedAs === 'email').forEach(field => {
                            usedEmails.push(field.value);
                        });
                    }
                    payload.usedEmails = usedEmails;
                }
                if(payload.usedEmails.indexOf(newValue) === -1) {
                    this.props.onCheckEmailRegistered(submissionId, formElementId, newValue);
                }
            }
            
        } if ( originalField.mappedAs === 'phone' ){
            this.props.onValidatePhoneNumber(submissionId, formElementId, newValue);
        } else {
            const validation = validateField({...originalField, ...submissionField, ...payload});
            payload = {...payload, ...validation};
        }

        // Set the submission value (with validations if they've added above)
        this.props.onInputChange(submissionId, formElementId, {...payload});

        // If field has presentations binded, add/remove them
        if(originalField.type === 'checkbox' || originalField.type === 'radio' || originalField.type === 'select') {
            this.handleOptionPresentations(submissionId, originalField, payload.value);
        }

    }

    handleOptionPresentations(submissionId, originalField, value) {
        let presentationsByOptionId = originalField.options.reduce((options, option) => {
            options[option.formElementOptionId] = option.presentations.reduce((presentations, presentation) => {
                presentations.push(presentation.presentationID);
                return presentations;
            }, []);
            return options;
        }, {});

        let optionIds = Object.keys(presentationsByOptionId);

        if(optionIds.length) {
            for( const formElementOptionId of optionIds ) {
                let presentationIDs = presentationsByOptionId[formElementOptionId];
                // Add presentations
                if(parseInt(value) === parseInt(formElementOptionId) || (Array.isArray(value) && value.indexOf(parseInt(formElementOptionId)) > -1)) {
                    presentationIDs.forEach(presentationID => {
                        this.props.onPresentationAdd(submissionId, presentationID);
                    })
                }
                // Remove presentations
                else {
                    presentationIDs.forEach(presentationID => {
                        this.props.onPresentationRemove(submissionId, presentationID);
                    })
                }
            }
        }
    }


    handleNextStep() {
        const { navigation, form, modifyingExistingSubmission, authToken } = this.props;
        if( !authToken && navigation.formPages.isLast && !form.allowSubmissionUpdate && modifyingExistingSubmission ) {
            return;
        }
        this.props.onNextPage(navigation.formPages.active);
    }

    handlePrevStep() {
        const { navigation } = this.props;
        this.props.onPrevPage(navigation.formPages.active);
    }

    handleSubmissionInstanceMount(submissionId) {
        this.checkConditions(submissionId)
    }

    handleAddPerson() {
        if(this.props.form.reservationsEnabled) {
            let payload = {};
            if(this.props.submissionReservation) {
                payload = {
                    ...this.props.submissionReservation,
                    personCount: this.props.submissionReservation.personCount+1
                }
            }
            this.props.onSaveReservation(payload);
        } else {
            this.props.onAddSubmission();
        }
    }

    handleRemovePerson(submissionId)  {
        if(this.props.form.reservationsEnabled) {
            let payload = {
                ...this.props.submissionReservation,
                personCount: this.props.submissionReservation.personCount-1
            }
            this.props.onSaveReservation(payload);
        }
        this.props.onRemoveSubmission(submissionId);
    }

    checkConditions(submissionId) {
        const { pageIds,conditionsByTarget }  = this.props;
        const activePageIndex = pageIds.indexOf(this.props.navigation.formPages.active);
        for( let submissionId of this.props.submissionIds) {
            const submissionValues = this.props.submissionFields[submissionId];
            const pageSubmissionFields = Object.values(submissionValues).filter(formElement => {
                return formElement.pageIndex === activePageIndex;
            }).filter(formElement => {
                return typeof conditionsByTarget[formElement.formElementId] !== 'undefined';
            });

            for( let formElement of pageSubmissionFields ) {
                let payload = {...submissionValues[formElement.formElementId]};
                this.props.onCheckFormElementConditions(submissionId, formElement.formElementId, {...payload});
            }
        }
    }

    render() {

        const {
            t,
            fieldsByPageId,
            form,
            languageId,
            modifyingExistingSubmission,
            navigation,
            pagesById,
            submissionIds,
            submissionFields,
            formStatus,
            bundlePersonNumber,
            submissionReservationExpired,
            order,
            allowedFileExtensions
        } = this.props;

        const hasOrder = (order && order.orderRows && order.orderRows.length);

        const activePageId = navigation.formPages.active;

        const showAddInstanceButton = (
            (form.allowMultipleRegistrations && !modifyingExistingSubmission && navigation.formPages.isFirst) && (formStatus.placesLeft === null || formStatus.placesLeft > submissionIds.length)
            && (!form.multiregParticipantLimit || form.multiregParticipantLimit > submissionIds.length));

        return (
            <div className="FormContainer text-left">

                    <form encType="multipart/form-data">

                        <FormPageTitle title={pagesById[activePageId].title[languageId]} />

                        {submissionIds.map((submissionId, submissionIndex) =>
                            <SubmissionFields
                                key={submissionId}
                                submissionId={submissionId}
                                submissionIndex={submissionIndex}
                                onRemove={this.handleRemovePerson}
                                onMount={this.handleSubmissionInstanceMount}
                                showLegend={form.allowMultipleRegistrations && !modifyingExistingSubmission}
                                inline={this.props.inline}
                                >
                                {fieldsByPageId[activePageId].filter(field => {
                                        return (!modifyingExistingSubmission && submissionIndex === 0)
                                            || (submissionIndex > 0 && field.duplicateInMultiReg !== false)
                                            || (bundlePersonNumber && bundlePersonNumber > 1 && field.duplicateInMultiReg !== false)
                                            || (modifyingExistingSubmission && (!bundlePersonNumber || bundlePersonNumber === 1));

                                }).map(field => {
                                    const hiddenClass = submissionFields[submissionId][field.formElementId].hidden ? 'hidden' : '';
                                    switch(field.type) {
                                        case 'heading':
                                            return (
                                                <div className={"col-md-12 element-type-paragraph " + hiddenClass} key={field.formElementId}>
                                                    <div className={this.props.inline ? 'row': ''}>
                                                        <h3 className="field-heading" dangerouslySetInnerHTML={{__html: field.label[languageId]}} />
                                                    </div>
                                                </div>
                                            )
                                        case 'paragraph':
                                            return (
                                                <div className={"col-md-12 element-type-paragraph " + hiddenClass} key={field.formElementId}>
                                                    <div className={this.props.inline ? 'row': ''} style={{display: 'block'}}  dangerouslySetInnerHTML={{__html: field.label[languageId]}} />
                                                </div>
                                            );
                                        case 'divider':
                                            return (
                                                <div className={"col-md-12 element-type-divider " + hiddenClass} key={field.formElementId}>
                                                    <hr className={this.props.inline ? 'row': ''} />
                                                </div>
                                            );
                                        default:
                                            return (
                                                <Field
                                                    formElement={{...field, ...submissionFields[submissionId][field.formElementId]}}
                                                    languageId={languageId}
                                                    key={field.formElementId}
                                                    handleInputChange={this.handleInputChange}
                                                    submissionId={submissionId}
                                                    uploads={this.props.formUploads.byFieldId[field.formElementId]}
                                                    inline={this.props.inline}
                                                    timeZone={this.props.timeZone}
                                                    allowedFileExtensions={allowedFileExtensions}
                                                />
                                            )
                                        }
                                    }
                                )}
                            </SubmissionFields>
                        )}

                        {showAddInstanceButton ?
                            <React.Fragment>
                                {this.props.reservationsFull ?
                                    <p className="text-danger">{t("SubmissionReservation.participantsFull")}</p>
                                : null }
                                <button
                                    type="button"
                                    className="btn btn-light btn-sm AddInstanceButton"
                                    onClick={this.handleAddPerson}
                                    disabled={submissionReservationExpired}
                                >
                                    <IoMdAddCircle />
                                    {t('App.addInstance')}
                                </button>
                            </React.Fragment>
                        : null}

                        <NavigationControls
                            t={t}
                            navigation={navigation}
                            form={form}
                            handleNextStep={this.handleNextStep}
                            handlePrevStep={this.handlePrevStep}
                            disabled={(this.props.isLoading || submissionReservationExpired)}
                            modifyingExistingSubmission={modifyingExistingSubmission}
                            hasOrder={hasOrder}
                            authToken={this.props.authToken}
                        />
                    </form>

                    {Object.keys(this.props.validationErrors).length > 0 ?
                        <p className="text-danger pull-right" style={{marginTop: '15px', fontSize: '14px'}}>
                        {t('App.checkFormForErrors')}.</p>
                    : null}

            </div>
        );
    }
};

const NavigationControls = ({ t, navigation, handleNextStep, handlePrevStep, form, disabled, modifyingExistingSubmission, hasOrder, authToken }) => {

    let nextButtonText = 'App.nextPage';

    if ( navigation.formPages.isLast ) {
        if( !modifyingExistingSubmission ) {
            if((form.showSummaryPage || hasOrder || form.products.length > 0)) {
                nextButtonText = 'App.previewRegistration';
            } else {
                nextButtonText = 'App.confirmSubmission';
            }
        } else {
            if((form.showSummaryPage || hasOrder || form.products.length > 0)) {
                nextButtonText = 'App.previewRegistration';
            } else {
                nextButtonText = 'App.saveChanges';
            }
        }
    }


    return (
        <div className="NavigationControls">

            {navigation.formPages.count > 1 && !navigation.formPages.isFirst ?
                <Button color="secondary" className="BackButton" onClick={handlePrevStep} disabled={navigation.formPages.isFirst || disabled}>
                    {t('App.return')}
                </Button>
            : null}

            <Button
                color="primary"
                className="ForwardButton"
                onClick={handleNextStep}
                disabled={(!authToken && navigation.formPages.isLast && modifyingExistingSubmission && !form.allowSubmissionUpdate) || disabled}
            >
                {t(nextButtonText)}
            </Button>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        languageId: state.language.languageId,
        bundlePersonNumber: state.submission.bundlePersonNumber,
        form: state.form.form,
        inline: state.form.inline,
        formStatus: state.form.formStatus,
        pagesById: state.form.pages.byId,
        pageIds: state.form.pages.allIds,
        fieldsByPageId: state.form.fields.byPageId,
        fieldsById: state.form.fields.byId,
        isLoading: state.app.loading.status,
        navigation: state.navigation,
        submissionIds: state.submission.submissionIds,
        submissionFields: state.submission.fields,
        modifyingExistingSubmission: state.submission.modifyingExistingSubmission,
        formUploads: state.submission.formUploads,
        validationErrors: state.submission.validationErrors,
        submissionReservation: state.submissionReservation.data,
        reservationsFull: state.submissionReservation.isFull,
        timeZone: state.submission.timeZone,
        conditionsByTarget: state.conditions.bundles.byTarget,
        order: state.submission.order,
        allowedFileExtensions: state.form.allowedFileExtensions,
        authToken: state.submission.authToken
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onHideField: (submissionId, formElementId) => {
            return dispatch(hideField(submissionId, formElementId));
        },
        onShowField: (submissionId, formElementId) => {
            return dispatch(showField(submissionId, formElementId));
        },
        onInputChange: (submissionId, formElementId, payload, shouldValidate = true) => {
            return dispatch(setInputValue(submissionId, formElementId, payload));
        },
        onAddSubmission: () => {
            return dispatch(addSubmissionInstance());
        },
        onRemoveSubmission: (submissionId) => {
            return dispatch(removeSubmissionInstance(submissionId));
        },
        onNextPage: (currentPageId) => {
			return dispatch(navigateNextFormPage(currentPageId))
		},
		onPrevPage: (currentPageId) => {
			return dispatch(navigatePrevFormPage(currentPageId))
        },
        onCheckEmailRegistered: (submissionId, formElementId, value) => {
            return dispatch(checkEmailAlreadyRegistered(submissionId, formElementId, value))
        },
        onValidatePhoneNumber: (submissionId, formElementId, value) => {
            return dispatch(validatePhoneNumber(submissionId, formElementId, value))
        },
        onSaveReservation: (payload) => {
            return dispatch(reservationSave(payload));
        },
        onCheckFormElementConditions: (submissionId, formElementId, payload) => {
            return dispatch(checkFormElementConditions(submissionId, formElementId, payload));
        },
        onPresentationAdd: (submissionId, presentationID) => {
            return dispatch(presentationAdd(submissionId, presentationID));
        },
        onPresentationRemove:  (submissionId, presentationID) => {
            return dispatch(presentationRemove(submissionId, presentationID));
        }
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FormContainer));