import React from 'react';
import { withTranslation } from 'react-i18next';

const FieldErrors = ({ t, errors, formElementId }) => (

    (errors && errors.length > 0) ?

        <ul className="field-errors-list text-danger" key={formElementId}>
            {errors.map((error, idx) => (
                <li className="text-danger" key={idx}>
                    {t(error.message)}
                </li>
            ))}
        </ul>

    : null
)

export default withTranslation('translation')(FieldErrors);