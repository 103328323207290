import {
    SUBMISSION_FETCH,
    SUBMISSION_FETCH_SUCCESS,
    SUBMISSION_FETCH_FAIL,
    SUBMISSION_SUBMIT,
    ADD_SUBMISSION_INSTANCE,
    INPUT_CHANGE,
    SET_SUBMISSION_VALUE,
    FIELD_SET_VALIDATION,
    FIELD_SET_REQUIREMENT,
    REMOVE_SUBMISSION_INSTANCE,
    SUBMISSION_SUBMIT_SUCCESS,
    SUBMISSION_SUBMIT_FAIL,
    COUPON_CODE_UPDATE,
    VALIDATE_PHONE_NUMBER,
    SUBMISSION_VALIDATE,
    SUBMISSION_SET_VALIDATION_ERRORS,
    CONFIRMATION_EMAIL_SEND_METHOD_TOGGLE,
    SET_MULTIREGISTRATION_MESSAGE_RECEIVER_EMAILS,
    SET_PERSONID,
    SET_PARTNERID,
    SET_USE_MANUAL_EMAIL_RECIPIENTS,
    CHECK_FORMELEMENT_CONDITIONS,
    PRESENTATION_ADD,
    PRESENTATION_REMOVE,
    SET_SUBMISSION_PRESENTATIONS
 } from './ActionTypes';

export const submissionFetch = payload => {
    return {
        type: SUBMISSION_FETCH,
        ...payload
    }
};

export const submissionFetchSuccess = (formSubmission, form) => {
    return {
        type: SUBMISSION_FETCH_SUCCESS,
        formSubmission,
        form
    }
};

export const submissionFetchFail = (error) => {
    return {
        type: SUBMISSION_FETCH_FAIL,
        error: error
    }
};

export const submissionSubmit = (action) => {
    return {
        type: SUBMISSION_SUBMIT,
        ...action
    }
}

export const submissionSubmitSuccess = (submissionResponse) => {
    return {
        type: SUBMISSION_SUBMIT_SUCCESS,
        submissionResponse
    }
}

export const submissionSubmitFail = () => {
    return {
        type: SUBMISSION_SUBMIT_FAIL
    }
}
export const addSubmissionInstance = submissionId => {
    return {
        type: ADD_SUBMISSION_INSTANCE,
        submissionId
    }
};

export const removeSubmissionInstance = submissionId => {
    return {
        type: REMOVE_SUBMISSION_INSTANCE,
        submissionId: submissionId
    }
}

export const inputChange = (submissionId, formElementId, payload) => {
    return {
        type: INPUT_CHANGE,
        submissionId: submissionId,
        formElementId: formElementId,
        payload: payload
    }
}

export const setInputValue = (submissionId, formElementId, payload, skipConditions = false) => {
    return {
        type: SET_SUBMISSION_VALUE,
        submissionId: submissionId,
        formElementId: formElementId,
        payload: payload,
        skipConditions
    }
};

export const checkFormElementConditions = (submissionId, formElementId, payload) => {
    return {
        type: CHECK_FORMELEMENT_CONDITIONS,
        submissionId,
        formElementId,
        payload
    }
};

export const setInputValidation = (submissionId, formElementId, validation) => {
    return {
        type: FIELD_SET_VALIDATION,
        submissionId: submissionId,
        formElementId: formElementId,
        validation
    }
};

export const fieldSetRequirement = (submissionId, formElementId, required = true) => {
    return {
        type: FIELD_SET_REQUIREMENT,
        submissionId: submissionId,
        formElementId: formElementId,
        required: required
    }
};

export const setCouponCode = couponCode => {
    return {
        type: COUPON_CODE_UPDATE,
        couponCode: couponCode
    }
}

export const validatePhoneNumber = (submissionId, formElementId, value) => {
    return {
        type: VALIDATE_PHONE_NUMBER,
        submissionId,
        formElementId,
        value
    }
}

export const validateSubmissions = (currentPageId = null) => {
    return {
        type: SUBMISSION_VALIDATE,
        currentPageId
    }
}

export const setSubmissionValidationErrors = validationErrors => {
    return {
        type: SUBMISSION_SET_VALIDATION_ERRORS,
        validationErrors
    }
}

export const confirmationEmailSendMethodToggle = () => {
    return {
        type: CONFIRMATION_EMAIL_SEND_METHOD_TOGGLE
    }
}

export const setMultiRegMessagesReceiverEmails = (email, valid, isValidated = true) => {
    return {
        type: SET_MULTIREGISTRATION_MESSAGE_RECEIVER_EMAILS,
        email,
        valid,
        isValidated
    }
}

export const setPersonID = personID => {
    return {
        type: SET_PERSONID,
        personID
    }
}

export const setPartnerID = partnerID => {
    return {
        type: SET_PARTNERID,
        partnerID
    }
}

export const setUseManualEmailRecipients = value => {
    return {
        type: SET_USE_MANUAL_EMAIL_RECIPIENTS,
        value
    }
}

export const presentationAdd = (submissionId, presentationID) => {
    return {
        type: PRESENTATION_ADD,
        submissionId,
        presentationID
    }
}

export const presentationRemove = (submissionId, presentationID) => {
    return {
        type: PRESENTATION_REMOVE,
        submissionId,
        presentationID
    }
}

export const setSubmissionPresentations = (submissionId, presentations, fromLocalStorage = false) => {
    return {
        type: SET_SUBMISSION_PRESENTATIONS,
        submissionId,
        presentations,
        fromLocalStorage
    }
}