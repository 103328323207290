import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { IoIosCloseCircle } from "react-icons/io";

function FileFieldQueueTable(props) {

    const { t, files = [], uploads = [], onRemove, multiple } = props;


    return (
        (files && files.length > 0) || (uploads && uploads.length > 0) ?
            <Table borderless striped size="sm" style={{marginTop: '15px', fontSize: '13px'}}>
                <thead>
                    <tr>
                        <th style={{width: '20px', paddingLeft: '0px'}} ></th>
                        <th>{t('Filename')}</th>
                        <th style={{textAlign: 'right'}}>{t('Size')}</th>
                    </tr>
                </thead>
                <tbody>
                    {files && files.map(file =>
                        <tr key={file.id}>
                            <td style={{color: 'red', paddingLeft: '0px'}} className="text-danger">
                                <IoIosCloseCircle onClick={() => onRemove(file)} style={{cursor: 'pointer'}} />
                            </td>
                            <td>{file.name}</td>
                            <td style={{textAlign: 'right'}}>{file.sizeReadable}</td>
                        </tr>
                    )}
                    {(multiple || (!multiple && (!files || !files.length))) &&
                        uploads &&
                            uploads.map(file =>
                                <tr key={file.materialID}>
                                    <td style={{color: 'red', paddingLeft: '0px'}} className="text-danger"></td>
                                    <td>{file.fileName}{file.fileType}</td>
                                    <td style={{textAlign: 'right'}}>{file.fileSizeKB}kB</td>
                                </tr>
                            )
                    }
                </tbody>
            </Table>
        : null
    )
}

FileFieldQueueTable.propTypes = {
    uploads: PropTypes.array, // If modifying existing submission, this holds old files
    files: PropTypes.array,
    onRemove: PropTypes.func.isRequired
};

export default FileFieldQueueTable;