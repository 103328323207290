import { combineReducers } from 'redux';

import AppReducer from './AppReducer';
import ConditionsReducer from './ConditionsReducer';
import FormReducer from './FormReducer';
import LanguageReducer from './LanguageReducer';
import NavigationReducer from './NavigationReducer';
import PaymentReducer from './PaymentReducer';
import SubmissionReducer from './SubmissionReducer';
import ParticipantListReducer from './ParticipantListReducer';
import SubmissionReservationReducer from './SubmissionReservationReducer';

const appReducer = combineReducers({
	app: AppReducer,
	conditions: ConditionsReducer,
	form: FormReducer,
	language: LanguageReducer,
	navigation: NavigationReducer,
	payment: PaymentReducer,
	submission: SubmissionReducer,
	submissionReservation: SubmissionReservationReducer,
	participantList: ParticipantListReducer
});

const rootReducer = (state, action) => {
	if (action.type === 'RESET_STORE') {
		state = undefined
	}
	return appReducer(state, action)
}

export default rootReducer;