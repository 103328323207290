import React from 'react';
import { FaRegGrinBeam, FaRegFrown, FaRegAngry, FaRegMeh, FaRegSmile } from 'react-icons/fa';

const iconSets = {
    4: {
        1: <FaRegFrown />,
        2: <FaRegMeh />,
        3: <FaRegSmile />,
        4: <FaRegGrinBeam />
    },
    5: {
        1: <FaRegAngry />,
        2: <FaRegFrown />,
        3: <FaRegMeh />,
        4: <FaRegSmile />,
        5: <FaRegGrinBeam />
    }
}

const IconRatingElement = ({ selected, element, onClick }) => {

    const ratingMax = +(element.ratingMax);
    const selectedClass = selected === '' ? 'not-selected' : 'is-selected'
    let icons = [];

    for (let i = 1; i <= ratingMax; i++) {

        const iconSelectedClass = (i === parseInt(selected)) ? 'is-selected' : 'not-selected';

        icons.push(
            <span className={"iconrating-icon iconrating-icon-" + i + " " + iconSelectedClass} onClick={() => onClick(element.formElementId, i)} key={element.formElementId+i}>
                {iconSets[ratingMax][i]}
            </span>
        );
    }

    return (
        <div className={"iconrating-element rating-max-"+ratingMax + " " + selectedClass}>
            {icons}
        </div>
    )
};

export default IconRatingElement;