import {
    NAVIGATE_NEXT_FORM_PAGE,
    NAVIGATE_PREV_FORM_PAGE,
    NAVIGATE_NEXT_FORM_PAGE_SUCCESS,
    SET_ACTIVE_VIEW,
    NEXT_STEP,
    PREV_STEP
 } from './ActionTypes';


 export const nextStep = () => {
    return {
        type: NEXT_STEP
    }
}

export const prevStep = () => {
    return {
        type: PREV_STEP
    }
}

export const navigateNextFormPage = (currentPageId) => {
    return {
        type: NAVIGATE_NEXT_FORM_PAGE,
        currentPageId
    }
}

export const navigateNextFormPageSuccess = (currentPageId) => {
    return {
        type: NAVIGATE_NEXT_FORM_PAGE_SUCCESS,
        currentPageId
    }
}

export const navigatePrevFormPage = (currentPageId) => {
    return {
        type: NAVIGATE_PREV_FORM_PAGE,
        currentPageId
    }
}

export const setActiveView = (view, scroll = true) => {
    return {
        type: SET_ACTIVE_VIEW,
        activeView: view,
        scroll
    }
}

