import {
    RESERVATION_SAVE,
    RESERVATION_SAVE_SUCCESS,
    RESERVATION_SAVE_FAIL
} from './ActionTypes';

export const reservationSave = (payload = {}) => {
    return {
        type: RESERVATION_SAVE,
        payload
    }
}

export const reservationSaveSuccess = submissionReservation => {
    return {
        type: RESERVATION_SAVE_SUCCESS,
        submissionReservation
    }
}

export const reservationSaveFail = error => {
    return {
        type: RESERVATION_SAVE_FAIL,
        error
    }
}

