import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { PropTypes } from 'prop-types';

const HIDE_INDIVIDUAL_PAYER_FOR_EVENTS = ['8b4b0c8a-d030-11eb-a21a-ee6a04371b85', '2c38c9ce-405f-11ec-89e2-080027a0fbc5'];

// 2023 KEVÄTKOULUTUSPÄIVÄT, 2024 KEVÄTKOULUTUSPÄIVÄT
const HIDE_INDIVIDUAL_PAYER_FOR_FORMS = ['ca5d3ea9-9e09-11ed-a018-ee6a04371b85', '21f188de-c383-11ee-9091-ee6a04371b85', 'ea3cb71d-c381-11ee-9091-ee6a04371b85'];

export default function InvoicePayer({ t, invoicePayerIsCompany, eventID, onChange, formID }) {
    return (
        <fieldset className="InvoicePayer">
            <FormGroup>
                <Label>{t('BillingInformation.invoicePayer')}</Label>
            </FormGroup>
            <FormGroup check inline  className="invoicePayer-company">
                <Label check>
                    <Input
                        type="radio"
                        name="invoicePayerIsCompany"
                        defaultChecked={invoicePayerIsCompany}
                        onChange={() => onChange(true)} />{' '}
                    {t('BillingInformation.invoicePayerCompany')}
                </Label>
            </FormGroup>
            {/* Hide "individual" option for some events */}
            {(HIDE_INDIVIDUAL_PAYER_FOR_EVENTS.indexOf(eventID) === -1 && HIDE_INDIVIDUAL_PAYER_FOR_FORMS.indexOf(formID) === -1) ?
                <FormGroup check inline className="invoicePayer-individual">
                    <Label check>
                        <Input
                            type="radio"
                            name="invoicePayerIsCompany"
                            defaultChecked={!invoicePayerIsCompany}
                            onChange={() => onChange(false)} />{' '}
                        {t('BillingInformation.invoicePayerIndividual')}
                    </Label>
                </FormGroup>
            : null}

        </fieldset>
    )
}

InvoicePayer.propTypes = {
    t: PropTypes.func,
    invoicePayerIsCompany: PropTypes.bool,
    onChange: PropTypes.func
}