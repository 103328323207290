
import React from 'react';
import PropTypes from 'prop-types';
import { FormText } from 'reactstrap';
import { withTranslation } from 'react-i18next';

/**
 * https://docs.paytrail.com/en/index-all.html#idm9374552432
 * /v1/public/paytrail/paymentForm/{orderID}
 *
 * Purpose of this compoonent is to redirect
 * the user to Paytrail payment selection page.
 */

class PaytrailPaymentForm extends React.Component {

    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.handleSubmit();
    }

    handleSubmit() {
        this.refs.paytrailForm.submit();
    }

    render() {

        const { t, paytrailData } = this.props;

        let formActionUrl = 'https://payment.paytrail.com/e2';
        let formMethod = 'POST';

        if(paytrailData && paytrailData.href) {
            formActionUrl = paytrailData.href;
            formMethod = 'GET';
        }

        let paytrailFields = [];
        if(paytrailData !== null) {
            for (const [key, value] of Object.entries(paytrailData)) {
                paytrailFields.push(
                    <input type="hidden" name={key} value={value} key={key} />
                )
            }
        }

        return (
            paytrailData ? (
                <div id="paytrailFormWrapper">

                    <form id="paytrailForm" name="paytrailForm" action={formActionUrl} method={formMethod} ref="paytrailForm">

                        {paytrailFields}

                        <FormText style={{marginTop: '15px', marginBottom: '20px', fontSize: '16px'}}>
                            <span>{t('Payments.redirectingToPaytrail')}</span>
                        </FormText>

                        <button type="submit" className="btn btn-secondary btn-sm">
                            {t('Payments.toPaytrailPaymentMethod')}
                        </button>

                    </form>

                </div>
            ) : null
        )
    }
}

PaytrailPaymentForm.propTypes = {
    paytrailData: PropTypes.object.isRequired
};

export default withTranslation()(PaytrailPaymentForm);