import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const LoadingIndicator = ({ status = false, text = '', color = 'primary', size = 'md' }) => {

    const { t } = useTranslation('translation');

    return (
        status ?
            <div className="LoadingIndicator">
                <Spinner
                    size={size}
                    color={color}
                    className="LoadingIndicatorSpinner"
                />{' '}
                <span className="LoadingIndicatorText">
                    {t(text)}
                </span>
            </div>
        : null
    )
}

LoadingIndicator.propTypes = {
    status: PropTypes.bool,
    text: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string
};

export default LoadingIndicator;