import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert, Form, FormGroup, Label, Row, Col } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { useTranslation } from 'react-i18next';

import {
    updateBillingInformationData,
    setBillingInformationValidation,
    setInvoiceCustomer,
    setMultiRegMessagesReceiverEmails,
    invoiceDeliveryMethodSet
} from './../../actions';

import { validateBillingInformationField, validateBillingInformation } from '../../utility/validator';

import InvoiceDeliveryMethod from './InvoiceDeliveryMethod';
import BillinginformationField from './BillingIformationField';



function BillingInformationForm(props) {

    const { invoiceCustomers, isValidated, defaultBillingFieldValues, billingInformation, sendInvoiceEmail, languageId, eventID } = props;
    const { fields, valid, validation, invoicePayerIsCompany, invoiceDeliveryMethod } = billingInformation;
    const { t } = useTranslation();
    const [ selectedTypeAhead, setSelectedTypeAhead ] = React.useState([]);

    const invoiceCustomersData = invoiceCustomers.data;

    const _isBusinessIdRequired = React.useMemo(() => {
        if(
            eventID === '8b4b0c8a-d030-11eb-a21a-ee6a04371b85'
            || eventID === '2c38c9ce-405f-11ec-89e2-080027a0fbc5'
        ) {
            if(languageId !== 1) {
                return false;
            }
        }
        return invoicePayerIsCompany;
    }, [invoicePayerIsCompany, eventID, languageId]);

    // 2023 KEVÄTKOULUTUSPÄIVÄT
    const _isInvoiceReferenceRequired = React.useMemo(() => {
        if(eventID === 'c8d0487d-9e09-11ed-a018-ee6a04371b85') {
            return true;
        }
        return false;
    })



    /**
     * On component mount, set fields.
     */
    useEffect(() => {
        for(const [name, value]  of Object.entries(defaultBillingFieldValues)) {

            // Set billing information to store, if not yet set
            if(!billingInformation.fields[name]) {
                props.onUpdateBillingInformation(name, value);
            }

            // If billing information is untouched, set it from invoiceCustomers if possible
            if(!Object.keys(billingInformation.fields).length) {
                if(name === 'invoiceCompany' && invoiceCustomers && invoiceCustomers.data && invoiceCustomers.data.length) {
                    for(const invoiceCustomer of invoiceCustomers.data) {
                        if(invoiceCustomer.name && value && invoiceCustomer.name === value) {
                            props.onInvoiceCustomerSelect(invoiceCustomer);
                            const billingInformationValidation = validateBillingInformation(props.billingInformation);
                            props.onSetBillingInformationValidation(billingInformationValidation);
                        }
                    }
                }
            }
        }
    }, [])

    /**
     * When invoiceCustomers is loaded or changed, set typeahead default.
     */
    useEffect(() => {
        if(invoiceCustomersData && invoiceCustomersData.length) {
            if(fields['invoiceCompany']) {
                setSelectedTypeAhead([fields['invoiceCompany']]);
            } else if (props.defaultBillingFieldValues.invoiceCompany) {
                setSelectedTypeAhead([props.defaultBillingFieldValues.invoiceCompany]);
            }
        }
    }, [invoiceCustomersData]);



    /**
     * Handle billing information change
     *
     * @param {object} event
     */
    function _handleBillingInformationChange(event) {
        const { name, value } = event.target;
        const fieldValidation = validateBillingInformationField(name, value, props.billingInformation);
        props.onUpdateBillingInformation(name, value, fieldValidation);
    }

    /**
     * Handle invoice customer select.
     *
     * @param {object} selected
     */

    function _handleInvoiceCustomerChange(selected) {
        let invoiceCustomer = (selected && selected.length && selected[0]) ? selected[0] : [];
        setSelectedTypeAhead(selected);
        props.onInvoiceCustomerSelect(invoiceCustomer);
        const billingInformationValidation = validateBillingInformation(props.billingInformation);
        props.onSetBillingInformationValidation(billingInformationValidation);
    }

    /**
     * Handle invoice delivery method change.
     *
     * @param {object} event
     */
    function _handleinvoiceDeliveryMethodChange(event) {
        const invoiceDeliveryMethod = typeof event === 'number' ? event : event.target.value;
        props.onInvoiceDeliveryMethodSet(invoiceDeliveryMethod);
        props.setBillingInformationValidated(false);
    }


    return (
        <div className="BillingInformationWrapper">

            <fieldset className="row">
                <div className="col-12" style={{marginTop: '20px'}}>
                    <InvoiceDeliveryMethod
                        key={'invoiceDeliveryMethod_'+invoiceDeliveryMethod}
                        onChange={(_handleinvoiceDeliveryMethodChange)}
                        t={t}
                        invoiceDeliveryMethod={invoiceDeliveryMethod}
                        invoicePayerIsCompany={invoicePayerIsCompany}
                        sendInvoiceEmail={sendInvoiceEmail}
                        eventID={eventID}
                    />
                </div>
            </fieldset>

            <fieldset className="row">
                <div className="col-12">
                    <legend>{t('Billing information')}</legend>
                </div>

                <div className="col-12 FieldsetContent">

                    {isValidated && valid === false && Object.keys(validation).length > 0 ? (
                        <Alert color="danger">
                            {t('BillingInformation.pleaseCheckErrors')}.
                        </Alert>
                    ) : null}

                    <Form className={invoicePayerIsCompany ? 'PayerCompany' : 'PayerIndividual'}>
                        <Row form>

                            {invoicePayerIsCompany ?
                                <React.Fragment>
                                    <Col md={6} key={'invoiceCompany'}>
                                        {(invoiceCustomers.data && invoiceCustomers.data.length) ?
                                            <FormGroup className={invoicePayerIsCompany ? 'required' : ''}>
                                                <Label for="invoiceCompany">{t('Company')}</Label>
                                                <Typeahead
                                                    onChange={(selected) => {
                                                        _handleInvoiceCustomerChange(selected);
                                                    }}
                                                    onBlur={_handleBillingInformationChange}
                                                    selected={selectedTypeAhead}
                                                    id={'invoiceCompany'}
                                                    labelKey="name"
                                                    options={invoiceCustomers.data}
                                                    minLength={3}
                                                    required={invoicePayerIsCompany}
                                                    isValid={validation['invoiceCompany'] && validation['invoiceCompany'].valid}
                                                    isInvalid={isValidated && validation['invoiceCompany'] && !validation['invoiceCompany'].valid}
                                                    inputProps={{
                                                        name: 'invoiceCompany',
                                                        id: 'invoiceCompany',
                                                        required: invoicePayerIsCompany
                                                    }}
                                                />
                                            </FormGroup>
                                        :
                                            <FormGroup className={invoicePayerIsCompany ? 'required' : ''}>
                                                <BillinginformationField
                                                    name="invoiceCompany"
                                                    label={t('Company')}
                                                    onChange={_handleBillingInformationChange}
                                                    validation={validation}
                                                    defaultValue={props.defaultBillingFieldValues.invoiceCompany || ''}
                                                    required={invoicePayerIsCompany}
                                                    fields={fields}
                                                    isValidated={isValidated} />
                                            </FormGroup>
                                        }

                                    </Col>
                                    <Col md={6} key={'invoiceCompanyBusinessID'}>
                                        <FormGroup className={_isBusinessIdRequired ? 'required' : ''}>
                                            <BillinginformationField
                                                name="invoiceCompanyBusinessID"
                                                label={t('BillingInformation.invoiceCompanyBusinessID')}
                                                onChange={_handleBillingInformationChange}
                                                validation={validation}
                                                defaultValue={props.defaultBillingFieldValues.invoiceCompanyBusinessID || ''}
                                                required={_isBusinessIdRequired}
                                                fields={fields}
                                                isValidated={isValidated} />
                                        </FormGroup>
                                    </Col>
                                    {languageId !== 1 ?
                                        <Col md={6} key={'invoiceVatNumber'}>
                                            <FormGroup>
                                                <BillinginformationField
                                                    name="invoiceVatNumber"
                                                    label={t('BillingInformation.invoiceVatNumber')}
                                                    onChange={_handleBillingInformationChange}
                                                    validation={validation}
                                                    defaultValue={props.defaultBillingFieldValues.invoiceVatNumber || ''}
                                                    fields={fields}
                                                    isValidated={isValidated}
                                                    required={false}
                                                />
                                            </FormGroup>
                                        </Col>
                                    : null}
                                </React.Fragment>
                            : null}

                            {!invoicePayerIsCompany ?
                                <React.Fragment>
                                    <Col md={6} key={'invoiceFirstNames'}>
                                        <FormGroup className="required">
                                            <BillinginformationField
                                                name="invoiceFirstNames"
                                                label={t('BillingInformation.invoiceFirstNames')}
                                                onChange={_handleBillingInformationChange}
                                                validation={validation}
                                                defaultValue={props.defaultBillingFieldValues.invoiceFirstNames || ''}
                                                required={!invoicePayerIsCompany}
                                                fields={fields}
                                                isValidated={isValidated} />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6} key={'invoiceLastName'}>
                                        <FormGroup className={'required'}>
                                            <BillinginformationField
                                                name="invoiceLastName"
                                                label={t('BillingInformation.invoiceLastName')}
                                                onChange={_handleBillingInformationChange}
                                                validation={validation}
                                                defaultValue={props.defaultBillingFieldValues.invoiceLastName || ''}
                                                required={!invoicePayerIsCompany}
                                                fields={fields}
                                                isValidated={isValidated} />
                                        </FormGroup>
                                    </Col>
                                </React.Fragment>
                            : null}

                            <Col md={6} key={'invoiceReference'}>
                                <FormGroup className={_isInvoiceReferenceRequired ? 'required' : ''}>
                                    <BillinginformationField
                                        name="invoiceReference"
                                        label={t('BillingInformation.invoiceReference')}
                                        onChange={_handleBillingInformationChange}
                                        validation={validation}
                                        required={_isInvoiceReferenceRequired}
                                        fields={fields}
                                        isValidated={isValidated} />
                                </FormGroup>
                            </Col>

                            {invoiceDeliveryMethod === 1 ?
                                <Col md={6} key={'invoiceDeliveryEmail'}>
                                    <FormGroup className={invoiceDeliveryMethod === 1 ? 'required' : ''}>
                                        <BillinginformationField
                                            name="invoiceDeliveryEmail"
                                            label={t('BillingInformation.invoiceDeliveryEmail')}
                                            onChange={_handleBillingInformationChange}
                                            validation={validation}
                                            required={invoiceDeliveryMethod === 1}
                                            defaultValue={props.defaultBillingFieldValues.email || ''}
                                            fields={fields}
                                            isValidated={isValidated} />
                                    </FormGroup>
                                </Col>
                            : null}

                            <Col md={6} key={'invoiceAdditionalDetails'}>
                                <FormGroup>
                                    <BillinginformationField
                                        name="invoiceAdditionalDetails"
                                        label={t('BillingInformation.invoiceAdditionalDetails')}
                                        onChange={_handleBillingInformationChange}
                                        validation={validation}
                                        required={false}
                                        fields={fields}
                                        isValidated={isValidated} />
                                </FormGroup>
                            </Col>
                        </Row>

                        <hr />

                        {(invoicePayerIsCompany && invoiceDeliveryMethod === 0) ?
                            <Row form>
                                <Col md={6} key={'eInvoiceAddress'}>
                                    <FormGroup className="required">
                                        <BillinginformationField
                                            name="eInvoiceAddress"
                                            label={t('BillingInformation.eInvoiceAddress')}
                                            onChange={_handleBillingInformationChange}
                                            required={invoiceDeliveryMethod === 'eInvoice'}
                                            defaultValue={fields.eInvoiceAddress || ''}
                                            validation={validation}
                                            fields={fields}
                                            isValidated={isValidated} />
                                    </FormGroup>
                                </Col>
                                <Col md={6} key={'eInvoiceIntermediateId'}>
                                    <FormGroup className="required">
                                        <BillinginformationField
                                            name="eInvoiceIntermediateId"
                                            label={t('BillingInformation.eInvoiceIntermediateId')}
                                            onChange={_handleBillingInformationChange}
                                            required={invoiceDeliveryMethod === 'eInvoice'}
                                            defaultValue={fields.eInvoiceIntermediateId || ''}
                                            validation={validation}
                                            fields={fields}
                                            isValidated={isValidated} />
                                    </FormGroup>
                                </Col>
                            </Row>
                        : null}

                        <Row form>
                            <Col md={6} key={'invoiceStreetAddress'}>
                                <FormGroup className={'required'}>
                                    <BillinginformationField
                                        name="invoiceStreetAddress"
                                        label={t('BillingInformation.invoiceStreetAddress')}
                                        onChange={_handleBillingInformationChange}
                                        required={true}
                                        defaultValue={props.defaultBillingFieldValues.invoiceStreetAddress || ''}
                                        validation={validation}
                                        fields={fields}
                                        isValidated={isValidated} />
                                </FormGroup>
                            </Col>
                            <Col md={6} key={'invoiceCity'}>
                                <FormGroup className={'required'}>
                                    <BillinginformationField
                                        name="invoiceCity"
                                        label={t('BillingInformation.invoiceCity')}
                                        onChange={_handleBillingInformationChange}
                                        required={true}
                                        validation={validation}
                                        defaultValue={props.defaultBillingFieldValues.invoiceCity || ''}
                                        fields={fields}
                                        isValidated={isValidated} />
                                </FormGroup>
                            </Col>
                            <Col md={2} key={'invoicePostalCode'}>
                                <FormGroup className={'required'}>
                                    <BillinginformationField
                                        name="invoicePostalCode"
                                        label={t('BillingInformation.invoicePostalCode')}
                                        onChange={_handleBillingInformationChange}
                                        defaultValue={props.defaultBillingFieldValues.invoicePostalCode || ''}
                                        required={true}
                                        validation={validation}
                                        fields={fields}
                                        isValidated={isValidated} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </fieldset>
        </div>
    )
}

BillingInformationForm.propTypes = {
    billingInformation: PropTypes.object,
    fields: PropTypes.object
};

const mapStateToProps = state => {
    return {
        billingInformation: state.payment.billingInformation,
        invoiceCustomers: state.payment.invoiceCustomers,
        sendInvoiceEmail: state.form.form.sendInvoiceEmail
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateBillingInformation: (name, value, validation) => {
            return dispatch(updateBillingInformationData(name, value, validation));
        },
        onSetBillingInformationValidation: (validation) => {
            return dispatch(setBillingInformationValidation(validation))
        },
        onInvoiceCustomerSelect: invoiceCustomer => {
            return dispatch(setInvoiceCustomer(invoiceCustomer))
        },
        onChangeMultiRegMessagesReceiverEmails: (email, valid, isValidated) => {
            return dispatch(setMultiRegMessagesReceiverEmails(email, valid, isValidated));
        },
        onInvoiceDeliveryMethodSet: invoiceDeliveryMethod => {
            return dispatch(invoiceDeliveryMethodSet(invoiceDeliveryMethod));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInformationForm);