import {
    PARTICIPANT_LIST_FETCH,
    PARTICIPANT_LIST_FETCH_SUCCESS,
    PARTICIPANT_LIST_FETCH_FAIL
} from "../actions/ActionTypes";

const initialState = {
    participantsAllowedToShow: [],
    personsMax: null,
    personsRegistered: null,
    loading: false,
    errors: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case PARTICIPANT_LIST_FETCH:
            return {
                ...state,
                loading: true
            };
        case PARTICIPANT_LIST_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload
            }
        case PARTICIPANT_LIST_FETCH_FAIL:
            return {
                ...state,
                errors: action.errors
            }
        default:
            return state;
    }
};
