import { select } from "redux-saga/effects";
import i18n from './../i18n';
import { registerLocale, setDefaultLocale } from "react-datepicker";

import fi from '../translations/date-fns/fi';
import sv from '../translations/date-fns/sv';

const getAllLanguages = (state) => state.language.languages;

const ALLOWED_LANGUAGES = ['fi', 'sv'];

const DEFAULT_LANGUAGE = 'en';

registerLocale('sv', sv);
registerLocale('fi', fi);

export function* languageChangeSaga(action) {
	try {

        const languages = yield select(getAllLanguages);
        const langCode = yield getLanguageCodeById(action.languageId, languages);

        // Change i18n language
		yield i18n.changeLanguage(langCode);

        // Change react-datepicker locale
        if(ALLOWED_LANGUAGES.indexOf(langCode) !== -1) {
            yield setDefaultLocale(langCode);
        } else {
            yield setDefaultLocale('en-GB');
        }

	} catch (error) {
		console.error(error);
	}
}

const getLanguageCodeById = (languageId, languages) => {
    let langCode = DEFAULT_LANGUAGE;
    for (const language of languages) {
        if ( language.languageId === languageId ) {
            langCode = language.langCode;
            break;
        }
    }
    return langCode;
}