import React from 'react';
import { PropTypes } from 'prop-types';

import Step from './Step/Step';

const Steps = ({ t, activeView, activePageId, formPages, showSummaryPage, languageId }) => {

    /*
    * Calculate step width based on how many form pages
    * there are plus staticPageCount (summary <optional>, success)
    */
    const staticPageCount = showSummaryPage ? 2 : 1;

    const stepWidthStyle = {
        width: 99 / (formPages.allIds.length + staticPageCount ) + '%'
    };

    return (
        (formPages && formPages.allIds.length) ?
            <div className="row">
                <div className="col-12" id="Steps">
                   <ul className="steps">
                        {Object.values(formPages.byId).sort((a, b) => a.order - b.order).map((page, index) =>
                            <Step
                                key={page.formPageId}
                                title={page.title[languageId] || (index+1) + '. ' + t('App.page')}
                                stepWidthStyle={stepWidthStyle}
                                pageNum={(index+1)}
                                active={activePageId === page.formPageId && activeView === 'form'}
                                done={index < formPages.allIds.indexOf(activePageId) || activeView !== 'form'}
                            />
                        )}
                        {showSummaryPage ?
                        <Step
                            title={t('Summary')}
                            stepWidthStyle={stepWidthStyle}
                            done={activeView === 'success'}
                            active={activeView === 'summary'}
                            pageNum={formPages.allIds.length + staticPageCount - 1}
                        />
                        : null}
                        <Step
                            title={t('Done')}
                            stepWidthStyle={stepWidthStyle}
                            active={activeView === 'success'}
                            done={activeView === 'success'}
                            last={true}
                            pageNum={formPages.allIds.length + staticPageCount}
                        />
                    </ul>
                </div>
            </div>
        : null
    )
}

Steps.propTypes = {
    formPages: PropTypes.object,
    languageId: PropTypes.number
}

export default Steps;