import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Button, Input, Form, Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';


class CouponCode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            couponCode: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        event.preventDefault();
        this.props.onChange(event.target.value);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.onSubmit(this.state.couponCode);
    }

    render() {

        const { t, used, found, limitExceeded, couponUsageLimitExceeded, validating } = this.props;

        let alertText = '';
        if(used) {
            if(!found) {
                alertText = t('The code is invalid or has expired');
            } else if (limitExceeded) {
                alertText = t('The code has expired');
            }
            else if (couponUsageLimitExceeded) {
                alertText = t('The code is already used');
            } else {
                alertText = t('Code accepted');
            }
        }

        return (
            <fieldset className="row couponCodeFieldset">
                <div className="col-12">
                    <legend>{t('Coupon code')}</legend>
                </div>

                <div className="col-12 FieldsetContent">
                    {used ?
                        <div className="couponCodeAlert">
                            <Alert color={(found && !couponUsageLimitExceeded) ? 'success' : 'warning'}>
                                {alertText}
                            </Alert>
                        </div>
                    : null}
                    <Form inline onSubmit={this.handleSubmit}>
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Input
                                type="text"
                                name="couponCode"
                                id="couponCode"
                                placeholder={t("Code here")}
                                onChange={this.handleChange}
                                onKeyPress={e => e.target.keyCode === 13 && e.preventDefault()}
                                valid={used && found}
                                defaultValue={this.props.code} />
                        </FormGroup>
                        <Button disabled={validating}>{t('Check')}</Button>
                    </Form>
                </div>
            </fieldset>
        )
    }
}

CouponCode.propTypes = {
    valid: PropTypes.bool,
    validating: PropTypes.bool,
    code: PropTypes.string,
    onSubmit: PropTypes.func,
    used: PropTypes.bool,
    found: PropTypes.bool
};

export default withTranslation('translation')(CouponCode);