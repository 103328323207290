import React from 'react';
import { useTranslation } from "react-i18next";

export default function ReservationFullView() {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <p>{t("SubmissionReservation.isFullMain")}.</p>
            <p>{t("SubmissionReservation.isFullSecondary")}.</p>
        </React.Fragment>

    )
}