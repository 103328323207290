import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next'

const SuccessContainer = ({ t, form, languageId, order, paytrailPaymentResult }) => (
    <div className="SuccessContainer">

        {(order && order.status === 2 && paytrailPaymentResult === "true") &&
            <div className="alert alert-success" role="alert">
               {t('Payments.paymentSuccess')}
            </div>
        }

        <div dangerouslySetInnerHTML={{__html: form.submitSuccessPageContent[languageId]}}></div>

    </div>
);

SuccessContainer.propTypes = {
    form: PropTypes.object,
    languageId: PropTypes.number,
    order: PropTypes.object,
    paytrailPaymentResult: PropTypes.string
};

export default withTranslation('translation')(SuccessContainer);