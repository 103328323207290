import { put } from "redux-saga/effects";
import api from '../api';

import { fetchInvoiceCustomersSuccess, fetchInvoiceCustomersFail } from './../actions/BillingInformationActions';

export function* fetchInvoiceCustomersSaga({organizationID}) {
	try {
		const response = yield api.post('public/invoiceCustomers', { "organizationID": organizationID });
		yield put(fetchInvoiceCustomersSuccess(response.data.data.invoiceCustomers));
	} catch (error) {
		if(error.response && error.response.data && error.response.data.errors) {
			yield put(fetchInvoiceCustomersFail(error.response.data.errors));
		}
	}
}