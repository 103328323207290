import {
    FORM_FETCH_SUCCESS,
    PAYMENT_METHOD_SET,
    SUBMISSION_FETCH_SUCCESS,
    SUBMISSION_SUBMIT_SUCCESS,
    BILLING_DETAILS_SET,
    BILLING_INFORMATION_SET,
    BILLING_INFORMATION_VALID,
    BILLING_INFORMATION_INVALID,
    PAYTRAIL_SET_REDIRECTING,
    PAYTRAIL_SET_STATUS,
    PAYTRAIL_FETCH_FORMDATA,
    PAYTRAIL_FETCH_FORMDATA_SUCCESS,
    PAYTRAIL_FETCH_FORMDATA_FAIL,
    FETCH_INVOICE_CUSTOMERS,
    FETCH_INVOICE_CUSTOMERS_SUCCESS,
    FETCH_INVOICE_CUSTOMERS_FAIL,
    INVOICE_CUSTOMER_SET,
    PAYER_IS_COMPANY_SET,
    INVOICE_DELIVERY_METHOD_SET
} from './../actions/ActionTypes';

const initialState = {
    formID: null,
    paymentMethods: {
        selected: null,
        default: null,
        byId: {},
        allIds: []
    },
    paymentMethodProducts: null,
    billingInformation: {
        fields: {},
        valid: null,
        validating: false,
        isValidated: false,
        validation: {},
        errors: [],
        invoicePayerIsCompany: false,
        invoiceDeliveryMethod: 1,
        invoiceDeliveryEmail: null
    },
    paytrail: {
        loading: false,
        redirecting: false,
        formData: null,
        status: false
    },
    invoiceCustomers: {
        initialized: false,
        loading: false,
        data: [],
        errors: [],
        selected: []
    },
    eInvoice: false
};

// Events with invoice as fixed default payment method
const DEFAULT_PAYMENT_METHOD_INVOICE = [];
const DEFAULT_PAYER_COMPANY = ['8b4b0c8a-d030-11eb-a21a-ee6a04371b85', '92420bd9-7cf0-11ec-a018-ee6a04371b85'];

export default function(state = initialState, action) {
    switch(action.type) {
        case FORM_FETCH_SUCCESS:
            return {
                ...state,
                formID: action.form.formID,
                paymentMethods: {
                    ...state.paymentMethods,
                    byId: paymentMethodsById(action.form.paymentMethods, action.form.supportedPaymentMethods),
                    default: action.form.products && action.form.supportedPaymentMethods === 1
                        ? 1
                        : DEFAULT_PAYMENT_METHOD_INVOICE.indexOf(action.form.eventID) > -1
                            ? 1
                            : 2
                },
                paymentMethodProducts: action.form.products
                    .filter(product => product.paymentMethod !== null)
                    .reduce((current, item) => {
                            const { productID, name, sku, totalPriceRounded, currency } = item.product;
                            current[item.paymentMethod] = current[item.paymentMethod] || [];
                            current[item.paymentMethod].push({
                                productID,
                                name,
                                sku,
                                totalPriceRounded,
                                currency
                            });
                            return current;
                }, {}),
                billingInformation: {
                    ...state.billingInformation,
                    invoicePayerIsCompany: setInvoicePayerIsCompany(action.form),
                    invoiceDeliveryMethod: action.form.formID === 'e8da38a7-4081-11ec-a018-ee6a04371b85'
                        ? 0
                        : action.form.sendInvoiceEmail ? 1 : 1
                }
            }
        case PAYMENT_METHOD_SET:
            return {
                ...state,
                paymentMethods: {
                    ...state.paymentMethods,
                    selected: parseInt(action.paymentMethod)
                }
            }
        case SUBMISSION_FETCH_SUCCESS:
            return {
                ...state,
                paymentMethods: {
                    ...state.paymentMethods,
                    selected: setSelectedPaymentMethod(action.formSubmission, state.paymentMethods)
                },
                billingInformation: {
                    ...state.billingInformation,
                    fields: setBillingInformationFields(action.formSubmission, state.billingInformation),
                    invoiceDeliveryMethod: action.formSubmission.order
                        ? action.formSubmission.order.invoiceDeliveryMethod
                        : state.billingInformation.invoiceDeliveryMethod,
                    invoiceDeliveryEmail: action.formSubmission.order
                        ? action.formSubmission.order.invoiceDeliveryEmail
                        : state.billingInformation.invoiceDeliveryEmail,
                    invoicePayerIsCompany: action.formSubmission.order
                        ? action.formSubmission.order.invoicePayerIsCompany
                        : state.billingInformation.invoicePayerIsCompany,
                }
            }
        case SUBMISSION_SUBMIT_SUCCESS:
            return {
                ...state,
                paymentMethods: {
                    ...state.paymentMethods,
                    selected: (action.submissionResponse[0].order && !state.paymentMethods.selected) ? state.paymentMethods.default : state.paymentMethods.selected
                }
            }
        case BILLING_INFORMATION_SET:
            return {
                ...state,
                billingInformation: {
                    ...state.billingInformation,
                    fields: {
                        ...state.billingInformation.fields,
                        [action.name]: action.value
                    },
                    validation: {
                        ...state.billingInformation.validation,
                        [action.name]: {...action.validation}
                    }
                },
                /* invoiceCustomers: {
                    ...state.invoiceCustomers,
                    // selected: []
                } */
            }
        case BILLING_INFORMATION_VALID:
            return {
                ...state,
                billingInformation: {
                    ...state.billingInformation,
                    errors: [],
                    valid: true,
                    isValidated: true
                }
            }
        case BILLING_INFORMATION_INVALID:
            const billingFieldsWithErrors = Object.values(action.errors).filter(field => !field.valid);
            return {
                ...state,
                billingInformation: {
                    ...state.billingInformation,
                    validation: action.errors,
                    errors: billingFieldsWithErrors,
                    valid: action.valid,
                    isValidated: true
                }
            }
        case BILLING_DETAILS_SET:
            return {
                ...state,
                billingDetails: action.billingDetails,
            }
        case PAYTRAIL_SET_REDIRECTING:
            return {
                ...state,
                redirecting: action.redirecting
            };
        case PAYTRAIL_SET_STATUS:
            return {
                ...state,
                paymentStatus: action.paymentStatus
            }
        case PAYTRAIL_FETCH_FORMDATA:
            return {
                ...state,
                paytrail: {
                    ...state.paytrail,
                    redirecting: false,
                    formData: null,
                    status: 'processing'
                }
            }
        case PAYTRAIL_FETCH_FORMDATA_SUCCESS:
            return {
                ...state,
                paytrail: {
                    ...state.paytrail,
                    redirecting: true,
                    formData: action.paytrailFormData,
                    status: 'success'
                }
            }
        case PAYTRAIL_FETCH_FORMDATA_FAIL:
            return {
                ...state,
                paytrail: {
                    ...state.paytrail,
                    redirecting: false,
                    formData: null,
                    status: 'error'
                }
            }
        case FETCH_INVOICE_CUSTOMERS:
            return {
                ...state,
                invoiceCustomers: {
                    ...state.invoiceCustomers,
                    loading: true
                }
            }
        case FETCH_INVOICE_CUSTOMERS_SUCCESS:
            return {
                ...state,
                invoiceCustomers: {
                    ...state.invoiceCustomers,
                    loading: false,
                    initialized: true,
                    data: action.customers.filter(customer => customer.name).map(customer => {
                        customer.id = customer.invoiceCustomerID;
                        customer.label = customer.name;
                        return customer;
                    })
                }
            }
        case FETCH_INVOICE_CUSTOMERS_FAIL:
            return {
                ...state,
                invoiceCustomers: {
                    ...state.invoiceCustomers,
                    loading: false,
                    initialized: true,
                    data: [],
                    errors: action.errors
                }
            }
        case INVOICE_CUSTOMER_SET:
            return {
                ...state,
                invoiceCustomers: {
                    ...state.invoiceCustomers,
                    selected: action.invoiceCustomer ? [action.invoiceCustomer] : []
                },
                billingInformation:{
                    ...state.billingInformation,
                    fields: (action.invoiceCustomer && action.invoiceCustomer.invoiceCustomerID) ? updateBillingInformationFromInvoiceCustomer(action.invoiceCustomer, state.billingInformation) : {...state.billingInformation.fields}
                }
            }
        case PAYER_IS_COMPANY_SET:
            return {
                ...state,
                billingInformation: {
                    ...state.billingInformation,
                    invoicePayerIsCompany: action.invoicePayerIsCompany,
                    // Lääkäripäivät 2022 customization
                    invoiceDeliveryMethod: state.formID === 'e8da38a7-4081-11ec-a018-ee6a04371b85'
                        ? 0
                        : (!action.invoicePayerIsCompany && state.billingInformation.invoiceDeliveryMethod === 0)
                            ? 1
                            : state.billingInformation.invoiceDeliveryMethod
                },
                invoiceCustomers: {
                    ...state.invoiceCustomers,
                    selected: action.invoicePayerIsCompany ? state.invoiceCustomers.selected : []
                }
            }
        case INVOICE_DELIVERY_METHOD_SET:
            return {
                ...state,
                billingInformation: {
                    ...state.billingInformation,
                    invoiceDeliveryMethod: parseInt(action.invoiceDeliveryMethod)
                }
            }
        default:
            return state;
    }
}

const paymentMethodsById = (pages, supportedPaymentMethods) => {
    return pages.filter(method => {
        return supportedPaymentMethods === 3 || supportedPaymentMethods === method.id;
    }).reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
    }, {})
};

const updateBillingInformationFromInvoiceCustomer = (invoiceCustomer, billingInformation) => {
    let output = {
        invoiceCompany: invoiceCustomer.name || '',
        invoiceCompanyBusinessID: invoiceCustomer.businessID || '',
        invoiceVatNumber: invoiceCustomer.invoiceVatNumber || '',
        invoiceStreetAddress: invoiceCustomer.streetAddress || '',
        invoiceCity: invoiceCustomer.city || '',
        invoicePostalCode: invoiceCustomer.postalCode || '',
        eInvoiceAddress: invoiceCustomer.eInvoiceAddress || '',
        eInvoiceIntermediateId: invoiceCustomer.eInvoiceIntermediateId || '',
        invoiceDeliveryEmail: invoiceCustomer.email || ''
    }
    if(billingInformation && billingInformation.fields) {
        if(billingInformation.fields.invoiceFirstNames) {
            output.invoiceFirstNames = billingInformation.fields.invoiceFirstNames;
        }
        if(billingInformation.fields.invoiceLastName) {
            output.invoiceLastName = billingInformation.fields.invoiceLastName;
        }
    }
    return output;
}

const setBillingInformationFields = (formSubmission, currentBillingInformation) => {
    let fields = {};

    if(!formSubmission.order) {
        return fields;
    }

    const { invoiceAdditionalDetails, invoiceCity, invoiceCompany, invoiceCompanyBusinessID, invoiceVatNumber, invoiceDeliveryEmail, invoiceFirstNames, invoiceLastName, invoicePayerIsCompany, invoicePostalCode, invoiceReference, invoiceStreetAddress, eInvoiceAddress, eInvoiceIntermediateId } = formSubmission.order;

    fields = {
        invoiceAdditionalDetails,
        invoiceCity,
        invoiceCompany,
        invoiceCompanyBusinessID,
        invoiceVatNumber,
        invoiceDeliveryEmail,
        invoiceFirstNames,
        invoiceLastName,
        invoicePayerIsCompany,
        invoicePostalCode,
        invoiceReference,
        invoiceStreetAddress,
        eInvoiceAddress,
        eInvoiceIntermediateId
    };

    return fields;
}

const setSelectedPaymentMethod = (formSubmission, paymentMethods) => {

    const { authToken, order } = formSubmission;

    if( authToken && (!order || (order && order.paymentMethod === 1)) ) {
        return 1;
    } else if(order && order.paymentMethod) {
        return order.paymentMethod;
    }
    else {
        return paymentMethods.default;
    }
}


/**
 * @name setInvoicePayerIsCompany
 * @desc Determine default invoicePayerIsCompany value.
 * @param {object} form
 */
const setInvoicePayerIsCompany = ({ supportedPaymentMethods, formID, eventID }) => {
    if(supportedPaymentMethods === 1) {
        return true;
    }
    // GPD 2021 tweak
    if(DEFAULT_PAYER_COMPANY.indexOf(eventID) > -1 || DEFAULT_PAYER_COMPANY.indexOf(formID) > -1) {
        return true;
    }
    // GPD 2021 tweak
    if(
        formID === '1c4fa088-d98e-11eb-a21a-ee6a04371b85' ||
        formID === '948be10f-d030-11eb-a21a-ee6a04371b85' ||
        formID === 'd0cf8193-d98f-11eb-a21a-ee6a04371b85' ||
        formID === '9cfaaea6-dd8a-11eb-a21a-ee6a04371b85' ||
        formID === 'a5bd299d-dd8a-11eb-a21a-ee6a04371b85' ||
        formID === 'afee0f64-dd8a-11eb-a21a-ee6a04371b85'
    ) {
        return true;
    }
    return false;
}