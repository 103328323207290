import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { withTranslation } from 'react-i18next';

function PaymentMethodSelect({ t, paymentMethods, paymentMethodProducts, onMethodChange, selectedMethod, defaultMethod, eventID, invoicePayerIsCompany }) {

    React.useEffect(
        () => {
            if(!selectedMethod) {
                onMethodChange(defaultMethod);
            }
        }, []
    )

    /**
     * Lääkäripäivät 2022 and Psykologiapäivät 2023 customization:
     *
     * Set payment method to Paytrail when payer is individual.
     */
    React.useEffect(() => {
        if((eventID === '02683374-407e-11ec-a018-ee6a04371b85' || eventID === 'cfe0dfdf-d295-11ec-a018-ee6a04371b85') && !invoicePayerIsCompany) {
            onMethodChange("2");
        }
    }, [eventID, invoicePayerIsCompany])

    const handleChange = event => {
        const paymentMethod = parseInt(event.target.value);
        onMethodChange(paymentMethod);
    }

    const isChecked = React.useCallback(value => {
        return parseInt(value) === parseInt(selectedMethod);
    }, [selectedMethod]);

    return (
        <fieldset className="row PaymentMethodSelectRow">

            <div className="col-12">
                <legend>{t('Payments.selectPaymentMethod')}</legend>
            </div>

            <div className="col-12 FieldsetContent">
                {paymentMethods && Object.keys(paymentMethods).length > 0
                ?
                    Object.values(paymentMethods)
                        .reverse()
                        .filter(paymentMethod => {
                            // Lääkäripäivät 2022 and Psykologiapäivät 2023 customization
                            // Don't show invoice payment method for individual payer
                            if(eventID === '02683374-407e-11ec-a018-ee6a04371b85' || eventID === 'cfe0dfdf-d295-11ec-a018-ee6a04371b85') {
                                if(!invoicePayerIsCompany && paymentMethod.id === 1) {
                                    return false;
                                }
                            }
                            return true;
                        })
                        .map(paymentMethod => (
                            <FormGroup check inline key={paymentMethod.id} className={`payment-method-form-group-${paymentMethod.name}`}>
                                <Label check>
                                    <Input
                                    type="radio"
                                    name="paymentMethod"
                                    value={paymentMethod.id}
                                    checked={isChecked(paymentMethod.id)}
                                    onChange={handleChange} />{' '}
                                        {t(paymentMethod.name)}
                                        {' '}
                                        {paymentMethodProducts[paymentMethod.id] ?
                                            <span>(
                                                {paymentMethodProducts[paymentMethod.id].map(product =>
                                                    <span key={product.productID}>
                                                        {' '}{product.name} +{product.totalPriceRounded + ' ' + product.currency}{' '}
                                                    </span>
                                                )}
                                            )</span>
                                        : null}

                                </Label>
                            </FormGroup>
                        ))
                : null}
            </div>

        </fieldset>
    )
}

PaymentMethodSelect.propTypes = {
    paymentMethods: PropTypes.object,
    selectedMethod: PropTypes.number,
    defaultMethod: PropTypes.number,
    onMethodChange: PropTypes.func,
    paymentMethodProducts: PropTypes.object,
    eventID: PropTypes.string,
    invoicePayerIsCompany: PropTypes.bool
};

export default withTranslation()(PaymentMethodSelect);