/**
 * Language
 */
export const languageId = (state) => state.language.languageId;

/**
 * Form
 */
export const form = (state) => state.form.form;
export const formID = (state) => state.form.form.formID;
export const fieldsById = state => state.form.fields.byId;
export const fieldsByPageId = state => state.form.fields.byPageId;

/**
 * Form pages
 */
export const pageCount = state => state.form.pageCount;
export const formPages = state => state.form.pages.allIds;
export const activeFormPageId = state => state.navigation.formPages.active;
export const lastFormPageId = state => state.form.pages.lastPageId;

/**
 * Navigation
 */
export const activeView = state => state.navigation.activeView;
export const canSkipSummary = state => state.navigation.canSkipSummary;

/**
 * Conditions
 */
export const conditions = state => state.conditions;
export const conditionBundlesByTarget = state => (state.conditions.bundles.byTarget);
export const conditionBundlesById = state => state.conditions.bundles.byId;
/**
 * Submission
 */
export const submissionIds = state => state.submission.submissionIds;
export const submissionFields = state => state.submission.fields;
export const formSubmission = state => state.form.formSubmission;
export const personID = state => state.submission.personID;
export const partnerID = state => state.submission.partnerID;

export const billingInformation = state => state.payment.billingInformation;
export const order = state => state.submission.order;
export const couponCode = state => state.submission.couponCode;
export const formSubmissionID = state => state.submission.formSubmissionID;
export const formSubmissionHash = state => state.submission.formSubmissionHash;
export const formSubmissionRevision = state => state.submission.formSubmissionRevision;
export const modifyingExistingSubmission = state => state.submission.modifyingExistingSubmission;
export const invoiceCustomers = state => state.payment.invoiceCustomers;
export const submissionValidationErrors = state => state.submission.validationErrors;
export const submissionResponse = state => state.submission.submissionResponse;
export const formUploadsByFieldId = state => state.submission.formUploads.byFieldId;
export const messagesSentOnlyToMainSubmitter = state => state.submission.messagesSentOnlyToMainSubmitter;
export const multiRegMessagesReceiverEmails = state => state.submission.multiRegMessagesReceiverEmails;
export const useManualConfirmationRecipients = state => state.submission.useManualConfirmationRecipients;
export const presentationsBySubmissionId = state => state.submission.presentationsBySubmissionId;
export const authToken = state => state.submission.authToken;
export const bundlePersonNumber =   state => state.submission.bundlePersonNumber;

export const submissionReservationID = state => state.submissionReservation.submissionReservationID;
export const submissionReservation = state => state.submissionReservation.data;
export const timeZone = state => state.submission.timeZone;

/**
 * Payments
 */
export const paymentMethod = state => state.payment.paymentMethods.selected;
export const paymentMethodDefault = state => state.payment.paymentMethods.default;
export const eInvoice = state => state.payment.eInvoice;
export const invoicePayerIsCompany = state => state.payment.billingInformation.invoicePayerIsCompany;
export const invoiceDeliveryMethod = state => state.payment.billingInformation.invoiceDeliveryMethod;
