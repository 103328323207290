import React from 'react';
import { PropTypes } from 'prop-types';

const FieldDescription = ({ description, languageId }) => (
    (description && description[languageId]) ? (
        <p className="text-muted field-description">
            {description[languageId]}
        </p>
    )
    : null
)

FieldDescription.propTypes = {
    description: PropTypes.object,
    languageId: PropTypes.number
};

export default FieldDescription;