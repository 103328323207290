import moment from 'moment';

import {
    FORM_FETCH,
    FORM_FETCH_FAIL,
    FORM_FETCH_SUCCESS,
    ALLOWED_FILE_EXTENSIONS_SET
} from './../actions/ActionTypes';

const initialState = {
    form: {},
    inline: false,
    pages: {
        byId: {},
        allIds: [],
        lastPageId: null,
        firstPageId: null
    },
    fields: {
        byId: {},
        byPageId: {}
    },
    formStatus: {
        enabled: null,
        closedReason: null,
        placesLeft: null
    },
    products: {
        baseProducts: [],
        byFormElementId: {}
    },
    loading: false,
    loadingError: null,
    isLoaded: false,
    errors: false,
    error: null,
    validationErrors: {},
    validationRules: [],
    participantRegistration: true,
    paymentMethod: 2,
    conditionsByFieldId: {},
    interests: {
        byID: {}
    },
    allowedFileExtensions: []
};

export default function (state = initialState, action) {
    switch(action.type) {
        case FORM_FETCH:
            return {
                ...state,
                isLoaded: false,
                loading: true
            };
        case FORM_FETCH_SUCCESS:
            return {
                ...state,
                form: action.form,
                inline: action.form.horizontal,
                pages: {
                    ...state.pages,
                    count: action.form.pages.length,
                    byId: pagesById(action.form.pages),
                    allIds: action.form.pages.map(page => page.formPageId),
                    firstPageId: action.form.pages[0].formPageId,
                    lastPageId: action.form.pages[action.form.pages.length-1].formPageId
                },
                fields: {
                    byId: fieldsById(action.form.pages),
                    byPageId: fieldsByPageId(action.form.pages)
                },
                products: {
                    baseProducts: action.form.products,
                    // byFormElementId: getProductsByFormElementId(action.form.pages)
                },
                interests: {
                    ...state.interests,
                    byId: action.form.interestSets ? interestsById(action.form.interestSets) : state.interestsById
                },
                formStatus: getFormStatus(action.form),
                isLoaded: true,
                loading: false,
                error: null,
                participantRegistration: hasParticipantRegistrationAction(action.form.actions),
                paymentMethod: action.form.supportedPaymentMethods === 1 ? 1 : 2,
                conditionsByFieldId: getConditionsByFieldId(action.form.formConditionBundles)
            };
        case FORM_FETCH_FAIL:
            return {
                ...state,
                form: action.form,
                loading: false,
                isLoaded: true,
                error: action.error,
                errors: action.error,
                loadingError: action.error
            };
        case ALLOWED_FILE_EXTENSIONS_SET:
            return {
                ...state,
                allowedFileExtensions: action.allowedFileExtensions
            }
        default:
            return state;
    }
};

/**
 * @TODO:
 *
 * Set products by formElementId so that the summary page can be skipped
 * if submission doesn't have products selected
 *
 * @param {array} pages
 *
const getProductsByFormElementId = pages => {
    return pages
        .map(page => page.elements)
        .reduce((prev, curr) => prev.concat(curr))
        .filter(formElement => {
            return formElement.options && formElement.options.length > 0 && formElement.products && formElement.products.length > 0
        })
        .reduce((prev, curr) => {
            prev[curr.formElementId] = curr.options.map(option => option.products);
            return prev;
        }, {});
}
*/

const getPlacesLeft = (submissionCount, submissionsMax) => {
    if(!submissionsMax) {
        return null;
    }
    return parseInt(submissionsMax) - parseInt(submissionCount);
}

const getFormStatus = form => {

    let result = {
        enabled: true,
        closedReason: null,
        placesLeft: getPlacesLeft(form.submissionCount, form.submissionsMax)
    };

    if (!form.enabled) {
        result.enabled = false;
        result.closedReason = 'formDisabled';
        return result;
    }

    // Check if there's places left
    if(result.placesLeft !== null && result.placesLeft < 1) {
        result.enabled = false;
        // Below is missing translations for 'eventIsFull' - error is also misleading.
        // result.closedReason = form.isRegistrationForm ? 'eventIsFull' : 'submissionsMaxReached';
        result.closedReason = 'submissionsMaxReached';
        return result;
    }

    // Check form base products
    if(form.products && form.products.length) {
        for (const key in form.products ){
            if(form.products[key].stock) {
                const stockValue = parseInt(form.products[key].stock);

                // Check that base product has stock
                if (stockValue === 0) {
                    result.enabled = false;
                    result.closedReason = 'baseProductStockLimitReached';
                }

                // If stock is lower than placesLeft, set placesLeft to stock amount
                if(!result.placesLeft || result.placesLeft > stockValue) {
                    result.placesLeft = stockValue;
                }
            }
        }
    }

    // Check when submit time starts & ends
    const submitStarted = isSubmitStarted(form.timeSubmitStarts);
    const submitEnded = isSubmitEnded(form.timeSubmitEnds);
    if (!submitStarted || submitEnded) {
        result.enabled = false;
        if(form.isRegistrationForm) {
            result.closedReason = !submitStarted ? 'registrationNotStarted' : 'registrationEnded';
        } else {
            result.closedReason = !submitStarted ? 'submitNotStarted' : 'submitEnded';
        }
        return result;
    }

    // Check if event quota is full
    if(form.isRegistrationForm && form.event.personLimitExceeded && !form.event.overbookingAllowed) {
        result.enabled = false;
        result.closedReason = 'eventIsFull';
        return result;
    }

    return result;
}

const isSubmitStarted = timeSubmitStarts => {
    if(timeSubmitStarts) {
        const now = moment().local();
        const signUpStarts = moment.utc(timeSubmitStarts);
        const localSignUpStarts = moment(signUpStarts).local();
        return !now.isBefore(localSignUpStarts);
    }
    return true;
}

const isSubmitEnded = timeSubmitEnds => {
    if(timeSubmitEnds) {
        const now = moment().local();
        const signUpEnds = moment.utc(timeSubmitEnds);
        const localSignUpEnds = moment(signUpEnds).local();
        return now.isSameOrAfter(localSignUpEnds);
    }
    return false;
}

const fieldsById = (pages) => {
    let fieldsById = {};
    for ( let i = 0; i < pages.length; i++ ) {
        pages[i].elements.forEach(field => {
            const defaults = {
                required: field.validation.required ? true : false,
                hidden: parseInt(field.hidden) === 1 ? true : false
            };
            let fieldName = field.name;
            fieldsById[field.formElementId] = {
                ...field,
                page: i,
                isDirty: false,
                isFocused: false,
                displayName: fieldName,
                errors: [],
                defaults: {
                    ...defaults
                }
            }
        });
    }
    return fieldsById;
}

const pagesById = pages => {
    return pages.reduce((obj, item) => {
        obj[item.formPageId] = item;
        return obj;
    }, {})
};

const fieldsByPageId = pages => {
    return pages.reduce((pageObject, page) => {
        pageObject[page.formPageId] = page.elements;
        return pageObject;
    }, {});
};

const getConditionsByFieldId = formConditionBundles => {
    let result = {};
    formConditionBundles.forEach(bundle => {
        bundle.formConditions.forEach(condition => {
            if(typeof result[condition.targetId] === 'undefined' ) {
                result[condition.targetId] = [condition];
            } else {
                result[condition.targetId].push(condition);
            }
        });
    });
    return result;
}

const hasParticipantRegistrationAction = actions => {
    for (let i = 0; i < actions.length; i++) {
        if(actions[i].actionId === 1) {
            return true;
        }
    }
    return false;
};

const interestsById = interestSets => {
    let interests = [];
    for (const interestSet of interestSets) {
        interests = interests.concat(interestSet.interests);
    }
    return interests.reduce((byId, interest) => {
        byId[interest.interestID] = {
            interestID: interest.interestID,
            parentInterestID: interest.parentInterestID,
            name: interest.name,
            order: interest.order,
            isSelectable: interest.isSelectable,
            type: interest.type
        };
        return byId;
    }, {});
}