import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { FaClock } from 'react-icons/fa';

function ReservationIndicator(props) {

    const { t } = useTranslation();

    const [ minutesLeft, setMinutesLeft ] = useState(null);
    const [ secondsLeft, setSecondsLeft ] = useState(null);

    const { submissionReservation, onReservationExpiration } = props;
    const { submissionReservationID, timeExpiration } = submissionReservation;
    const reservationHasExpired = submissionReservation.status === 1;

    useEffect(
        () => {

            const expires = moment(timeExpiration);

            let timer = setInterval(() => {

                let now = moment();

                // Calculate values
                let newMinutesLeft = Math.floor(moment.duration(expires - now).asMinutes());
                let newSecondsLeft = Math.floor(moment.duration(expires - now).asSeconds() - (newMinutesLeft*60));

                if(newMinutesLeft < 1 ) {
                    newMinutesLeft = 0;
                }

                // Appends leading zero to seconds below 10
                if(newSecondsLeft < 10) {
                    newSecondsLeft = '0'+newSecondsLeft;
                }

                setMinutesLeft(newMinutesLeft);
                setSecondsLeft(newSecondsLeft);

                if(newMinutesLeft <= 0 && newSecondsLeft <= 0) {
                    clearInterval(timer);
                    onReservationExpiration({...submissionReservation, status: 1});
                }

            }, 1000); // 1 second interval

            // cleanup function
            return () => clearInterval(timer);

        }, [timeExpiration]
    )

    return (
        <div className="ReservationIndicator">
            <p>
                {submissionReservationID ?

                    !reservationHasExpired ?
                        <React.Fragment>
                            <FaClock style={{marginRight: '6px', position: 'relative', top: '-2px'}} /> {t('SubmissionReservation.timeLeft', {minutesLeft: minutesLeft, secondsLeft: secondsLeft})}.
                        </React.Fragment>
                        :
                        <div className="alert alert-danger">{t('SubmissionReservation.timeExpired')}.</div>
                : null
                }
            </p>
        </div>
    )
}

ReservationIndicator.propTypes = {
    submissionReservation: PropTypes.object,
    onReservationExpiration: PropTypes.func
};

export default ReservationIndicator;