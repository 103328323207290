import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

const ProgressBar = ({ t, activeView = 'form', activePageId, pageIds, showSummaryPage }) => {

    // Step count is form pages + summary + success/failure page
    const staticPageCount = showSummaryPage ? 2 : 1;
    const stepCount = pageIds.length + staticPageCount;

    // Set current step
    let stepNow = 1;
    switch (activeView) {
        case 'summary':
            stepNow = stepCount -1;
            break;
        case 'success':
            stepNow = stepCount;
            break;
        default:
            stepNow = pageIds.indexOf(activePageId) + 1;
            break;
    }

    // Calculate bar width
    const barWidth = Math.floor((stepNow / stepCount * 100).toFixed(2));
    const barWidthRounded = Math.round(barWidth);

    // Set bar styles
    const barStyle = {
        width: barWidth + '%',
        background: '#CCC'
    };

    return (
        <div style={{marginTop: '30px'}} id="FormProgressBar">
            <p className="text-center">
                {barWidthRounded}% {t('ready')}
            </p>
            <div className="progress" style={{marginTop: '10px', height: '8px'}}>
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={barStyle}
                    aria-valuenow={stepNow}
                    aria-valuemin="1"
                    aria-valuemax={stepCount}>
                </div>
            </div>
        </div>
    )
}

ProgressBar.propTypes = {
    activeView: PropTypes.string.isRequired,
    activePageId: PropTypes.number,
    pageIds: PropTypes.array
}

export default withTranslation()(ProgressBar);