import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { formFetch, languageChange, setAlerts, resetAlerts, setErrors, setActiveView } from '../actions';

import FormContainer from './FormContainer';
import SummaryContainer from './SummaryContainer';
import SuccessContainer from './SuccessContainer';

import Alerts from '../components/Alerts/Alerts';
import LanguageSwitcher from '../components/LanguageSwitcher/LanguageSwitcher';
import LoadingIndicator from '../components/LoadingIndicator/LoadingIndicator';
import Steps from '../components/Steps/Steps';
import ProgressBar from '../components/ProgressBar/ProgressBar';
import ParticipantList from '../components/ParticipantList/ParticipantList';
import ParticipantCount from '../components/ParticipantCount/ParticipantCount';
import ReservationIndicator from './../components/ReservationIndicator/ReservationIndicator';
import ReservationFullView from './../components/ReservationFullView/ReservationFullView';

import './App.scss';

class App extends Component {

	componentDidMount() {

		const { config } = this.props;

		/**
		 * If Paytrail payment has failed, reload page without fs & h parameters.
		 * This is to ensure that following payment will not fail.
		 */
		if( config.queryParams.paytrailPaymentResult && config.queryParams.paytrailPaymentResult === 'false' ) {

			if(config.queryParams.fs) {

				// Base url
				let refreshURL = window.location.protocol + '//' + window.location.host + window.location.pathname + '?';
				// Remove fs & h parameters
				delete config.queryParams.fs;
				delete config.queryParams.h;
				// Add parameters to base url
				let parameters = Object.entries(config.queryParams);
				for( const [key, value] of parameters ) {
					refreshURL += key+"="+value+"&";
				}
				// Remove trailing &
				if(parameters.length > 0) {
					refreshURL = refreshURL.slice(0, -1);
				}
				// Refresh
				window.location = refreshURL;
			}
			// If there's only paytrailPaymentResult=false in url, show error
			else {
				this.props.onSetErrors([{details: 'Payments.paymentCancelledOrFailed.details', title: 'Payments.paymentCancelledOrFailed.title'}]);
			}
		}

		this.props.onFetchForm(
			config.formID,
			{
				...config.queryParams,
				personID: config.personID || null,
				env: config.env || null
			}
		);
	}


	inactiveMessage() {
        const { t, formStatus } = this.props;
        if(formStatus.closedReason) {
            return t('App.'+formStatus.closedReason);
        }
        return t('App.formDisabled');
    }

	render() {

		const {
			t,
			activeView,
			activePageId,
			languages,
			languageId,
			form,
			formStatus,
			order,
			loadingSubmission,
			formIsLoaded,
			modifyingExistingSubmission,
			submissionReservationExpired
		} = this.props;

		const showProgress = (formStatus.enabled || modifyingExistingSubmission) && (activeView !== 'reservationFull' && activeView !== 'cancelled');

		const currentView = () => {
			switch(activeView) {
				case 'success':
					return <SuccessContainer
						form={form}
						languageId={languageId}
						order={order}
						paytrailPaymentResult={this.props.config.queryParams.paytrailPaymentResult}
					/>;
				case 'summary':
					return <SummaryContainer form={form} languageId={languageId} submissionReservationExpired={submissionReservationExpired} />;
				case 'reservationFull':
					return <ReservationFullView />;
				case 'cancelled':
					return (
						<div className="FormClosedWrapper">
							{t('App.formSubmissionCancelled')}
						</div>
					)
				default:
					return (formStatus.enabled || modifyingExistingSubmission)
						?
							<FormContainer form={form} languageId={languageId} submissionReservationExpired={submissionReservationExpired} />
						:
							<div className="FormClosedWrapper">
								{formStatus.closedReason ?
									t('App.'+formStatus.closedReason)
								:
									t('App.formDisabled')
								}
							</div>
			}
		}

		return (
			<div
				id="EventosFormWidget"
				className={`container language-id-${languageId} eventos-form-id-${typeof form !== 'undefined' ? form.formID : 'undefined'}`}
			>

				<div id="EventosFormAlerts">
					<Alerts
						type="errors"
						errors={this.props.errors}
						visibile={this.props.alertsVisible}
						onDismiss={this.props.onResetAlerts}
					/>
				</div>
				<div>
					{(formIsLoaded && form && formStatus && !loadingSubmission) &&
						<div>

							<ParticipantCount
								visible={form.showParticipantCount}
								count={this.props.participantList.personsRegistered}
								max={this.props.participantList.personsMax}
							/>

							<LanguageSwitcher
								languages={languages}
								languageId={languageId}
								onChangeLanguage={this.props.onLanguageChange}
							/>

							{(form.event.overbookingAllowed && form.event.personLimitExceeded && !modifyingExistingSubmission && (activeView === 'form' || activeView === 'summary')) ?
								<div className="alert alert-warning">
									{t('App.overbookingAllowed')}
								</div>
							: null}

							{(formStatus.enabled && form.reservationsEnabled && this.props.submissionReservation && !modifyingExistingSubmission && (activeView === 'form' || activeView === 'summary')) ?
								<ReservationIndicator
									submissionReservation={this.props.submissionReservation}
									onReservationExpiration={this.props.onReservationExpiration}
								/>
							: null}

							{showProgress ?
								<Steps
									t={t}
									formPages={this.props.formPages}
									languageId={languageId}
									activeView={activeView}
									activePageId={activePageId}
									modifyingExistingSubmission={this.props.modifyingExistingSubmission}
									showSummaryPage={form.showSummaryPage}
								/>
							: null}

							{(modifyingExistingSubmission && !form.allowSubmissionUpdate && !this.props.authToken && activeView !== 'cancelled') ?
								<div className="alert alert-warning">
									{t('App.submissionModificationNotAllowed')}
								</div>
							: null}

							{currentView()}

							{showProgress ?
								<React.Fragment>
									<ProgressBar
										activeView={activeView}
										activePageId={activePageId}
										pageIds={this.props.formPages.allIds}
										showSummaryPage={form.showSummaryPage}
									/>
									<a href="https://eventos.fi" rel="noopener noreferrer" target="_blank" style={{fontSize: '12px', display: 'inline-block', marginTop: '20px', color: 'rgba(0,0,0,0.6)', textAlign: 'right'}}>Powered by Eventos</a>
								</React.Fragment>
							: null}

							<ParticipantList
								title={t('App.participants')}
								visible={form.showParticipantList}
								participants={this.props.participantList.participantsAllowedToShow}
								personsRegistered={this.props.participantList.personsRegistered}
							/>

							<span id="person-id-wrapper" data-personid={this.props.personID}></span>

						</div>
					}
					<div>
						<LoadingIndicator {...this.props.loading} />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		form: state.form.form,
		formStatus: state.form.formStatus,
		formPages: state.form.pages,
		formSubmissionID: state.submission.formSubmissionID,
		formSubmissionHash: state.submission.formSubmissionHash,
		errors: state.app.errors,
		formIsLoaded: state.form.isLoaded,
		loadingError: state.form.loadingError,
		languages: state.language.languages,
		languageId: state.language.languageId,
		activeView: state.navigation.activeView,
		activePageId: state.navigation.formPages.active,
		alerts: state.app.alerts,
		alertsVisible: state.app.alertsVisible,
		modifyingExistingSubmission: state.submission.modifyingExistingSubmission,
		loadingSubmission: state.submission.loading,
		isLoading: state.app.loading.status,
		loading: state.app.loading,
		order: state.submission.order,
		participantList: state.participantList,
		submissionReservation: state.submissionReservation.data,
		submissionReservationExpired: (state.submissionReservation.data && state.submissionReservation.data.status === 1),
		authToken: state.submission.authToken,
		personID: state.submission.personID
	}
};

const mapDispatchToProps = dispatch => {
	return {
		onFetchForm: (formID, parameters) => {
			return dispatch(formFetch(formID, parameters));
		},
		onLanguageChange: (languageId) =>  {
			return dispatch(languageChange(languageId));
		},
		onSetAlerts: (alerts) => {
			return dispatch(setAlerts(alerts));
		},
		onResetAlerts: () => {
			return dispatch(resetAlerts());
		},
		onSetErrors: (errors) => {
			return dispatch(setErrors(errors));
		},
		onSetActiveView: (view) => {
			return dispatch(setActiveView(view))
		},
		onReservationExpiration: (payload) => {
			dispatch({
				type: 'RESERVATION_SAVE',
				payload
			});
		},
		onStateReset: () => {
			dispatch({
				type: 'RESET_STORE'
			})
		}
	}
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(App));