import validator from 'validator';


/**
 * Validate formConditionBundle by checking all conditions and if they match.
 *
 * @param {object} formConditionBundle
 * @param {object} submissionFields
 * @param {object} formFields
 * @return {boolean}
 */
export const validateConditionBundle = (formConditionBundle, submissionFields, formFields) => {

    let conditionMatchCount = 0;

    // Loop through bundle's conditions and validate them
    formConditionBundle.formConditions.forEach(formCondition => {

        if(submissionFields[formCondition.targetId]) {
            let targetElement = {
                type: formFields[formCondition.targetId].type,
                ...submissionFields[formCondition.targetId]
            };

            if(validateCondition(targetElement, formCondition, submissionFields[formCondition.expectedId])) {

                conditionMatchCount++;

                // If ANY of the conditions is sufficient,
                // break from loop
                if(formConditionBundle.rulesToMatch === 1) {
                    return;
                }
            }
        }
    });

    // ALL conditions match
    if (formConditionBundle.rulesToMatch === 0) {
        return (conditionMatchCount === formConditionBundle.formConditions.length);
    }

    // ANY of the conditions match
    return conditionMatchCount > 0;
}

/**
 * Validate single condition, by checking if the
 * target element meets the expected condition/value.
 *
 * @param {object} targetElement
 * @param {object} formCondition
 * @param {object|undefined} expectedElement
 * @return {boolean}
 */
export const validateCondition = (targetElement, formCondition, expectedElement) => {

    /*
    if(targetElement.hidden) {
        return true;
    }
    */

    /**
     * Check the condition type and status
     *
     * 0: Filled
     * 1: Not filled
     * 2: Equals
     * 3: Not equals
     * 4: Contains
     * 5: Not contains
     */
    switch(formCondition.condition) {
        case 0:
            if(targetElement.type === 'checkbox') {
                return targetElement.value.length > 0;
            } else if(targetElement.type === 'file') {
                return targetElement.value.length > 0;
            } else {
                return typeof targetElement.value !== 'undefined' && targetElement.value !== null && !validator.isEmpty(targetElement.value) && targetElement.value.length > 0;
            }
        case 1:
            if(targetElement.type === 'checkbox') {
                return targetElement.value.length === 0;
            } else if(targetElement.type === 'file') {
                return targetElement.value.length === 0;
            }  else {
                return typeof targetElement.value === 'undefined' || validator.isEmpty(targetElement.value);
            }
        case 2:
            return equalsValue(targetElement, formCondition, expectedElement);
        case 3:
            return !equalsValue(targetElement, formCondition, expectedElement);
        case 4:
            return containsValue(targetElement, formCondition);
        case 5:
            return !containsValue(targetElement, formCondition);
        default:
            return false;
    }
}

/**
 * Check if the element value contains expected value.
 *
 * @param {object} targetElement
 * @param {object} formCondition
 * @param {boolean}
 */
export const containsValue = (targetElement, formCondition, expectedElement) => {

    if(Array.isArray(targetElement.value) && targetElement.value.length === 0) {
        return false;
    }

    if (formCondition.expectedValue !== null) {
        if( validator.contains(targetElement.value, formCondition.expectedValue) || validator.equals(targetElement.value, formCondition.expectedValue) ) {
            return true;
        }
    } else if(formCondition.expectedId !== null) {
        if(targetElement.value.indexOf(""+formCondition.expectedId) !== -1 || targetElement.value.indexOf(formCondition.expectedId) !== -1) {
            return true;
        }
    }
    /* else if (formCondition.expectedValue === null) {
        return typeof targetElement.value !== 'undefined' && !validator.isEmpty(targetElement.value) && targetElement.value.length;
    }*/

    return false;
}

/**
 * Check if the element value equals expected value.
 *
 * @param {object} targetElement
 * @param {object} formCondition
 * @param {boolean}
 */
export const equalsValue = (targetElement, formCondition, expectedElement) => {

    if (formCondition.expectedValue !== null) {
        if ( validator.equals(targetElement.value, formCondition.expectedValue) ) {
            return true;
        }
    } else if(formCondition.expectedId !== null) {
        if ( Array.isArray(targetElement.value) && (targetElement.value.indexOf(""+formCondition.expectedId) > 0 || targetElement.value.indexOf(formCondition.expectedId) > 0 ) ) {
            return true;
        } else {
            if ( validator.equals(""+targetElement.value, ""+formCondition.expectedId) ) {
                return true;
            } else if ( expectedElement && expectedElement.value && validator.equals(expectedElement.value, targetElement.value)) {
                return true;
            }
        }
    }
    return false;
}
