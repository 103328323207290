import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider}  from 'react-redux';
// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import * as parseUri from 'parse-uri';

import reducer from './reducers'
import rootSaga from './sagas'

import './i18n';
import App from './containers/App';
import Alerts from './components/Alerts/Alerts';

const composeEnhancers = typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : compose;


// const loggerMiddleware = createLogger();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    reducer,
    composeEnhancers(
		applyMiddleware(
			// loggerMiddleware,
			sagaMiddleware
		)
	)
);

sagaMiddleware.run(rootSaga);

export const initEventosForm = (config) => {

    /**
     * Extend config object with URI query params which may hold
     * formSubmissionID, formSubmissionHash, personID, partnerID etc.
     */
    const parsedURI = parseUri(window.location.href);
    const parsedURIKeys = (parsedURI.queryKey && Object.keys(parsedURI.queryKey).length > 0) ? parsedURI.queryKey : {};
    config = {
        ...config,
        parentElement: (config && config.parentElementID) || 'eventos-form-widget',
        queryParams: parsedURIKeys,
        debug:  ((config && parsedURIKeys) && (config.debug || parsedURIKeys.debug)) || false
    };

    /**
     * For testing purposes, widget can be tested in development env:
     * http://localhost:3009?formID={formID}
     */
    if(config.queryParams.formID) {
        config.formID = config.queryParams.formID;
    }

    if(config.languageId) {
        config.queryParams.lang = config.languageId;
    }

    if(config.debug) {
        console.log(config);
    }

    if(config.queryParams.personID) {
        config.personID = config.queryParams.personID;
    }


    if ( typeof config.formID === 'string' ) {
        ReactDOM.unmountComponentAtNode(document.getElementById(config.parentElement));
        ReactDOM.render(
            <Provider store={store}>
               <App config={config} />
            </Provider>,
            document.getElementById(config.parentElement)
        );
    } else {
        ReactDOM.render(
            <div className="invalid-configuration container">
                <Alerts
                    type="errors"
                    errors={[{title: 'App.invalidConfig.title', details: 'App.invalidConfig.details'}]}
                    visibile={true}
                />
            </div>,
            document.getElementById(config.parentElement)
        );
    }
}


/**
 * Widget is initialized automatically in local and development environment.
 *
 * In production it has to be initialized manually:
 * eventos.initEventosForm({formID: '9179db93-126b-11e9-899f-ee6a04371b85'});
 */
if(process.env.NODE_ENV === 'local' || process.env.NODE_ENV === 'development') {
    initEventosForm();
}