import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import validator from 'validator';
import { FormGroup, Label, Input } from 'reactstrap';

const ConfirmationEmailMethod = (props) => {

    const { t } = useTranslation();

    const {
        multiRegMessagesReceiverEmails,
        setUseManualEmailRecipients,
        onChange,
        forceMainSubmitter,
        primaryEmail,
        useManualConfirmationRecipients
    } = props;

    const { value, valid, isValidated } = multiRegMessagesReceiverEmails;

    useEffect(
        () => {
            if(forceMainSubmitter) {
                setUseManualEmailRecipients(true)
                onChange(primaryEmail, true, true)
            }
        }, [primaryEmail]
    )

    const toggleManualRecipients = function(event) {
        const newValue = !useManualConfirmationRecipients;
        setUseManualEmailRecipients(newValue)
    }

    const handleEmailChange = event => {
        const valid = validator.isEmail(event.target.value);
        onChange(event.target.value, valid, true);
    }

    return (
        <div id="ConfirmationEmailRecipients">
            <FormGroup check>
                <Label check>
                    <Input
                        type="checkbox"
                        value={useManualConfirmationRecipients}
                        defaultChecked={(useManualConfirmationRecipients || forceMainSubmitter)}
                        onChange={toggleManualRecipients}
                        disabled={forceMainSubmitter}
                    />{' '}
                    {t('App.setMultiRegMessagesReceiverEmails')}
                </Label>
            </FormGroup>
            <FormGroup style={{marginTop: '10px', display: (useManualConfirmationRecipients || forceMainSubmitter) ? 'block' : 'none' }}>
                <Input
                    type="email"
                    name="messagesSentOnlyToMainSubmitter"
                    valid={valid && isValidated}
                    invalid={!valid && isValidated}
                    placeholder="example@email.com"
                    style={{maxWidth: '600px'}}
                    onChange={handleEmailChange}
                    defaultValue={value}
                    disabled={false}
                />
            </FormGroup>
        </div>
    )
}

ConfirmationEmailMethod.propTypes = {
    multiRegMessagesReceiverEmails: PropTypes.object,
    onChange: PropTypes.func,
    messagesSentOnlyToMainSubmitter: PropTypes.bool,
    mainEmail: PropTypes.string
};

export default ConfirmationEmailMethod;