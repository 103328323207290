import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { updateBillingInformationData } from './../../actions';

function BusinessDetails({ defaultBillingFieldValues, eventID }) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { fields, validation, isValidated, valid, invoicePayerIsCompany } = useSelector(state => state.payment.billingInformation);
    const { invoiceCompany, invoiceCompanyBusinessID, invoiceVatNumber, invoiceReference, invoiceAdditionalDetails, invoiceStreetAddress, invoiceCity, invoicePostalCode } = fields;
    const { languageId } = useSelector(state => state.language);


    const _isBusinessIdRequired = React.useMemo(() => {
        if(
            eventID === '8b4b0c8a-d030-11eb-a21a-ee6a04371b85'
            || eventID === '2c38c9ce-405f-11ec-89e2-080027a0fbc5'
        ) {
            if(languageId !== 1) {
                return false;
            }
        }
        return invoicePayerIsCompany;
    }, [invoicePayerIsCompany, eventID, languageId]);

    const _isVatNumberRequired = React.useMemo(() => {
        if(
            eventID === '8b4b0c8a-d030-11eb-a21a-ee6a04371b85'
            || eventID === '2c38c9ce-405f-11ec-89e2-080027a0fbc5'
        ) {
            if(languageId !== 1) {
                return true;
            }
        }
        return false;
    }, [invoicePayerIsCompany, eventID, languageId]);



    /**
     * When component is mounted,
     * set default values for invoiceCompany && invoiceCompanyBusinessID.
     */
    useEffect(() => {
        if(defaultBillingFieldValues.invoiceCompany && !invoiceCompany) {
            dispatch(updateBillingInformationData('invoiceCompany', defaultBillingFieldValues.invoiceCompany));
        }
        if(defaultBillingFieldValues.invoiceCompanyBusinessID && !invoiceCompanyBusinessID) {
            dispatch(updateBillingInformationData('invoiceCompanyBusinessID', defaultBillingFieldValues.invoiceCompanyBusinessID));
        }
        if(defaultBillingFieldValues.invoiceStreetAddress && !invoiceStreetAddress) {
            dispatch(updateBillingInformationData('invoiceStreetAddress', defaultBillingFieldValues.invoiceStreetAddress));
        }
        if(defaultBillingFieldValues.invoiceCity && !invoiceCity) {
            dispatch(updateBillingInformationData('invoiceCity', defaultBillingFieldValues.invoiceCity));
        }
        if(defaultBillingFieldValues.invoicePostalCode && !invoicePostalCode) {
            dispatch(updateBillingInformationData('invoicePostalCode', defaultBillingFieldValues.invoicePostalCode));
        }
    }, [])

    /**
     * @name _handleInputChange
     * @desc Event handler for input change.
     * @param {object} event
     */
    function _handleInputChange(event) {
        const { name, value } = event.target;
        dispatch(updateBillingInformationData(name, value));
    }

    return(
        <React.Fragment>
            <fieldset className={invoicePayerIsCompany ? 'PayerCompany' : 'PayerIndividual'}>
                <legend>{t('BillingInformation.billingInformation')}</legend>
                {isValidated && valid === false && Object.keys(validation).length > 0 ? (
                    <Alert color="danger">
                        {t('BillingInformation.pleaseCheckErrors')}.
                    </Alert>
                ) : null}
                <Row>
                    <Col md={6}>
                        <FormGroup className="required">
                            <Label for="invoiceCompany">{t('Company')}</Label>
                            <Input
                                type="text"
                                name={'invoiceCompany'}
                                id={'invoiceCompany'}
                                defaultValue={invoiceCompany || (defaultBillingFieldValues.invoiceCompany || null)}
                                onChange={_handleInputChange}
                                valid={validation['invoiceCompany'] && validation['invoiceCompany'].valid}
                                invalid={validation['invoiceCompany'] && validation['invoiceCompany'].valid === false}
                                required={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className={_isBusinessIdRequired ? 'required' : ''}>
                            <Label for="invoiceCompanyBusinessID">{t('Business ID')}</Label>
                            <Input
                                type="text"
                                name={'invoiceCompanyBusinessID'}
                                id={'invoiceCompanyBusinessID'}
                                defaultValue={invoiceCompanyBusinessID ||(defaultBillingFieldValues.invoiceCompanyBusinessID || null)}
                                valid={validation['invoiceCompanyBusinessID'] && validation['invoiceCompanyBusinessID'].valid}
                                invalid={validation['invoiceCompanyBusinessID'] && validation['invoiceCompanyBusinessID'].valid === false}
                                onChange={_handleInputChange}
                                required={_isBusinessIdRequired}
                            />
                        </FormGroup>
                    </Col>
                    {languageId !== 1 ?
                        <Col md={6}>
                            <FormGroup className={_isVatNumberRequired ? 'required' : ''}>
                                <Label for="invoiceVatNumber">{t('VAT Number')}</Label>
                                <Input
                                    type="text"
                                    name={'invoiceVatNumber'}
                                    id={'invoiceVatNumber'}
                                    defaultValue={invoiceVatNumber || (defaultBillingFieldValues.invoiceVatNumber || null)}
                                    valid={validation['invoiceVatNumber'] && validation['invoiceVatNumber'].valid}
                                    invalid={validation['invoiceVatNumber'] && validation['invoiceVatNumber'].valid === false}
                                    onChange={_handleInputChange}
                                    required={_isVatNumberRequired}
                                />
                            </FormGroup>
                        </Col>
                    : null}
                     <Col md={6} key={'invoiceReference'}>
                        <FormGroup>
                            <Label for="invoiceReference">{t('BillingInformation.invoiceReference')}</Label>
                            <Input
                                name="invoiceReference"
                                id="invoiceReference"
                                defaultValue={invoiceReference || null}
                                onChange={_handleInputChange}
                                valid={validation['invoiceReference'] && validation['invoiceReference'].valid}
                                invalid={validation['invoiceReference'] && validation['invoiceReference'].valid === false}
                                required={false}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} key={'invoiceAdditionalDetails'}>
                        <FormGroup>
                            <Label for="invoiceAdditionalDetails">{t('BillingInformation.invoiceAdditionalDetails')}</Label>
                            <Input
                                name="invoiceAdditionalDetails"
                                id="invoiceAdditionalDetails"
                                defaultValue={invoiceAdditionalDetails || null}
                                onChange={_handleInputChange}
                                valid={validation['invoiceAdditionalDetails'] && validation['invoiceAdditionalDetails'].valid}
                                invalid={validation['invoiceAdditionalDetails'] && validation['invoiceAdditionalDetails'].valid === false}
                                required={false}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={6} key={'invoiceStreetAddress'}>
                        <FormGroup className="required">
                            <Label for="invoiceStreetAddress">{t('BillingInformation.invoiceStreetAddress')}</Label>
                            <Input
                                type="text"
                                name={'invoiceStreetAddress'}
                                id={'invoiceStreetAddress'}
                                defaultValue={invoiceStreetAddress || (defaultBillingFieldValues.invoiceStreetAddress || null)}
                                valid={validation['invoiceStreetAddress'] && validation['invoiceStreetAddress'].valid}
                                invalid={validation['invoiceStreetAddress'] && validation['invoiceStreetAddress'].valid === false}
                                onChange={_handleInputChange}
                                required={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} key={'invoiceCity'}>
                        <FormGroup className="required">
                            <Label for="invoiceCity">{t('BillingInformation.invoiceCity')}</Label>
                            <Input
                                type="text"
                                name={'invoiceCity'}
                                id={'invoiceCity'}
                                defaultValue={invoiceCity || (defaultBillingFieldValues.invoiceCity || null)}
                                valid={validation['invoiceCity'] && validation['invoiceCity'].valid}
                                invalid={validation['invoiceCity'] && validation['invoiceCity'].valid === false}
                                onChange={_handleInputChange}
                                required={true}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6} key={'invoicePostalCode'}>
                        <FormGroup className="required">
                            <Label for="invoicePostalCode">{t('BillingInformation.invoicePostalCode')}</Label>
                            <Input
                                type="text"
                                name={'invoicePostalCode'}
                                id={'invoicePostalCode'}
                                defaultValue={invoicePostalCode || (defaultBillingFieldValues.invoicePostalCode || null)}
                                valid={validation['invoicePostalCode'] && validation['invoicePostalCode'].valid}
                                invalid={validation['invoicePostalCode'] && validation['invoicePostalCode'].valid === false}
                                onChange={_handleInputChange}
                                required={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </fieldset>
        </React.Fragment>
    )
}

export default BusinessDetails;