import {
    PARTICIPANT_LIST_FETCH,
    PARTICIPANT_LIST_FETCH_SUCCESS,
    PARTICIPANT_LIST_FETCH_FAIL
 } from './ActionTypes';

 export const participantListFetch = form => {
    return {
        type: PARTICIPANT_LIST_FETCH,
        form
    };
};

export const participantListFetchSuccess = participants => {
    return {
        type: PARTICIPANT_LIST_FETCH_SUCCESS,
        payload: participants
    };
};

export const participantListFetchFail = errors => {
    return {
        type: PARTICIPANT_LIST_FETCH_FAIL,
        errors
    };
};