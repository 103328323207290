import { put } from "redux-saga/effects";
import api from '../api';

import {
    participantListFetchSuccess,
    participantListFetchFail
} from '../actions';

export function* participantListFetchSaga(action) {
    try {
        const { formID } = yield action.form;
        const response = yield api.get('public/forms/' + formID + '/eventParticipants');
        if(response && response.data && response.data.data && response.data.data.event ) {
            yield put(participantListFetchSuccess(response.data.data.event));
        } else {
            let error = {
                title: '',
                details: ''
            };
            throw error;
        }
    } catch (error) {
        yield put(participantListFetchFail());
        console.error(error);
    }
}
