import {
    SET_LANGUAGES,
    LANGUAGE_CHANGE
} from './../actions/ActionTypes';

const initialState = {
    languages: [],
    byId: {},
    languageId: null,
    langCode: 'fi'
};

export const getLanguageCodeByLanguageId = (languages, languageId) => {
    languages.forEach(language => {
        if ( language.languageId === languageId ) {
            return language.langCode;
        }
    });
}

export default function (state = initialState, action) {
    switch(action.type) {
        case SET_LANGUAGES:
            return {
                ...state,
                languages: action.languages,
                byId: action.languages.reduce((languageObj, language) => {
                    languageObj[language.languageId] = language;
                    return languageObj;
                }, {})
            }
        case LANGUAGE_CHANGE:
            return {
                ...state,
                languageId: action.languageId,
                langCode: state.byId[action.languageId]
            }
        default:
            return state;
    }
};

