import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Files from 'react-files';
import { withTranslation } from 'react-i18next';

import FileFieldQueueTable from './FileFieldQueueTable';

function FileField(props) {

    const { t, formElement, submissionId, allowedFileExtensions } = props;
    const [ fileErrors, setFileErrors ] = useState(null);
    const files = useRef(null);

    function _onFilesChange(files)  {
        const { formElementId, isValidated } = formElement;
        props.handleInputChange(submissionId, formElementId, files, isValidated);
    }

    function _onFilesError(error) {
        setFileErrors(error.message);
    }

    function _onFilesRemoveOne(file) {
        files.current.removeFile(file);
        _onClearFileErrors();
    }

    function _onClearFileErrors() {
        setFileErrors(null);
    }

    return (
        <div>
            <Files
                ref={files}
                className='files-dropzone'
                onChange={_onFilesChange}
                onError={_onFilesError}
                multiple={formElement.fileAllowMultiple}
                maxFileSize={20000000}
                minFileSize={0}
                accepts={[...allowedFileExtensions, '.zip']}
                clickable
            >
                <span>
                    {t('Drop files to upload')}  {t('or')} <span onClick={_onClearFileErrors}>{t('select file')}</span>
                </span>
            </Files>

            <FileFieldQueueTable
                t={t}
                files={formElement.value}
                onRemove={_onFilesRemoveOne}
                uploads={props.uploads}
                multiple={formElement.fileAllowMultiple}
            />

            {fileErrors ?
                <div className="text-danger" style={{marginTop: '10px', display: 'block'}}>
                    {t(fileErrors)}
                </div>
            : null}

        </div>
    )
}

FileField.propTypes = {
    formElement: PropTypes.object.isRequired,
    submissionId: PropTypes.string.isRequired,
    allowedFileExtensions: PropTypes.array.isRequired,
}

export default withTranslation('translation')(FileField);