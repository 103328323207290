import {
    BILLING_INFORMATION_SET,
    BILLING_INFORMATION_VALID,
    BILLING_INFORMATION_INVALID,
    FETCH_INVOICE_CUSTOMERS,
    FETCH_INVOICE_CUSTOMERS_SUCCESS,
    FETCH_INVOICE_CUSTOMERS_FAIL,
    INVOICE_CUSTOMER_SET,
    PAYER_IS_COMPANY_SET,
    INVOICE_DELIVERY_METHOD_SET
} from './ActionTypes';

export const fetchInvoiceCustomers = organizationID => {
    return {
        type: FETCH_INVOICE_CUSTOMERS,
        organizationID
    }
}

export const fetchInvoiceCustomersSuccess = customers => {
    return {
        type: FETCH_INVOICE_CUSTOMERS_SUCCESS,
        customers
    }
}

export const fetchInvoiceCustomersFail = errors => {
    return {
        type: FETCH_INVOICE_CUSTOMERS_FAIL,
        errors
    }
}

export const setInvoiceCustomer = invoiceCustomer => {
    return {
        type: INVOICE_CUSTOMER_SET,
        invoiceCustomer
    }
}

export const updateBillingInformationData = (name, value, validation = {}) => {
    return {
        type: BILLING_INFORMATION_SET,
        name: name,
        value: value,
        validation: validation
    }
}

export const setBillingInformationValidation = validation => {
    return {
        type: BILLING_INFORMATION_INVALID,
        ...validation
    }
}

export const setBillingInformationValid = () => {
    return {
        type: BILLING_INFORMATION_VALID
    }
}

export const payerIsCompanySet = invoicePayerIsCompany => {
    return {
        type: PAYER_IS_COMPANY_SET,
        invoicePayerIsCompany
    }
}

export const invoiceDeliveryMethodSet = invoiceDeliveryMethod => {
    return {
        type: INVOICE_DELIVERY_METHOD_SET,
        invoiceDeliveryMethod
    }
}