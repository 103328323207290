import { all, put } from "redux-saga/effects";
import moment from 'moment';
import api, { getBaseUrl } from '../api';

import {
	formFetchSuccess,
	formFetchFail,
	setLanguages,
	languageChange,
	participantListFetch,
	setErrors,
	submissionFetch,
	addSubmissionInstance,
	setLoading,
	setPersonID,
	setPartnerID,
	personFetch,
	reservationSave,
	setCouponCode,
	setAllowedFileExtensions
} from './../actions';


/**
 * Fetch a form.
 *
 * @param {object} action
 */
export function* formFetchSaga(action) {

	try {

		console.log(action)

		yield put(setLoading(true, 'Loading form'));

		// Reset store before form is loaded.
		yield put({type: 'RESET_STORE'});

		const { formID, fs, h, lang, paytrailPaymentResult, personID, couponCode, partnerID, authToken } = yield action;

		let baseURL = getBaseUrl(action.env);
		api.defaults.baseURL =  baseURL;
		/* console.log(baseURL); */

		const response = yield api.get('public/forms/' + formID, {
			baseURL
		});

		const { form } = yield response.data.data;

		if( !form.pages.length ) {
			let errors = {
				title: 'General error',
				details: 'Form has no pages defined'
			};
			throw errors;
		}

		const baseLanguageId = lang ? parseInt(lang) : form.languages[0].languageId;

		yield all([
			put(formFetchSuccess(form)),
			put(setLanguages(form.languages)),
			put(languageChange(baseLanguageId))
		]);


		/**
		* Fetch submission if parameters are provided,
		* otherwise add an empty submission.
		* fs = formSubmissionID
		* h = formSubmissionHash
		*/
		if(fs && h) {
			let submissionFetchPayload = {
				formSubmissionID: fs,
				formSubmissionHash:h,
				paytrailPaymentResult,
				authToken,
				form
			}
			yield put(submissionFetch(submissionFetchPayload));
		} else {

			if(form.reservationsEnabled) {
				yield put(reservationSave());
			} else {

				const submissionId = moment().format('x');
				yield put(addSubmissionInstance(submissionId));
			}

			// Add personID and partnerID parameters
			if(personID) {
				yield put(setPersonID(personID));
				yield put(personFetch(personID, form.eventID, form.organizationID));
			}
			if(partnerID) {
				yield put(setPartnerID(partnerID));
			}

			yield put(setLoading(false));
		}

		// Fetch participant list if showParticipantList is set as true
		if(form.showParticipantList || form.showParticipantCount) {
			yield put(participantListFetch(form));
		}

		if(couponCode) {
			yield put(setCouponCode(couponCode));
		}

		// Check if there's file elements, fetch allowedFileTypes from meta if found
		try {
			const fileElements = form.pages
				.reduce((elements, page) => [...elements, ...page.elements], [])
				.filter(element => element.type === 'file');

			if(fileElements.length) {

				const metaResponse = yield api.get('materialFolders/meta');

				if(metaResponse && metaResponse.data && metaResponse.data.data && metaResponse.data.data.meta && metaResponse.data.data.meta.materialFolderTypes) {
					const allowedFileExtensions = metaResponse.data.data.meta.materialFolderTypes
						.filter(folder => folder.id === 5)
						.reduce((extensions, folder) => {
							extensions = folder.allowedFileTypes
								.reduce((extensions, extension) => [...extensions, extension.mimeType], [])
							return extensions;
						}, []);
					if(allowedFileExtensions && allowedFileExtensions.length) {
						yield put(setAllowedFileExtensions(allowedFileExtensions));
					}
				}
			}
		} catch (error) {
			console.log(`Cannot fetch materialMeta`, error);
		}

	} catch (error) {
		console.log(error)
		if(error.response && error.response.data && error.response.data.errors) {
			yield put(formFetchFail(error.response.data.errors));
			yield put(setErrors(error.response.data.errors));
		} else {
			yield put(formFetchFail('error'));
			yield put(setErrors([error]));
		}
		yield put(setLoading(false));
	} finally {
		//
	}
}