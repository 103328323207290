import React from 'react';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';

const ParticipantList = ({ t, title, visible, participants, personsRegistered }) => (
    visible ?
        <div id="ParticipantList">
            <h3 className="title">{title} ({personsRegistered})</h3>
            <ul>
                {participants && participants.map(participant =>
                    <li className="participant-list-item" key={participant.eventsPersonsID}>
                        {participant.firstNames || ''} {participant.lastName || ''}{participant.company && ', ' + participant.company}
                    </li>
                )}
                {(personsRegistered && personsRegistered > 0 && (personsRegistered - participants.length) > 0)  &&
                    <li className="non-public">
                        {t('App.participantsNotAllowedPublic', {count: personsRegistered - participants.length})}.
                    </li>
                }
            </ul>
        </div>
    : null
);

ParticipantList.propTypes = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    participants: PropTypes.array,
    personsRegistered: PropTypes.number
};

export default withTranslation()(ParticipantList);