import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class Alerts extends React.Component {

    render() {

        const { t, type, errors, onDismiss } = this.props;
        const showAlerts = errors && errors.length && errors.length > 0;

        let errorColor = "success";
        if(type === "errors") {
            errorColor = "danger";
        } else if (type === "warning") {
            errorColor = "warning";
        }

        return (
            (errors && Array.isArray(errors)) ? (
                <div className="AlertsWrapper">
                    {errors.length ? (
                        <Alert color={errorColor} isOpen={showAlerts} toggle={onDismiss}>
                            {errors.map( (error, index) => (
                                <span className="AlertsErrorRow" key={index}>
                                    <h4 className="alert-heading">{t(error.title)}</h4>
                                    <p>{t(error.details)} {error.property ? '('+t(error.property)+')' : null}</p>
                                </span>
                            ))}
                        </Alert>
                    ) : null}
                </div>
            ) : null
        )
    }
}

Alerts.propTypes = {
    errors: PropTypes.array
}

export default withTranslation()(Alerts);