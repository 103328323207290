import { put } from "redux-saga/effects";
import {
	setLoading
} from './../actions';

export function* setErrorsSaga(action) {
	if(action.errors.length > 0) {
		const element = yield document.getElementById("EventosFormAlerts");
		if(element) {
			yield element.scrollIntoView({behavior: "smooth"});
		}
	}
	yield put(setLoading(false));
}