import { takeEvery, all, fork } from "redux-saga/effects";

import {
	CHECK_COUPON_CODE,
	CHECK_EMAIL_REGISTERED,
	FETCH_PAYTRAIL_FORMDATA,
	FORM_FETCH,
	LANGUAGE_CHANGE,
	NAVIGATE_NEXT_FORM_PAGE,
	SET_ACTIVE_VIEW,
	SET_ERRORS,
	SUBMISSION_FETCH,
	SUBMISSION_SUBMIT,
	PARTICIPANT_LIST_FETCH,
	FETCH_INVOICE_CUSTOMERS,
	VALIDATE_PHONE_NUMBER,
	PERSON_FETCH,
	RESERVATION_SAVE,
	SET_SUBMISSION_VALUE,
	CHECK_FORMELEMENT_CONDITIONS
} from './../actions/ActionTypes';

import { submissionFetchSaga, submitSaga, emailAlreadyRegisteredSaga } from "./SubmissionSaga";
import { formFetchSaga } from "./FormSaga";
import { paytrailFormDataSaga } from "./PaytrailSaga";
import { languageChangeSaga } from "./LanguageSaga";
import { setErrorsSaga } from "./ErrorSaga";
import { scrollToTopSaga } from './ScrollToTopSaga';
import { participantListFetchSaga } from './ParticipantListSaga';
import { fetchInvoiceCustomersSaga } from './BillingInformationSaga';
import { validatePhoneNumberSaga } from './ValidatePhoneNumberSaga';
import { validateSubmissionSaga } from './ValidateSubmissionSaga';
import { personFetchSaga } from './PersonSaga';
import { reservationSaveSaga } from './ReservationSaga';
import { conditionCheckSaga } from './ConditionCheckSaga';


export function* watchForm() {
  yield all([
		takeEvery(FORM_FETCH, formFetchSaga),
		takeEvery(PARTICIPANT_LIST_FETCH, participantListFetchSaga),
		takeEvery(LANGUAGE_CHANGE, languageChangeSaga),
		takeEvery(SUBMISSION_FETCH, submissionFetchSaga),
		takeEvery(SUBMISSION_SUBMIT, submitSaga),
		takeEvery(CHECK_COUPON_CODE, submitSaga),
		takeEvery(CHECK_EMAIL_REGISTERED, emailAlreadyRegisteredSaga),
		takeEvery(SET_ERRORS, setErrorsSaga),
		takeEvery(FETCH_PAYTRAIL_FORMDATA, paytrailFormDataSaga),
		takeEvery(NAVIGATE_NEXT_FORM_PAGE, validateSubmissionSaga),
		takeEvery(SET_ACTIVE_VIEW, scrollToTopSaga),
		takeEvery(FETCH_INVOICE_CUSTOMERS, fetchInvoiceCustomersSaga),
		takeEvery(VALIDATE_PHONE_NUMBER, validatePhoneNumberSaga),
		takeEvery(PERSON_FETCH, personFetchSaga),
		takeEvery(RESERVATION_SAVE, reservationSaveSaga),
		takeEvery(SET_SUBMISSION_VALUE, conditionCheckSaga),
		takeEvery(CHECK_FORMELEMENT_CONDITIONS, conditionCheckSaga)
	]);
}

export default function* rootSaga() {
	yield fork(watchForm);
}
