import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';

const SummaryOrderRows = ({t, order}) => (
    <fieldset className="row">
        <div className="col-12">
            <legend>{t('Products')}</legend>
        </div>
        <div className="col-12">
            <div className="row">
                <div className="col-12">
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('Product')}</th>
                                <th className="text-center">{t('Count')}</th>
                                <th className="text-right">{t('UnitPrice')}</th>
                                {/*<th className="text-right">{t('Price')}</th>*/}
                                <th className="text-right">{t('Tax')}</th>
                                <th className="text-right">{t('Total')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {order.orderRows.map(orderRow => (
                                <tr key={orderRow.productID}>
                                    <td>{orderRow.productName}</td>
                                    <td className="text-center">{orderRow.productCount}</td>
                                    <td className="text-right">
                                        {orderRow.priceRounded}€
                                        {(orderRow.originalPriceRounded !==  orderRow.priceRounded) && <span style={{marginLeft: '10px', textDecoration: 'line-through'}}>{orderRow.originalPriceRounded}€</span>}

                                    </td>
                                    {/*<td className="text-right">
                                        {orderRow.totalWithoutTaxRounded}€
                                        {(orderRow.originalTotalWithoutTaxRounded !==  orderRow.totalWithoutTaxRounded) && <span style={{marginLeft: '10px', textDecoration: 'line-through'}}>{orderRow.originalTotalWithoutTaxRounded}€</span>}
                                    </td>*/}
                                    <td className="text-right">
                                        {orderRow.taxAmountIncludingProductCount}€
                                        {(orderRow.originalPriceRounded !==  orderRow.priceRounded) && <span style={{marginLeft: '10px', textDecoration: 'line-through'}}>{orderRow.originalTotalPriceIncludingProductCount}€</span>}
                                        ({orderRow.taxPercentRounded}%)
                                    </td>
                                    <td className="text-right">
                                        {orderRow.totalPriceIncludingProductCount}€
                                        {(orderRow.originalPriceRounded !==  orderRow.priceRounded) && <span style={{marginLeft: '10px', textDecoration: 'line-through'}}>{orderRow.originalTotalPriceIncludingProductCount}€</span>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-lg-4 offset-md-6 offset-lg-8">
                    <Table>
                        <tbody>
                            <tr>
                                <th className="text-right">{t('Payments.subTotal')}</th>
                                <td style={{textAlign: 'right'}}>
                                    {order.totalWithoutTaxRounded} €
                                </td>
                            </tr>
                            {order.taxAmounts.map(tax => (
                                 <tr key={tax.taxPercentRounded}>
                                    <th className="text-right">{t('Payments.vat')}{tax.taxPercentRounded} %</th>
                                    <td style={{textAlign: 'right'}}>
                                        {tax.taxAmountRounded} €
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <th className="text-right">{t('Payments.vatTotal')}</th>
                                <td style={{textAlign: 'right'}}>
                                    {order.totalTaxRounded} €
                                </td>
                            </tr>
                            <tr>
                                <th className="text-right">{t('Payments.total')}</th>
                                <td style={{textAlign: 'right'}}>
                                    {order.couponCodeId && <span style={{marginLeft: '10px'}}>{order.originalTotalWithTaxRounded}€<br />
                                    -{order.totalDiscountPriceIncludingProductCountRounded} €<br /></span>}
                                    <strong>{order.totalWithTaxRounded} €</strong>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    </fieldset>
);

SummaryOrderRows.propTypes = {
    order: PropTypes.object
};

export default withTranslation('translation')(SummaryOrderRows);