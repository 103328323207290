import React from 'react';
import PropTypes from 'prop-types';

const FormPageTitle = ({ title }) => (
    title ?
        <h1 className="PageTitle FormPageTitle">
            {title}
        </h1>
    : null
)

FormPageTitle.propTypes = {
    title: PropTypes.string
};

export default FormPageTitle;