import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';

class LanguageSwitcher extends React.Component {

    render() {

        const { languages, languageId, onChangeLanguage } = this.props;

        return (
            (languages && languages.length > 1) ?
                <div className="LanguageSwitcher">
                    <Nav pills>
                        {languages.map(language =>
                            <NavItem key={language.languageId}>
                                <NavLink
                                    href="#"
                                    active={languageId === language.languageId}
                                    onClick={() => onChangeLanguage(language.languageId)}>
                                    {language.name}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                </div>
            : null
        )
    }

}

LanguageSwitcher.propTypes = {
    languages: PropTypes.array,
    languageId: PropTypes.number,
    onChangeLanguage: PropTypes.func
};

export default LanguageSwitcher;