import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { withTranslation } from 'react-i18next';

import 'react-confirm-alert/src/react-confirm-alert.css';

const RemoveButton = ({handleClick}) => (
    <span className="SubmissionInstanceRemoveButton text-danger" onClick={handleClick}>
        <IoIosCloseCircleOutline />
    </span>
);

class SubmissionFields extends Component {

    componentDidMount() {
        this.props.onMount(this.props.submissionId);
    }

    handleRemove = () => {

        const { t, submissionId } = this.props;

        confirmAlert({
            title: <div className="SubmissionRemoveConfirmTitle">{t('Confirm action')}</div>,
            message: <div className="SubmissionRemoveConfirmMessage">{t('Are you sure you want to remove this participant from the form?')}</div>,
            buttons: [{
                label: t('Yes'),
                onClick: () => this.props.onRemove(submissionId)
            },
            {
                label: t('No')
            }]
        })
    }

    render() {

        const { t, submissionIndex, showLegend, children, inline } = this.props;

        return (
            <div className="FormPageFieldset">
                <fieldset className="row">
                    <div className="col-12">

                        {showLegend ?
                            <legend className="SubmissionInstanceTitle">
                                <span className="InstanceIndex"> {(submissionIndex+1) + '. '}</span>
                                <span className="InstanceTitle">{t('App.Participant')}</span>
                                {submissionIndex !== 0 ?
                                    <RemoveButton handleClick={this.handleRemove}></RemoveButton>
                                : null}
                            </legend>
                        : null}

                        {inline ?
                            <HorizontalFieldWrapper fields={children} />
                        :
                            <DefaultFieldWrapper fields={children} />
                        }

                    </div>
                </fieldset>
            </div>
        )
    }
}

const DefaultFieldWrapper = ({ fields }) => (
    <div className="FieldsWrapper">
        <div className="row">
            {fields}
        </div>
    </div>
);

const HorizontalFieldWrapper = ({ fields }) => (
    <div className="FieldsWrapper row">
        <div className="col-md-12">
            {fields}
        </div>
    </div>
);

export default withTranslation('translation')(SubmissionFields);