import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { PropTypes } from 'prop-types';

// Lääkäripäivät 2023, 2023 KEVÄTKOULUTUSPÄIVÄT, 2024 KEVÄTKOULUTUSPÄIVÄT
const HIDE_EMAIL_FOR_EVENTS = ['0903ef53-341d-11ed-a018-ee6a04371b85', 'c8d0487d-9e09-11ed-a018-ee6a04371b85', 'ca6f28b0-c381-11ee-9091-ee6a04371b85'];

export default function InvoiceDeliveryMethod({ t, invoiceDeliveryMethod, invoicePayerIsCompany, sendInvoiceEmail, onChange, eventID }) {

    // Lääkäripäivät 2023 custom. Set delivery method based on payer.
    React.useEffect(() => {
        if(HIDE_EMAIL_FOR_EVENTS.includes(eventID)) {
            onChange(invoicePayerIsCompany ? 0 : 1);
        }
    }, [eventID, invoicePayerIsCompany])

    return (
        <React.Fragment>
            <FormGroup>
                <Label>{t('BillingInformation.invoiceDeliveryMethod')}</Label>
            </FormGroup>
            {(invoicePayerIsCompany && !sendInvoiceEmail) ?
            <FormGroup check inline className="form-group-invoiceDeliveryMethod-online">
                <Label check>
                    <Input
                        type="radio"
                        name="invoiceDeliveryMethod"
                        defaultChecked={invoiceDeliveryMethod === 0}
                        value={0}
                        onChange={onChange} />{' '}
                    {t('BillingInformation.invoiceDeliveryMethodEinvoice')}
                </Label>
            </FormGroup>
            : null}
            {(HIDE_EMAIL_FOR_EVENTS.includes(eventID) && invoicePayerIsCompany) ? 
                null : 
                <FormGroup check inline className="form-group-invoiceDeliveryMethod-email">
                    <Label check>
                        <Input
                            type="radio"
                            name="invoiceDeliveryMethod"
                            defaultChecked={(invoiceDeliveryMethod === 1 || sendInvoiceEmail)}
                            value={1}
                            onChange={onChange} />{' '}
                        {t('BillingInformation.invoiceDeliveryMethodEmail')}
                    </Label>
                </FormGroup>
            }
           
        </React.Fragment>
    )
}

InvoiceDeliveryMethod.propTypes = {
    t: PropTypes.func,
    invoiceDeliveryMethod: PropTypes.number,
    invoicePayerIsCompany: PropTypes.bool,
    sendInvoiceEmail: PropTypes.bool,
    onChange: PropTypes.func
}