import {
    APP_LOADING_SET,
    SET_ERRORS,
    SET_ALERTS,
    RESET_ALERTS
 } from './ActionTypes';

 export const setLoading = (status, text, color) => {
    return {
        type: APP_LOADING_SET,
        status,
        text,
        color
    };
};

export const setAlerts = alerts => {
    return {
        type: SET_ALERTS,
        alerts: alerts
    };
};

export const setErrors = errors => {
    return {
        type: SET_ERRORS,
        errors: errors
    };
};

export const resetAlerts = alerts => {
    return {
        type: RESET_ALERTS,
        alerts: alerts
    };
};